var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './style.css';
export default function RoundedToggle(_a) {
    var isActive = _a.isActive, handleToggle = _a.handleToggle;
    return (_jsx("div", __assign({ onClick: function () {
            handleToggle();
        }, className: "rounded-full w-10 h-0 flex py-1 items-center cursor-pointer rounded-toggle ".concat(!isActive ? 'themes-bg-gray-v2' : 'themes-bg-brand-v5') }, { children: _jsx("div", { className: "w-5 h-5 border-2  themes-bg-white rounded-full transform transition-transform ".concat(isActive ? 'translate-x-7 border-logistical-blue' : '') }) })));
}
