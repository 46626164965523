import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { InvoicesDataDummy } from './invoices.dummy'
import { IInvoices } from './invoices.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const InvoicesStatusHeader: ITabItem[] = [
    {
        label: 'All Status',
        labelTranslationCode: 'status.all_status',
        totalData: InvoicesDataDummy.length,
        key: 'status',
        value: 'allInvoices',
        className: 'brand',
    },
    {
        label: 'Unpaid',
        labelTranslationCode: 'status.unpaid',
        totalData: InvoicesDataDummy.filter((x: any) => x.status === 'unpaid')
            .length,
        key: 'status',
        value: 'unpaid',
        className: 'green',
    },
    {
        label: 'Due Next 7 Days',
        labelTranslationCode: 'status.due_next_7_days',
        totalData: InvoicesDataDummy.filter(
            (x: any) => x.status === 'due next 7 days',
        ).length,
        key: 'status',
        value: 'due next 7 days',
        className: 'yellow',
    },
    {
        label: 'Overdue',
        labelTranslationCode: 'status.overdue',
        totalData: InvoicesDataDummy.filter((x: any) => x.status === 'overdue')
            .length,
        key: 'status',
        value: 'overdue',
        className: 'red',
    },
    {
        label: 'Others',
        labelTranslationCode: 'status.others',
        totalData: InvoicesDataDummy.filter((x: any) => x.status === 'others')
            .length,
        key: 'status',
        value: 'others',
        className: 'gray',
    },
]

export const InvoicesSummaryHeader: ITableColumn[] = [
    {
        label: 'Job Invoice No.',
        accessor: 'jobInvoiceNo',
    },
    {
        label: 'Transaction Type',
        accessor: 'transactionType',
    },
    {
        label: 'Invoice Date',
        accessor: 'invoiceDate',
    },
    {
        label: 'Due Date',
        accessor: 'dueDate',
    },
    {
        label: 'Fully Paid Date',
        accessor: 'fullyPaidDate',
    },
    {
        label: 'Invoice Amount',
        accessor: 'invoiceAmount',
    },
    {
        label: 'Transaction Currency',
        accessor: 'transactionCurrency',
    },
    {
        label: 'Amount',
        accessor: 'amount',
    },
    {
        label: 'Company Name',
        accessor: 'companyName',
    },
    {
        label: 'Currency Code',
        accessor: 'currencyCode',
    },
]

export const InvoicesConfigureColumns: IConfigureColumns<IInvoices>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Job Invoice No.',
                labelTranslation: 'invoices.job_invoice_no',
                accessor: 'jobInvoiceNo',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Transaction Type',
                labelTranslation: 'invoices.transaction_type',
                accessor: 'transactionType',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Invoice Date',
                labelTranslation: 'invoices.invoice_date',
                accessor: 'invoiceDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Due Date',
                labelTranslation: 'invoices.due_date',
                accessor: 'dueDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Fully Paid Date',
                labelTranslation: 'invoices.fully_paid_date',
                accessor: 'fullyPaidDate',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Invoice Amount',
                labelTranslation: 'invoices.invoice_amount',
                accessor: 'invoiceAmount',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Transaction Currency',
                labelTranslation: 'invoices.transaction_currency',
                accessor: 'transactionCurrency',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Amount',
                labelTranslation: 'invoices.amount',
                accessor: 'amount',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Company Name',
                labelTranslation: 'invoices.company_name',
                accessor: 'companyName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Currency Code',
                labelTranslation: 'invoices.currency_code',
                accessor: 'currencyCode',
            },
        ],
    },
]
