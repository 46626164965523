import * as Yup from 'yup'
import {
    IManageUser,
    IModuleAccess,
} from '../pages/manage-users/manage-users.interface'
import { ICreateUserModuleValidation } from './create-user.validation'

// interface for validation
export interface IUpdateUserValidation {
    id: string
    fullName: string
    jobTitle: string
    jobCategory: string
    organizationName: string
    email: string
    userName: string
    country: string
    province: string
    city: string
    addressDetails: string
    mobilePhoneNumber: string
    homePhoneNumber: string
    contactName: string
    phoneNumber: string
    moduleAccessList: ICreateUserModuleValidation[] | undefined
}

// form validation YUP for formik
export const updateUserValidation = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    jobTitle: Yup.string().required('Job title is required'),
    jobCategory: Yup.string().required('Job category is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    userName: Yup.string().required('User name is required'),
})

// form login initial data
export const updateUserInitialValue = (
    data: IManageUser,
): IUpdateUserValidation => {
    return {
        id: data.id,
        organizationName: data.organizationName,
        fullName: data.fullName,
        jobTitle: data.jobTitle,
        jobCategory: data.jobCategory,
        email: data.email,
        userName: data.userName,
        country: data.contactName,
        province: data.province,
        city: data.city,
        addressDetails: data.addressDetails,
        mobilePhoneNumber: data.mobilePhoneNumber,
        homePhoneNumber: data.homePhoneNumber,
        contactName: data.contactName,
        phoneNumber: data.phoneNumber,
        moduleAccessList: data.role?.moduleAccessList.map(
            (item: IModuleAccess): ICreateUserModuleValidation => ({
                moduleTemplateId: item.moduleTemplateId,
                access: {
                    canAccessModule: item.access.canAccessModule,
                    canViewDetails: item.access.canViewDetails,
                    canCreate: item.access.canCreate,
                    canUpdate: item.access.canUpdate,
                    canDelete: item.access.canDelete,
                },
            }),
        ),
    }
}
