import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import { useTranslation } from 'react-i18next'

const ManageUsersFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Input
                    label={t('users.user_id')}
                    placeholder={t('action.enter', {
                        value: t('users.user_id'),
                    })}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Admin Staff',
                            value: 'Admin Staff',
                        },
                        {
                            label: 'Customer Service',
                            value: 'Customer Service',
                        },
                        {
                            label: 'Warehouse Manager',
                            value: 'Warehouse Manager',
                        },
                        {
                            label: 'Logistics Coordinator',
                            value: 'Logistics Coordinator',
                        },
                    ]}
                    label={t('users.job_title')}
                    placeholder={t('action.select', {
                        value: t('users.job_title'),
                    })}
                    onClick={() => {}}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Logistics',
                            value: 'Logistics',
                        },
                        {
                            label: 'Operations',
                            value: 'Operations',
                        },
                    ]}
                    label={t('users.job_category')}
                    placeholder={t('action.select', {
                        value: t('users.job_category'),
                    })}
                    onClick={() => {}}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Admin',
                            value: 'Admin',
                        },
                        {
                            label: 'Regular User',
                            value: 'Regular User',
                        },
                    ]}
                    onClick={() => {}}
                    label={t('users.role')}
                    placeholder={t('action.select', {
                        value: t('users.role'),
                    })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Organization',
                            value: 'Organization',
                        },
                        {
                            label: 'Last Access',
                            value: 'Last Access',
                        },
                    ]}
                    onClick={() => {}}
                    placeholder={t('action.add', {
                        value: t('action.filter'),
                    })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
        </div>
    )
}

export default ManageUsersFilter
