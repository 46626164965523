import SimpleTable from '@components/simple-table/simple-table.component'
import { milestonesHeader } from '../shipments-details.static'
import { IMilestone } from 'client/jpl/pages/shipments/shipments.interface'

const Milestones = (data: IMilestone[] | null) => {
    return (
        <SimpleTable<IMilestone> headers={milestonesHeader} data={data || []} />
    )
}

export default Milestones
