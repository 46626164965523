/* eslint-disable no-unused-vars */
import Button from '@components/button/button.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import { containersHeaders } from '../../booking-details/booking-details.static'
import { metaDummy } from '../../bookings.dummy'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { FormikProvider } from 'formik'
import {
    ICreateBookingContainersValidation,
    useBookingsCreateValidation,
} from 'client/ifb/form-validation/booking-details.validation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IUseCreateBooking } from '../create-booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { Toast } from '@components/toast/toast.component'
import { metaDummy2 } from 'common/common.static'

const ContainerBookings = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const [isNewForm, setIsNewForm] = useState<boolean>(true)
    const { t } = useTranslation()
    const { createContainerBookingsInitialValue } =
        useBookingsCreateValidation()

    return (
        <div className="h-[calc(100vh-290px)] grid grid-cols-12">
            <div className="col-span-9">
                <SimpleTable<ICreateBookingContainersValidation>
                    headers={containersHeaders}
                    data={CBService.containersData || []}
                    usePagination
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy2(CBService.containersData.length)}
                    onRowClick={(data) => {
                        setIsNewForm(false)
                        CBService.setContainerData(data)
                    }}
                />
            </div>
            <FormikProvider value={CBService.formikCreateContainerBookings}>
                <div className="col-span-3 border-y-2 flex flex-col justify-between">
                    <div>
                        <div className="flex themes-text-gray-v5 justify-between items-center p-3 text-[16px] border-b-2">
                            {!isNewForm ? (
                                <>
                                    <span>Selected Container</span>
                                    <div className="text-[24px] flex">
                                        <div className="border-x-2 px-2">
                                            <i
                                                className="ri-file-copy-line themes-text-hover-green-v3 cursor-pointer"
                                                onClick={() => {
                                                    CBService.setConfirmationModalProperty(
                                                        {
                                                            title: 'Duplicate Container',
                                                            message:
                                                                'Duplicating this item will create a copy of the current data. Please review carefully before proceeding',
                                                            btnProceed: {
                                                                onClick: () => {
                                                                    CBService.setContainerParams(
                                                                        'duplicate',
                                                                    )
                                                                    CBService.formikCreateContainerBookings.submitForm()
                                                                },
                                                                variant:
                                                                    'brand',
                                                            },
                                                        },
                                                    )
                                                    CBService.confirmationModal.openModalHandling()
                                                }}
                                            />
                                        </div>
                                        <div className="pl-2">
                                            <i
                                                className="ri-delete-bin-line themes-text-hover-red-v3 cursor-pointer"
                                                onClick={() => {
                                                    CBService.setConfirmationModalProperty(
                                                        {
                                                            title: 'Delete Container',
                                                            message:
                                                                'Deleting this will not be able to undo the changes.',
                                                            btnProceed: {
                                                                onClick: () => {
                                                                    CBService.setContainerParams(
                                                                        'delete',
                                                                    )
                                                                    CBService.formikCreateContainerBookings.submitForm()
                                                                    setIsNewForm(
                                                                        true,
                                                                    )
                                                                },
                                                                variant: 'red',
                                                            },
                                                        },
                                                    )
                                                    CBService.confirmationModal.openModalHandling()
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>Add New Container</>
                            )}
                        </div>
                        <div className="p-3 grid grid-cols-2 gap-3">
                            <FormInput
                                label={'Container Count'}
                                name={`containerCount`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                type={'number'}
                            />
                            <FormDropdown
                                isSearchable
                                isClearable
                                label={'Container Type'}
                                name={`containerType.code`}
                                placeholder={'Select here'}
                                options={CBService.dropdownData.containerModes}
                                defaultValue={
                                    CBService.dropdownData.containerModes
                                }
                                parentDivClassName="!mb-0"
                                isAsync={true}
                                loadOptions={(e: string) =>
                                    CBService.loadOptions({ containerItem: e })
                                }
                                additionalOnClick={(data) => {
                                    CBService.additionalClickSetDesc<ICreateBookingContainersValidation>(
                                        CBService.formikCreateContainerBookings,
                                        'containerType.description',
                                        data as IDropdownItem,
                                    )
                                }}
                            />
                            <FormInput
                                label={'Container Number'}
                                name={`containerNumber`}
                                placeholder={'Select here'}
                                parentDivClassName="!mb-0"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 p-3">
                        <Button
                            onClick={() => {
                                if (isNewForm) {
                                    CBService.setContainerParams('add')
                                } else {
                                    CBService.setContainerParams('update')
                                }
                                if (CBService.isFormStillError.addContainer) {
                                    Toast({
                                        header: 'Failed to Add Container!',
                                        message:
                                            'There is some mandatory fields required to not leave blank',
                                        type: 'error',
                                    })
                                }
                                CBService.formikCreateContainerBookings.submitForm()
                            }}
                            label={
                                isNewForm
                                    ? 'Add New Container'
                                    : 'Update Container'
                            }
                            variant="brand"
                            isDisabled={
                                isNewForm
                                    ? false
                                    : !CBService.formikCreateContainerBookings
                                          .dirty
                            }
                        />
                        <Button
                            onClick={() => {
                                CBService.setConfirmationModalProperty({
                                    title: 'Discard Changes',
                                    message:
                                        'Everything that you’ve added in the page will not be recorded.',
                                    btnProceed: {
                                        onClick: () => {
                                            CBService.setContainerData(
                                                createContainerBookingsInitialValue,
                                            )
                                            CBService.formikCreateContainerBookings.resetForm()
                                            setIsNewForm(true)
                                        },
                                        variant: 'brand',
                                    },
                                })
                                CBService.confirmationModal.openModalHandling()
                            }}
                            label={t('action.discard_changes')}
                            variant="red-inverse"
                            isDisabled={
                                !isNewForm
                                    ? false
                                    : !CBService.formikCreateContainerBookings
                                          .dirty
                            }
                        />
                    </div>
                </div>
            </FormikProvider>
        </div>
    )
}

export default ContainerBookings
