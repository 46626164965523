import Button from '@components/button/button.component'
import { IUseUserAccessForm } from 'client/common/pages/user-acces/user-access-form/user-access-form.service'
import './manage-user-form.style.css'
import useManageUserForm from './manage-user-form.service'
import { useTranslation } from 'react-i18next'
import { FormikProvider } from 'formik'
import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import Spinner from '@components/spinner/spinner.component'

const ManageUserForm = ({ isNew, isProfilePage }: IUseUserAccessForm) => {
    const { t } = useTranslation()
    const {
        allModal,
        formik,
        formikUpdate,
        userAccessConfModal,
        loading,
        navigate,
        submitForm,
    } = useManageUserForm(isNew)

    const labelNew = isNew
        ? t('menu.manage_user_create')
        : t('menu.manage_user_update')
    const label = isProfilePage ? t('menu.manage_user_details') : labelNew
    const isDisabled = !isProfilePage ? false : true
    const buttonLabel = isNew
        ? t('menu.manage_user_create')
        : t('menu.manage_user_update')
    const cancelButtonLabel = t('action.back')
    const spinnerShow = loading ? '' : 'hidden'
    return (
        <>
            <div
                className={`${spinnerShow} flex w-full items-center justify-center content-full-height`}
            >
                <Spinner label="Please Wait..." />
            </div>
            <div className="container-global content-full-height create-user transition-opacity duration-500">
                <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                    <div className="border-b border-logistical-gray-ver7 ">
                        <div className="p-4 text-size-S font-bold">
                            {label.toUpperCase()}
                        </div>
                    </div>

                    {/* Form */}

                    <FormikProvider value={isNew ? formik : formikUpdate}>
                        <div className="flex gap-3 p-3 h-[calc(100vh-14.5rem)] overflow-auto">
                            <div className="w-1/2 flex flex-col gap-3 border-r pr-3">
                                <div className="text-size-S font-bold">
                                    {t('users.user_profile')}
                                </div>
                                <FormInput
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.full_name')}
                                    placeholder={t('action.enter', {
                                        value: t('users.full_name'),
                                    })}
                                    name={'fullName'}
                                    required={isNew}
                                    parentDivClassName="!mb-0"
                                />
                                <FormInput
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.job_title')}
                                    placeholder={t('action.enter', {
                                        value: t('users.job_title'),
                                    })}
                                    name={'jobTitle'}
                                    required={isNew}
                                    parentDivClassName="!mb-0"
                                />
                                <FormDropdown
                                    useUppercaseLabel={true}
                                    disabled={isDisabled}
                                    label={t('users.job_category')}
                                    placeholder={t('action.enter', {
                                        value: t('users.job_category'),
                                    })}
                                    name={'jobCategory'}
                                    required={isNew}
                                    parentDivClassName="!mb-0"
                                    options={[
                                        {
                                            label: 'Logistics',
                                            value: 'Logistics',
                                        },
                                        {
                                            label: 'Operations',
                                            value: 'Operations',
                                        },
                                    ]}
                                />

                                <div className="text-size-S font-bold">
                                    {t('users.user_account')}
                                </div>
                                <FormInput
                                    useUppercaseLabel={true}
                                    disabled={isDisabled || !isNew}
                                    label={t('users.email')}
                                    placeholder={t('action.enter', {
                                        value: t('users.email'),
                                    })}
                                    name={'email'}
                                    required={isNew}
                                    parentDivClassName="!mb-0"
                                />
                                <FormInput
                                    useUppercaseLabel={true}
                                    disabled={isDisabled || !isNew}
                                    label={t('users.username')}
                                    placeholder={t('action.enter', {
                                        value: t('users.username'),
                                    })}
                                    name={'userName'}
                                    required={isNew}
                                    parentDivClassName="!mb-0"
                                />
                                {isNew ? (
                                    <>
                                        <FormInput
                                            useUppercaseLabel={true}
                                            disabled={isDisabled}
                                            label={'password'}
                                            placeholder={'Enter password'}
                                            name={'password'}
                                            type="password"
                                            required
                                            parentDivClassName="!mb-0"
                                            client="IFB"
                                        />
                                        <FormInput
                                            useUppercaseLabel={true}
                                            disabled={isDisabled}
                                            label={'confirm password'}
                                            placeholder={
                                                'Enter confirm password'
                                            }
                                            name={'confirmPassword'}
                                            required
                                            parentDivClassName="!mb-0"
                                            type="password"
                                            client="IFB"
                                        />
                                        <div>
                                            <Button
                                                label="User Access Configuration"
                                                variant="brand-inverse"
                                                onClick={() => {
                                                    userAccessConfModal.openModalHandling()
                                                }}
                                                className="w-full"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="w-1/2 flex flex-col gap-3">
                                <div className="text-size-S font-bold">
                                    {t('users.user_address')}
                                </div>
                                <div className="grid grid-rows-3 grid-flow-col-dense gap-3">
                                    <FormInput
                                        label="Country"
                                        name="country"
                                        parentDivClassName={'!mb-0 row-start-1'}
                                        useUppercaseLabel={true}
                                        placeholder={'Enter country'}
                                        disabled={isProfilePage}
                                    />
                                    <FormInput
                                        label="Province"
                                        name="province"
                                        parentDivClassName={'!mb-0 row-start-1'}
                                        useUppercaseLabel={true}
                                        placeholder={'Enter province'}
                                        disabled={isProfilePage}
                                    />
                                    <FormInput
                                        label="City"
                                        name="city"
                                        parentDivClassName={'!mb-0 row-start-1'}
                                        useUppercaseLabel={true}
                                        placeholder={'Enter city'}
                                        disabled={isProfilePage}
                                    />
                                    <FormTextarea
                                        label="ADDRESS DETAILS"
                                        name="addressDetails"
                                        parentDivClassName={
                                            ' row-span-2 row-start-2 col-span-3'
                                        }
                                        useUppercaseLabel={true}
                                        placeholder={'Enter addressDetails'}
                                        disabled={isProfilePage}
                                    />
                                </div>

                                <div className="text-size-S font-bold ">
                                    {t('users.user_contact')}
                                </div>
                                <div className="flex gap-3">
                                    <FormInput
                                        label="Mobile Phone Number"
                                        name={'mobilePhoneNumber'}
                                        parentDivClassName="!mb-0 w-1/2"
                                        useUppercaseLabel={true}
                                        placeholder={
                                            'Enter mobile phone number'
                                        }
                                        disabled={isProfilePage}
                                    />
                                    <FormInput
                                        label="Home Phone Number"
                                        name={'homePhoneNumber'}
                                        parentDivClassName="!mb-0 w-1/2"
                                        useUppercaseLabel={true}
                                        placeholder={'Enter home phone number'}
                                        disabled={isProfilePage}
                                    />
                                </div>

                                <div className="text-size-S font-bold">
                                    {t('users.emergency_contact')}
                                </div>
                                <div className="flex gap-3">
                                    <FormInput
                                        label="Contact Name"
                                        name={'contactName'}
                                        parentDivClassName="!mb-0 w-1/2"
                                        useUppercaseLabel={true}
                                        placeholder={'Enter contact name'}
                                        disabled={isProfilePage}
                                    />
                                    <FormInput
                                        label="Phone Number"
                                        name={'phoneNumber'}
                                        parentDivClassName="!mb-0 w-1/2"
                                        useUppercaseLabel={true}
                                        placeholder={'Enter phone number'}
                                        disabled={isProfilePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </FormikProvider>
                </div>

                {/* Button */}
                <div className="flex justify-between p-4">
                    {!isProfilePage ? (
                        <Button
                            onClick={() => {
                                navigate(-1)
                            }}
                            useUpperCase={true}
                            label={cancelButtonLabel}
                        />
                    ) : null}
                    {!isProfilePage ? (
                        <Button
                            type="button"
                            useUpperCase={true}
                            variant="brand"
                            onClick={() => {
                                submitForm()
                            }}
                            label={buttonLabel}
                        />
                    ) : null}
                </div>
            </div>

            {allModal}
        </>
    )
}

export default ManageUserForm
