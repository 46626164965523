import Button from '@components/button/button.component'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { useState } from 'react'
import {
    IFilterModuleTemplates,
    IModuleTemplate,
} from '../../manage-users.interface'
import {
    industryOptions,
    portalAccessOptions,
} from '../manage-user-form.static'
import Spinner from '@components/spinner/spinner.component'

const UserAccessConfigurationModal = ({
    modalService,
    moduleTemplates,
    onClickModule,
    onClickFilterModule,
    filterModuleTemplate,
    loading,
    chooseModule,
    handleToggle,
}: {
    modalService: IUseModal
    moduleTemplates: IModuleTemplate[]
    onClickModule: (data: IModuleTemplate) => void
    onClickFilterModule: (data: IFilterModuleTemplates) => void
    filterModuleTemplate: IFilterModuleTemplates
    loading: boolean
    chooseModule: IModuleTemplate
    handleToggle: (value: string) => void
}) => {
    const [portalAccessActive, setPortalAccessActive] = useState<number>(0)
    const [industryActive, setIndustryActive] = useState<number>(0)
    const [moduleActive, setModuleActive] = useState<number>(0)
    const heightContentModule =
        filterModuleTemplate?.portalAcess === 'customer'
            ? 'h-[calc(100vh-36rem)]'
            : 'h-[calc(100vh-31.5rem)]'

    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="!w-1/2 h-[600px]"
        >
            <div className=" flex flex-col gap-3">
                <div>ACCESS CONTROL</div>
                <div>
                    <div>Portal Access</div>
                    <div className="flex gap-3">
                        {portalAccessOptions.map((item, idx) => {
                            const isActive = portalAccessActive === idx
                            const variant = isActive ? 'brand' : 'brand-inverse'
                            return (
                                <Button
                                    label={item.label}
                                    onClick={() => {
                                        setPortalAccessActive(idx)
                                        onClickFilterModule({
                                            portalAcess: item.value,
                                        })
                                    }}
                                    variant={variant}
                                    key={idx}
                                />
                            )
                        })}
                    </div>
                </div>

                {filterModuleTemplate?.portalAcess === 'customer' && (
                    <div>
                        <div>Industry</div>
                        <div className="flex gap-3">
                            {industryOptions.map((item, idx) => {
                                const isActive = industryActive === idx
                                const variant = isActive
                                    ? 'brand'
                                    : 'brand-inverse'
                                return (
                                    <Button
                                        label={item.label}
                                        onClick={() => {
                                            setIndustryActive(idx)
                                            onClickFilterModule({
                                                industry: item.value,
                                            })
                                        }}
                                        variant={variant}
                                        key={idx}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )}

                {/* list */}
                <div className={`flex w-full ${heightContentModule}`}>
                    {loading ? (
                        <div
                            className={` flex w-full items-center justify-center h-full`}
                        >
                            <Spinner label="Please Wait..." />
                        </div>
                    ) : (
                        <>
                            <div className="w-full border border-r-0  overflow-auto">
                                {moduleTemplates?.map((item, idx) => {
                                    const activeClassName =
                                        moduleActive === idx
                                            ? 'themes-bg-brand-v2'
                                            : ''
                                    return (
                                        <div
                                            className={` ${activeClassName} cursor-pointer  border-b px-3 py-1`}
                                            onClick={() => {
                                                onClickModule(item)
                                                setModuleActive(idx)
                                            }}
                                            key={idx}
                                        >
                                            {item.moduleName}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="w-full border ">
                                <div className="border-b px-3 py-1">
                                    Module Content{' '}
                                </div>
                                {chooseModule?.loadActionList?.map((item) => (
                                    <div className="px-3 py-1">
                                        <ToggleSwitch
                                            label={item?.label}
                                            initialOn={item?.isActive}
                                            onClick={() => {
                                                handleToggle(item?.value)
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>

                <div className="flex justify-end">
                    <Button
                        onClick={() => {
                            modalService.closeModalHandling()
                        }}
                        label="Save"
                        variant="brand"
                        className="w-btnSmallWidth"
                    />
                </div>
            </div>
        </Modal>
    )
}

export default UserAccessConfigurationModal
