import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'
import { ITableColumn } from '@components/table/table.interface'
import { ShipmentConfigureColumns } from './shipments.static'
import { getShipmentsData } from 'client/ifb/repository/shipment.repository'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import {
    filterSelector,
    setFilter,
    setPageNumber,
    setSelectedStatus,
    setShipmentData,
    shipmentMetaSelector,
    shipmentsDataSelector,
    shipmentsStatusTotalSelector,
    tabStatusFilterSelector,
} from './shipments.slice'
import {
    IShipment,
    IShipmentFilter,
    IShipmentStatusTotal,
} from 'client/ifb/repository/interface/shipment.interface'
import { Toast } from '@components/toast/toast.component'
import { isEmptyArray } from 'formik'
import { initialFilter } from 'common/common.static'

const useShipments = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // selector
    const shipmentsData = useSelector(shipmentsDataSelector)
    const totalStatus = useSelector(shipmentsStatusTotalSelector)
    const tabFilter = useSelector(tabStatusFilterSelector)
    const filter = useSelector(filterSelector)
    const shipemntsDataMeta = useSelector(shipmentMetaSelector)

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [headers, setHeaders] = useState<ITableColumn<IShipment>[]>([])
    const [tabItems, setTabItems] = useState<ITabItem[]>()

    // variable

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    // variable
    const { pageNumber, pageSize, status, search } = filter

    useEffect(() => {
        dispatch(setFilter(initialFilter))
        setIsInitialized(true)
    }, [])

    useEffect(() => {
        generateTabItems(totalStatus)
    }, [totalStatus])

    // get data
    useEffect(() => {
        if (isInitialized) loadData()
    }, [pageNumber, pageSize, status, tabFilter, search, isInitialized])

    // Get Data Function
    const loadData = async () => {
        const status = tabFilter.value

        let tmpFilter: IShipmentFilter = filter
        if (tabFilter?.childStatus) {
            const inProgressStat = tabFilter?.childStatus[0].value
            tmpFilter = { ...filter, status, inProgressStatus: inProgressStat }
        } else {
            tmpFilter = { ...filter, status }
        }
        try {
            setLoading(true)
            const actionResult = await getShipmentsData(tmpFilter)
            dispatch(setShipmentData(actionResult))
            setLoading(false)
            if (isEmptyArray(actionResult.data)) {
                Toast({
                    header: 'Search Not Found!',
                    message:
                        "The keyword you've entered does not display any result.",
                    type: 'error',
                })
            }
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = (status: IShipmentStatusTotal) => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Shipments',
                totalData: status.all,
                key: 'status',
                value: 'all',
                className: 'brand',
            },
            {
                label: 'Arrived',
                totalData: status.arrived,
                key: 'status',
                value: 'arrived',
                className: 'green',
            },
            {
                label: 'In Transit',
                totalData: status.inTransit,
                key: 'status',
                value: 'inTransit',
                className: 'yellow',
            },
            {
                label: 'Delayed',
                totalData: status.delayed,
                key: 'status',
                value: 'delayed',
                className: 'red',
            },
            {
                label: 'Others',
                totalData: status.noStatus,
                key: 'status',
                value: 'others',
                className: 'gray',
            },
        ]

        setTabItems(tabItems)
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(setFilter({ ...filter, search: values, pageNumber: 1 }))
        } else if (values.length <= 0)
            dispatch(setFilter({ ...filter, search: '', pageNumber: 1 }))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    // segment general
    const allModal = (
        <>
            <ConfigureColumnsModal<IShipment>
                modalService={ConfigureColumnsModalService}
                columns={ShipmentConfigureColumns}
                setHeaders={setHeaders}
            />
        </>
    )

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        loading,
        errorMessage,
        shipemntsDataMeta,
        setPageData,
        setLoading,
        setErrorMessage,
        navigate,
        handleSearch,
        setTabItems,
        setTabFilter,
        shipmentsData,
        ConfigureColumnsModalService,
        allModal,
        headers,
    }
}

export default useShipments
