import Input from '@components/input/input.component'
import { useTranslation } from 'react-i18next'

const Details = () => {
    const { t } = useTranslation()
    return (
        <div className="grid grid-cols-4 gap-3 px-5 py-4">
            <div className="flex flex-col">
                <div className="text-[16px] themes-text-gray-v5">
                    {t('receipts.receipt_information')}
                </div>
                <div className="flex flex-col h-full gap-3 pt-2">
                    <Input
                        label={t('receipts.warehouse_name')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('receipts.customer_ref')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('orders.external_reference')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('receipts.booking_date')}
                        placeholder="Not Available"
                        disabled
                    />
                    <Input
                        label={t('receipts.arrival_date')}
                        placeholder="Not Available"
                        disabled
                    />
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            label={t('receipts.total_units')}
                            placeholder="Not Availabel"
                            disabled
                        />
                        <Input
                            label={t('receipts.total_pallets')}
                            placeholder="Not Availabel"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details
