var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import './measurement-input.style.css';
var MeasurementInput = function (_a) {
    var options = _a.options, measurement = _a.measurement, setMeasurement = _a.setMeasurement, defaultOption = _a.defaultOption, _b = _a.required, required = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.disabledVariant, disabledVariant = _d === void 0 ? 'gray' : _d, props = __rest(_a, ["options", "measurement", "setMeasurement", "defaultOption", "required", "disabled", "disabledVariant"]);
    var _e = useState(false), dropdownOpen = _e[0], setDropdownOpen = _e[1];
    var dropdownRef = useRef(null);
    var _f = useState(false), focus = _f[0], setFocus = _f[1];
    var isRequired = required ? 'required' : '';
    var labelFocus = focus || dropdownOpen ? 'themes-text-brand-v5' : '';
    var isDisabled = disabled ? "disabled-".concat(disabledVariant) : '';
    var borderFocus = focus || dropdownOpen ? 'themes-border-brand-v5' : '';
    var cursorDisabled = disabled ? '' : 'cursor-pointer';
    var handleFocusEvent = function () {
        setFocus(true);
    };
    var handleQtyChange = function (e) {
        var value = e.target.value;
        // Jika input kosong, set qty menjadi null atau string kosong
        var data = __assign(__assign({}, measurement), { qty: value === '' ? undefined : Number(value) });
        setMeasurement(data);
    };
    var handleUnitChange = function (selectedOption) {
        var data = __assign(__assign({}, measurement), { unit: selectedOption.value });
        setMeasurement(data);
        setDropdownOpen(false);
    };
    var toggleDropdownIcon = function (e) {
        e.stopPropagation(); // Prevents event bubbling
        setDropdownOpen(!dropdownOpen);
    };
    // Close dropdown when clicking outside
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (dropdownRef.current &&
                !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
                setFocus(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        if (defaultOption) {
            var data = __assign(__assign({}, measurement), { unit: defaultOption.value });
            setMeasurement(data);
        }
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "measurement-input-container", ref: dropdownRef }, { children: [_jsxs("div", __assign({ className: "w-full h-inherit " }, { children: [_jsx("label", { children: _jsx("div", __assign({ className: "h-[1px]" }, { children: _jsx("p", __assign({ className: "".concat(isRequired, " ").concat(labelFocus) }, { children: props.label })) })) }), _jsxs("div", __assign({ className: "flex rounded border  ".concat(borderFocus) }, { children: [_jsx("input", { type: "number", value: (measurement === null || measurement === void 0 ? void 0 : measurement.qty) || '', onChange: handleQtyChange, placeholder: (props === null || props === void 0 ? void 0 : props.placeholder) || 'Enter here', className: "".concat(borderFocus, " ").concat(isDisabled), onFocus: handleFocusEvent, disabled: disabled }), _jsxs("div", __assign({ className: "unit ".concat(isDisabled), onClick: function (data) {
                                    return !disabled && toggleDropdownIcon(data);
                                } }, { children: [_jsx("span", __assign({ className: "border-l pl-2" }, { children: measurement === null || measurement === void 0 ? void 0 : measurement.unit })), _jsx("i", { className: "".concat(dropdownOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line', " ").concat(cursorDisabled) })] }))] }))] })), dropdownOpen && (_jsx("div", __assign({ className: "dropdown-list" }, { children: options.map(function (unitOption) {
                    var activeClass = unitOption.value === (measurement === null || measurement === void 0 ? void 0 : measurement.unit)
                        ? 'themes-bg-brand-v2'
                        : 'themes-bg-white';
                    return (_jsx("div", __assign({ onClick: function () { return handleUnitChange(unitOption); }, className: "".concat(activeClass, " p-2 cursor-pointer rounded themes-bg-hover-gray-v5:hover") }, { children: unitOption.label }), unitOption.value));
                }) })))] })));
};
export default MeasurementInput;
