var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import MonthYearPicker from '@components/month-year-picker/month-year-picker.component';
var FormMonthYearPicker = function (_a) {
    var name = _a.name, label = _a.label, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.readonly, readonly = _c === void 0 ? false : _c, _d = _a.required, required = _d === void 0 ? false : _d, useUppercaseLabel = _a.useUppercaseLabel, _e = _a.parentDivClassName, parentDivClassName = _e === void 0 ? '' : _e, _f = _a.hideError, hideError = _f === void 0 ? false : _f, _g = _a.placeholder, placeholder = _g === void 0 ? 'MM/YYYY' : _g, props = __rest(_a, ["name", "label", "disabled", "readonly", "required", "useUppercaseLabel", "parentDivClassName", "hideError", "placeholder"]);
    // Formik hook
    var _h = useField(name), field = _h[0], meta = _h[1], helpers = _h[2];
    // Determine border color based on error state
    var isFormError = meta.touched && meta.error ? true : false;
    var errorClassMessage = meta.touched && meta.error && !hideError ? '' : 'hidden';
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName, " h-[40px] w-full relative") }, { children: [_jsx(MonthYearPicker, __assign({ name: name, label: label, disabled: disabled, readonly: readonly, required: required, useUppercaseLabel: useUppercaseLabel, placeholder: placeholder, value: field.value, onChange: function (value) { return helpers.setValue(value); }, isFormError: isFormError }, props)), _jsx("div", __assign({ className: "ml-small-x text-size-XS themes-text-red-v3 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, { name: name, component: "p" }) }))] })));
};
export default FormMonthYearPicker;
