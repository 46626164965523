import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { ICreateBookingValidation } from 'client/ifb/form-validation/booking-details.validation'
import { FormikContextType } from 'formik'
import { useTranslation } from 'react-i18next'
import useCreateBooking from '../create-booking/create-booking.service'

const LogisticsEntitiesForm = ({
    entity,
    companyOptions,
    contactNameOptions,
    countryOptions,
    formik,
}: {
    entity: string
    formik?: FormikContextType<ICreateBookingValidation>
    companyOptions: IDropdownItem[]
    contactNameOptions: IDropdownItem[]
    countryOptions: IDropdownItem[]
}) => {
    const { t } = useTranslation()
    const {loadOptions, getContactOptions } = useCreateBooking()
    return (
        <div className="grid grid-cols-2 gap-3" key={entity}>
            <FormDropdown
                isSearchable
                label={t('bookings.company')}
                name={`${entity}.company`}
                options={companyOptions}
                defaultValue={companyOptions}
                isAsync={true}
                loadOptions={(e: string) =>
                    loadOptions && loadOptions({ consgineecompanies1: e })
                }
                additionalOnClick={(data) => {
                    if (!formik) return
                    const _data = data as IDropdownItem
                    const id = _data?.value as string
                    formik?.setFieldValue(`${entity}.idCompany`, id)
                    getContactOptions({
                        idCompany: id,
                        goal: `${entity}.idCompany`,
                    })
                }}
                placeholder={t('action.enter', {
                    value: t('bookings.company'),
                })}
                parentDivClassName="!mb-0 col-span-2"
                isClearable={true}
            />
            <FormInput
                label={t('bookings.street')}
                name={`${entity}.address1`}
                placeholder={t('action.enter', {
                    value: t('bookings.street'),
                })}
                parentDivClassName="!mb-0 col-span-2"
            />
            <FormInput
                label={t('bookings.additional_street')}
                name={`${entity}.address2`}
                placeholder={t('action.enter', {
                    value: t('bookings.additional_street'),
                })}
                parentDivClassName="!mb-0 col-span-2"
            />
            <FormInput
                label={t('bookings.city')}
                name={`${entity}.city`}
                placeholder={t('action.enter', {
                    value: t('bookings.city'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormInput
                label={t('bookings.state')}
                name={`${entity}.state.value`}
                placeholder={t('action.enter', {
                    value: t('bookings.state'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormInput
                label={t('bookings.postcode')}
                name={`${entity}.postCode`}
                placeholder={t('action.enter', {
                    value: t('bookings.postcode'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormDropdown
                isSearchable
                label={t('bookings.country')}
                name={`${entity}.countryCode`}
                options={countryOptions}
                defaultValue={countryOptions}
                placeholder={t('action.enter', {
                    value: t('bookings.country'),
                })}
                parentDivClassName="!mb-0 "
                isClearable={true}
                isAsync={true}
                loadOptions={(e: string) =>
                    loadOptions && loadOptions({ countryItem: e })
                }
                additionalOnClick={(data) => {
                    if (!formik) return
                    const _data = data as IDropdownItem
                    const label = _data?.label as string
                    const countryName = label?.split('-')[1].trim()
                    formik?.setFieldValue(`${entity}.countryName`, countryName)
                }}
            />
            <FormDropdown
                isSearchable
                label={t('bookings.contact_name')}
                name={`${entity}.contactName`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_name'),
                })}
                parentDivClassName="!mb-0 col-span-2"
                options={contactNameOptions}
            />
            <FormInput
                label={t('bookings.contact_email_address')}
                name={`${entity}.contactEmailAddress`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_email_address'),
                })}
                parentDivClassName="!mb-0 col-span-2"
            />
            <FormInput
                label={t('bookings.contact_phone')}
                name={`${entity}.contactPhoneNumber`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_phone'),
                })}
                parentDivClassName="!mb-0"
            />
            <FormInput
                label={t('bookings.contact_mobile')}
                name={`${entity}.contactMobileNumber`}
                placeholder={t('action.enter', {
                    value: t('bookings.contact_mobile'),
                })}
                parentDivClassName="!mb-0"
            />
        </div>
    )
}

export default LogisticsEntitiesForm
