import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { useTranslation } from 'react-i18next'
import { IOption } from '@components/measurement-input/measurement-input.interface'
import { FormikContextType } from 'formik'
import { ICreateBookingValidation } from 'client/ifb/form-validation/booking-details.validation'
import { bookingsDataDropdownSelector } from '../bookings.slice'
import { useSelector } from 'react-redux'
import useCreateBooking from '../create-booking/create-booking.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

const BookingInformation = ({
    idForm,
    formik,
}: {
    idForm: string
    formik?: FormikContextType<ICreateBookingValidation>
}) => {
    const { t } = useTranslation()
    const dropdownData = useSelector(bookingsDataDropdownSelector)
    const { loadOptions } = useCreateBooking()
    const sortDataDropdown = () => {
        let options
        switch (idForm) {
            case 'loose':
                options = [
                    {
                        label: 'AIR - Air Freight',
                        value: 'AIR',
                    },
                ]
                break
            case 'fcl':
                options = [
                    {
                        label: 'SEA - Sea Freight',
                        value: 'SEA',
                    },
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                    {
                        label: 'RAI - Rail Freight',
                        value: 'RAI',
                    },
                ]
                break
            case 'ftl':
                options = [
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                ]
                break
        }
        return options
    }
    return (
        <>
            <div>{t('bookings.booking_information')}</div>
            <div className="flex gap-3">
                <div className={`flex flex-col gap-3 w-full`}>
                    <FormDropdown
                        isSearchable
                        label={'Transport Mode'}
                        name={`transportModeCode`}
                        placeholder={t('action.select', {
                            value: 'Transport Mode',
                        })}
                        parentDivClassName="!mb-0"
                        options={sortDataDropdown()}
                        additionalOnClick={(data) => {
                            if (!formik) return
                            const _data = data as IDropdownItem
                            const label = _data?.label as string
                            const value = label?.split('-')[1].trim()
                            formik?.setFieldValue(`transportModeDesc`, value)
                        }}
                    />
                    <FormInput
                        label={'Container Mode'}
                        name={`containerMode`}
                        placeholder={t('action.enter', {
                            value: 'Container Mode',
                        })}
                        disabled
                        parentDivClassName="!mb-0 "
                    />
                    <FormInput
                        label={t('bookings.shippers_ref')}
                        name={`loglinesBookingCreateBookingDetails.shippersRef`}
                        placeholder={t('action.enter', {
                            value: t('bookings.shippers_ref'),
                        })}
                        parentDivClassName="!mb-0"
                    />
                    <FormDropdown
                        isSearchable
                        label={t('bookings.service_level')}
                        name={`loglinesBookingCreateBookingDetails.serviceLevelCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.service_level'),
                        })}
                        parentDivClassName="!mb-0"
                        options={dropdownData.serviceLevel}
                        additionalOnClick={(data) => {
                            if (!formik) return
                            const _data = data as IDropdownItem
                            const label = _data?.label as string
                            const value = label?.split('-')[1].trim()
                            formik?.setFieldValue(
                                `loglinesBookingCreateBookingDetails.serviceLevelDescription`,
                                value,
                            )
                        }}
                    />
                    <FormDropdown
                        isSearchable
                        label={t('bookings.incoterm')}
                        name={`loglinesBookingCreateBookingDetails.incoTermCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.incoterm'),
                        })}
                        parentDivClassName="!mb-0"
                        options={dropdownData.incOterm}
                        additionalOnClick={(data) => {
                            if (!formik) return
                            const _data = data as IDropdownItem
                            const label = _data?.label as string
                            const value = label?.split('-')[1].trim()
                            formik?.setFieldValue(
                                `loglinesBookingCreateBookingDetails.incoTermDescription`,
                                value,
                            )
                        }}
                    />
                    <FormInput
                        label={t('bookings.additional_terms')}
                        name={`loglinesBookingCreateBookingDetails.additionalTerms`}
                        placeholder={t('action.enter', {
                            value: t('bookings.additional_terms'),
                        })}
                        parentDivClassName="!mb-0 "
                    />
                    <FormInput
                        label={t('bookings.goods_value')}
                        name={`loglinesBookingCreateBookingDetails.goodsValue`}
                        placeholder={t('action.enter', {
                            value: t('bookings.goods_value'),
                        })}
                        parentDivClassName="!mb-0"
                    />
                    <FormDropdown
                        isSearchable
                        label={t('bookings.goods_value_currency')}
                        name={`loglinesBookingCreateBookingDetails.goodsValueCurrencyCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.goods_value_currency'),
                        })}
                        parentDivClassName="!mb-0"
                        options={dropdownData.currency}
                        defaultValue={dropdownData.currency}
                        isAsync={true}
                        loadOptions={(e: string) =>
                            loadOptions({ currencyItem: e })
                        }
                        additionalOnClick={(data) => {
                            if (!formik) return
                            const _data = data as IDropdownItem
                            const label = _data?.label as string
                            const value = label?.split('-')[1].trim()
                            formik?.setFieldValue(
                                `loglinesBookingCreateBookingDetails.goodsValueCurrencyDescription`,
                                value,
                            )
                        }}
                    />
                    <FormInput
                        label={t('bookings.insurance_value')}
                        name={`loglinesBookingCreateBookingDetails.insuranceValue`}
                        placeholder={t('action.enter', {
                            value: t('bookings.insurance_value'),
                        })}
                        parentDivClassName="!mb-0"
                    />
                    <FormDropdown
                        isSearchable
                        label={t('bookings.insurance_value_currency')}
                        name={`loglinesBookingCreateBookingDetails.insuranceValueCurrencyCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.insurance_value_currency'),
                        })}
                        parentDivClassName="!mb-0"
                        isAsync={true}
                        loadOptions={(e: string) =>
                            loadOptions({ currencyItem: e })
                        }
                        options={dropdownData.currency}
                        defaultValue={dropdownData.currency}
                        additionalOnClick={(data) => {
                            if (!formik) return
                            const _data = data as IDropdownItem
                            const label = _data?.label as string
                            const value = label?.split('-')[1].trim()
                            formik?.setFieldValue(
                                `loglinesBookingCreateBookingDetails.insuranceValueCurrencyDescription`,
                                value,
                            )
                        }}
                    />
                    <FormInput
                        label={t('bookings.packs')}
                        name={`loglinesBookingCreateBookingDetails.packs`}
                        placeholder={t('action.enter', {
                            value: t('bookings.packs'),
                        })}
                        parentDivClassName="!mb-0"
                    />
                    <FormDropdown
                        isSearchable
                        label={t('bookings.outer_pack_unit')}
                        name={`loglinesBookingCreateBookingDetails.outerPacksUnitCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.outer_pack_unit'),
                        })}
                        parentDivClassName="!mb-0"
                        options={dropdownData.packsUnit}
                    />
                    <FormDropdown
                        isSearchable
                        label={'Charges Apply'}
                        name={`chargesApplyCode`}
                        placeholder={t('action.select', {
                            value: 'Charges Apply',
                        })}
                        parentDivClassName="!mb-0"
                        options={dropdownData.chargesApply}
                        additionalOnClick={(data) => {
                            if (!formik) return
                            const _data = data as IDropdownItem
                            const label = _data?.label as string
                            const value = label?.split('-')[1].trim()
                            formik?.setFieldValue(
                                `chargesApplyDescription`,
                                value,
                            )
                        }}
                    />
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <FormMeasurementInput
                        options={dropdownData.volumeMeasure as IOption[]}
                        name={'specialField.volumeMeasure'}
                        label={t('bookings.volume_measure')}
                        parentDivClassName=""
                        placeholder={t('action.enter', {
                            value: t('bookings.volume_measure'),
                        })}
                    />
                    <FormMeasurementInput
                        options={dropdownData.weightMeasure as IOption[]}
                        name={'specialField.weightMeasure'}
                        label={t('bookings.weight_measure')}
                        parentDivClassName=""
                        placeholder={t('action.enter', {
                            value: t('bookings.weight_measure'),
                        })}
                    />
                    <FormTextarea
                        name={
                            'loglinesBookingCreateBookingDetails.goodsDescription'
                        }
                        label={t('bookings.goods_description')}
                        parentDivClassName="row-span-2"
                        placeholder={t('action.enter', {
                            value: t('bookings.goods_description'),
                        })}
                    />
                    <FormTextarea
                        name={
                            'loglinesBookingCreateBookingDetails.marksAndNumbers'
                        }
                        label={t('bookings.marks_and_numbers')}
                        parentDivClassName="row-span-2"
                        placeholder={t('action.enter', {
                            value: t('bookings.marks_and_numbers'),
                        })}
                    />
                </div>
            </div>
        </>
    )
}

export default BookingInformation
