import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import {
    dataSimpleLine,
    itemListInfoGhdgEvolution,
    itemListInfoGhgTrend,
} from './emissions.static'
import { SimpleLineChart } from '@components/rechart-component'
import LogoIfb from '@assets/img/logos/IFBLogo.png'
import LogoGyrn from '@assets/img/logos/grynLogo.png'
import imgFinance from '@assets/img/gallery/imageChart.png'
import Button from '@components/button/button.component'
import { useTranslation } from 'react-i18next'

function Emissions() {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col gap-2">
            <div className="grid gap-2 lg:grid-cols-12 md:grid-cols-1">
                <div className="grid gap-2 lg:col-span-6 lg:grid-cols-3">
                    <CardsDashboard className="">
                        <div className=" themes-text-gray-v3 font-light text-[12px] mb-1">
                            <div className="font-bold">
                                {' '}
                                {t('emissions.data_score_gilec')}
                                <i className="ri-error-warning-line font-normal text-[12px]" />
                            </div>
                        </div>
                        <div className="flex gap-1">
                            <div className="rounded-md text-[20px] themes-text-red-v3 themes-bg-red-v1 w-[40px] h-[40px] flex items-center justify-center">
                                D
                            </div>
                            <div className="rounded-md text-[20px] themes-bg-extra-red-v3 themes-text-extra-red-v4  w-[40px] h-[40px] flex items-center justify-center">
                                D+
                            </div>
                            <div className="rounded-md text-[20px] themes-bg-extra-green-v1 themes-text-extra-green-v2 w-[40px] h-[40px] flex items-center justify-center">
                                M
                            </div>
                            <div className="rounded-md text-[20px] themes-bg-blue-v1 themes-text-blue-v2 w-[40px] h-[40px] flex items-center justify-center">
                                M+
                            </div>
                            <div className="rounded-md text-[20px] themes-text-violet-v2 themes-bg-violet-v1 w-[40px] h-[40px] flex items-center justify-center">
                                P
                            </div>
                        </div>
                    </CardsDashboard>
                    <CardsDashboard className="">
                        <div className=" themes-text-gray-v3 font-light text-[12px] ">
                            <div className="font-bold">
                                {t('emissions.co2e_emission')}{' '}
                                <i className="ri-error-warning-line font-normal text-[12px]" />
                            </div>
                        </div>
                        <div className="text-[20px] themes-text-gray-v9">
                            465T
                        </div>
                    </CardsDashboard>
                    <CardsDashboard className="">
                        <div className=" themes-text-gray-v3 font-light text-[12px] ">
                            <div className="font-bold">
                                {t('emissions.co2e_intensity')}
                                <i className="ri-error-warning-line font-normal text-[12px]" />
                            </div>
                        </div>
                        <div className="text-[20px] themes-text-gray-v9">
                            30 g/tkm
                        </div>
                    </CardsDashboard>
                </div>
                <div className="grid gap-2 lg:col-span-6 lg:grid-cols-12">
                    <CardsDashboard className="lg:col-span-4">
                        <div className=" themes-text-gray-v3 font-light text-[12px] ">
                            <div className="font-bold">
                                {t('emissions.transport_activity')}
                                <i className="ri-error-warning-line font-normal text-[12px]" />
                            </div>
                        </div>
                        <div className="text-[20px] themes-text-gray-v9">
                            32,46M tkm
                        </div>
                    </CardsDashboard>
                    <CardsDashboard className="lg:col-span-8">
                        <div className=" themes-text-gray-v3 font-light text-[12px] ">
                            <div className="font-bold">
                                {t('emissions.total')}{' '}
                                <i className="ri-error-warning-line font-normal text-[12px]" />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-[90px] border-r-[1px]">
                                <div className="text-[20px] themes-text-gray-v9">
                                    3,58M
                                </div>
                                <div className="text-[12px] themes-text-gray-v5 leading-3">
                                    {t('emissions.km')}
                                    <br />
                                    {t('emissions.travelled')}
                                </div>
                            </div>
                            <div className="ml-2 w-[90px] border-r-[1px]">
                                <div className="text-[20px] themes-text-gray-v9">
                                    196
                                </div>
                                <div className="text-[12px] themes-text-gray-v5 leading-3">
                                    {t('emissions.shipments')}
                                    <br />
                                    {t('emissions.delivered')}
                                </div>
                            </div>
                            <div className=" ml-2 w-[90px] border-r-[1px]">
                                <div className="text-[20px] themes-text-gray-v9">
                                    0
                                </div>
                                <div className="text-[12px] themes-text-gray-v5 leading-3">
                                    {t('emissions.items')}
                                    <br />
                                    {t('emissions.shipped')}
                                </div>
                            </div>
                            <div className="ml-2 w-[90px]">
                                <div className="text-[20px] themes-text-gray-v9">
                                    1,960
                                </div>
                                <div className="text-[12px] themes-text-gray-v5 leading-3">
                                    {t('emissions.volume_t')}
                                    <br />
                                    {t('emissions.shipmend')}
                                </div>
                            </div>
                        </div>
                    </CardsDashboard>
                </div>
            </div>
            <div className="grid gap-2 lg:h-[calc(100vh-220px)] lg:grid-rows-12">
                <div className="grid gap-2 lg:grid-cols-2 lg:row-span-7 md:grid-cols-1">
                    <CardsDashboard className="flex flex-col pt-0">
                        <div className=" w-full py-[4.5px] border-b flex justify-between items-center">
                            <div className=" themes-text-gray-v3 font-light text-[12px] ">
                                <div className="font-bold">
                                    {t('emissions.ghg_trend')}
                                </div>
                                <div>{t('emissions.monthly')}</div>
                            </div>
                            <ItemListInfo
                                itemListInfo={itemListInfoGhdgEvolution}
                            />
                        </div>
                        <SimpleLineChart
                            data={dataSimpleLine}
                            colorPV={'#266EDC'}
                            fillPV={'#266FDC2F'}
                            colorUV={'#A647F0'}
                            fillUV={'#A747F038'}
                        />
                    </CardsDashboard>
                    <CardsDashboard className="flex flex-col pt-0">
                        <div className=" w-full py-[4.5px] border-b flex justify-between items-center">
                            <div className=" themes-text-gray-v3 font-light text-[12px] ">
                                <div className="font-bold">
                                    {t('emissions.ghg_evolution')}
                                </div>
                                <div>{t('emissions.monthly')}</div>
                            </div>
                            <ItemListInfo itemListInfo={itemListInfoGhgTrend} />
                        </div>
                        <SimpleLineChart
                            data={dataSimpleLine}
                            colorPV={'#266EDC'}
                            fillPV={'#266FDC2F'}
                            colorUV={'#A647F0'}
                            fillUV={'#A747F038'}
                        />
                    </CardsDashboard>
                </div>
                <div className="grid gap-2 lg:grid-cols-2 lg:row-span-5 md:grid-cols-1">
                    <CardsDashboard>
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center border-[1px] rounded-md px-2 py-2">
                                <div className="border-r-[1px] pr-2 text-[26px] h-[40px]">
                                    <i className="ri-ship-2-fill" />
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.percent_of_total')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        2,33%
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.co2e')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        296.71 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.volume')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        1,462.37 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.shipments')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        112
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="ml-2 rounded-md text-[20px] themes-text-red-v3 themes-bg-red-v1 w-[40px] h-[40px] flex items-center justify-center">
                                    D
                                </div>
                            </div>
                            <div className="flex items-center border-[1px] rounded-md px-2 py-2">
                                <div className="border-r-[1px] pr-2 text-[26px] h-[40px]">
                                    <i className="ri-ship-2-fill" />
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.percent_of_total')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        2,33%
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.co2e')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        296.71 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.volume')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        1,462.37 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.shipments')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        112
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="ml-2 rounded-md text-[20px] themes-text-red-v3 themes-bg-red-v1 w-[40px] h-[40px] flex items-center justify-center">
                                    D
                                </div>
                            </div>
                            <div className="flex items-center border-[1px] rounded-md px-2 py-2">
                                <div className="border-r-[1px] pr-2 text-[26px] h-[40px]">
                                    <i className="ri-ship-2-fill" />
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.percent_of_total')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        2,33%
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.co2e')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        296.71 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.volume')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        1,462.37 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.shipments')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        112
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="ml-2 rounded-md text-[20px] themes-text-red-v3 themes-bg-red-v1 w-[40px] h-[40px] flex items-center justify-center">
                                    D
                                </div>
                            </div>
                            <div className="flex items-center border-[1px] rounded-md px-2 py-2">
                                <div className="border-r-[1px] pr-2 text-[26px] h-[40px]">
                                    <i className="ri-ship-2-fill" />
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.percent_of_total')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        2,33%
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.co2e')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        296.71 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.volume')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        1,462.37 t
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.shipments')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        112
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="border-r-[1px] px-2 leading-3 h-[40px] w-[100px] flex flex-col justify-center">
                                    <div className="text-[12px] themes-text-gray-v5">
                                        {t('emissions.intensity')}
                                    </div>
                                    <div className="text-[16px] themes-text-gray-v6">
                                        25.51 g/tkm
                                    </div>
                                </div>
                                <div className="ml-2 rounded-md text-[20px] themes-text-red-v3 themes-bg-red-v1 w-[40px] h-[40px] flex items-center justify-center">
                                    D
                                </div>
                            </div>
                        </div>
                    </CardsDashboard>
                    <CardsDashboard className="">
                        <div className="border-[1px] p-2 w-full h-full rounded-md flex flex-col justify-between">
                            <div className="flex items-center">
                                <img
                                    src={LogoIfb}
                                    alt="logoifb"
                                    className={`w-[64px] h-auto`}
                                />
                                <img
                                    src={LogoGyrn}
                                    alt="logogyrn"
                                    className={`w-[64px] h-[24px]`}
                                />
                            </div>
                            <div className="flex">
                                <img
                                    src={imgFinance}
                                    alt="logogyrn"
                                    className={`w-[400px] h-auto`}
                                />
                                <div className="w-full h-full flex flex-col justify-center">
                                    <div className="text-[14px] max-w-[300px] w-full themes-text-gray-v9 ">
                                        {t('emissions.click')}{' '}
                                        <span className="font-bold">
                                            “{t('emissions.proceed')}”
                                        </span>{' '}
                                        {t('emissions.redirect_to_gryn')}
                                    </div>
                                    <div className="flex gap-2 mt-[5rem]">
                                        <Button
                                            type="submit"
                                            label={t(
                                                'emissions.request_free_gryn_account',
                                            )}
                                            className="w-full"
                                            variant="brand-inverse"
                                            onClick={() => {}}
                                        />
                                        <Button
                                            type="submit"
                                            label={t(
                                                'emissions.proceed_to_gryn',
                                            )}
                                            className="w-full"
                                            variant="brand-v2"
                                            onClick={() => {}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardsDashboard>
                </div>
            </div>
        </div>
    )
}

export default Emissions
