import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import usePerformances from './performances.service'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { FormikProvider } from 'formik'
import Perfom from './content/perfom.component'
import Transit from './content/transit.component'
import Costs from './content/costs.component'
import Button from '@components/button/button.component'
import FormMonthYearPicker from '@components/form-month-year-picker/form-month-year-picker.component'
import { filterShipmentDateBy } from './performances.static'

function Performances() {
    const performancesService = usePerformances()

    const content = () => {
        if (performancesService.tabFilter === 'performance')
            return <Perfom performancesService={performancesService} />
        else if (performancesService.tabFilter === 'transitTime')
            return <Transit performancesService={performancesService} />
        else if (performancesService.tabFilter === 'costs')
            return <Costs performancesService={performancesService} />
    }

    return (
        <div className="flex flex-col gap-3 overflow-auto xl:overflow-hidden">
            <CardsDashboard className="grid grid-cols-12 gap-3">
                <div className="col-span-12 grid flex-col gap-3 grid-cols-2 justify-center xl:border-r xl:pr-3 xl:col-span-4">
                    <Button
                        onClick={() => {
                            performancesService.setTabFilter('performance')
                        }}
                        className={`${performancesService.tabFilter === 'performance' ? 'themes-bg-brand-v4 themes-text-white ' : ' themes-bg-brand-v2 themes-text-brand-v4'} transition-all duration-300 cursor-pointer px-5 py-1 rounded-md flex items-center text-[12px] col-span-1`}
                        icon="ri-lightbulb-flash-line text-size-XL"
                        label="PERFORMANCE"
                        labelClass="sm:!block sm:!pl-2"
                    />
                    <Button
                        onClick={() => {
                            performancesService.setTabFilter('transitTime')
                        }}
                        className={`${performancesService.tabFilter === 'transitTime' ? 'themes-bg-brand-v4 themes-text-white ' : ' themes-bg-brand-v2 themes-text-brand-v4'} transition-all duration-300 cursor-pointer px-5 py-1 rounded-md flex items-center text-[12px]  col-span-1`}
                        icon="ri-route-line text-size-XL"
                        label="TRANSIT TIME"
                        labelClass="sm:!block sm:!pl-2"
                    />
                    <Button
                        onClick={() => {
                            performancesService.setTabFilter('costs')
                        }}
                        className={`${performancesService.tabFilter === 'costs' ? 'themes-bg-brand-v4 themes-text-white ' : ' themes-bg-brand-v2 themes-text-brand-v4'} transition-all duration-300 cursor-pointer px-5 py-1 rounded-md flex items-center text-[12px] col-span-2`}
                        icon="ri-price-tag-2-line text-size-XL"
                        label="COSTS"
                        labelClass="sm:!block sm:!pl-2"
                    />
                </div>
                <FormikProvider value={performancesService.formikPerformance}>
                    <div className="col-span-12 grid grid-cols-12 gap-3 xl:col-span-8">
                        <div className="grid grid-cols-2 gap-3 md:border-r md:pr-3 xl:col-span-5 md:col-span-8 sm:col-span-12">
                            <FormDropdown
                                label={'SHIPMENT DATE BY'}
                                name={`shipmentDateBy`}
                                placeholder="Select Here"
                                parentDivClassName="!mb-0 sm:col-span-2 md:col-span-2 lg:col-span-2"
                                options={filterShipmentDateBy}
                            />
                            <FormMonthYearPicker
                                name="startMonth"
                                label="START MONTH"
                                placeholder="MM/YYYY"
                                hideError
                            />
                            <FormMonthYearPicker
                                name="endMonth"
                                label="END MONTH"
                                placeholder="MM/YYYY"
                                hideError
                            />
                        </div>
                        {/* hide for next feature */}
                        {/* {performancesService.tabFilter === 'costs' && (
                            <div className="md:border-r md:pr-3 xl:col-span-5 md:col-span-6 sm:col-span-12">
                                <FormDropdown
                                    isSearchable
                                    label={'CURRENCY'}
                                    name={`currency`}
                                    placeholder="Select Here"
                                    options={filterCurrency}
                                    isClearable
                                />
                            </div>
                        )} */}
                    </div>
                </FormikProvider>
            </CardsDashboard>
            <div className="grid grid-cols-12 grid-rows-2 gap-3 xl:h-[calc(100vh-15.2rem)]">
                {content()}
            </div>
        </div>
    )
}

export default Performances
