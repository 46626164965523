import { ITabItem } from '@components/tab/tab.interface'

export const quotationDetailsHeader: ITabItem[] = [
    {
        label: 'Quotation Details',
        labelTranslationCode: 'tab_details.quotation_details',
        key: 'status',
        value: 'quotationDetails',
        className: 'brand',
    },
    {
        label: 'Tracking',
        labelTranslationCode: 'tab_details.tracking',
        key: 'status',
        value: 'tracking',
        className: 'brand',
    },
    {
        label: 'eDocs',
        labelTranslationCode: 'tab_details.edocs',
        key: 'status',
        value: 'eDocs',
        className: 'brand',
    },
    {
        label: 'Documents',
        labelTranslationCode: 'tab_details.documents',
        key: 'status',
        value: 'documents',
        className: 'brand',
    },
]
