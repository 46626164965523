import { useEffect, useState } from 'react';
var useProgressionStep = function (items, selectedItem) {
    var _a = useState(0), activeIndex = _a[0], setActiveIndex = _a[1];
    var _b = useState(items.map(function (data) { return data.label; })), labels = _b[0], setLabels = _b[1];
    useEffect(function () {
        setActiveIndex(items.findIndex(function (item) { return item.value === selectedItem.value; }));
    }, [selectedItem]);
    return {
        labels: labels,
        activeIndex: activeIndex,
        setLabels: setLabels,
        setActiveIndex: setActiveIndex,
    };
};
export default useProgressionStep;
