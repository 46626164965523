/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import { ITabItem } from '@components/tab/tab.interface'
import { useState } from 'react'
import { formBookingStatusHeader } from '../create-booking/create-booking.static'
import { useFormik } from 'formik'
import {
    ICreateBookingPackLinesValidation,
    useBookingsCreateValidation,
} from 'client/ifb/form-validation/booking-details.validation'
import { Toast } from '@components/toast/toast.component'
import { useNavigate } from 'react-router-dom'
import PackLines from '../create-booking/contents/pack-lines.component'
import {
    bookingDetails,
    bookingPackLinesDummy,
} from '../booking-details/booking-details.dummy'
import Details from './contents/details.component'
import { editBookingValidation } from 'client/ifb/form-validation/edit-booking.validation'
import {
    IBookingDetails,
    IBookingPackLines,
} from '../booking-details/booking-details.interface'
import { tabConsigneeHeaders } from '../bookings.static'

const useEditBooking = () => {
    const navigate = useNavigate()
    // validate
    const {
        createBookingPackLinesInitialValue,
        createBookingPackLinesValidation,
    } = useBookingsCreateValidation()

    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>(
        formBookingStatusHeader[0],
    )
    const [consignorTabFilter, setConsignorTabFilter] = useState<ITabItem>(
        tabConsigneeHeaders[0],
    )
    const [consigneeTabFilter, setConsigneeTabFilter] = useState<ITabItem>(
        tabConsigneeHeaders[0],
    )
    const [packLinesData, setPackLinesData] = useState<IBookingPackLines[]>(
        bookingPackLinesDummy,
    )
    const [packLineData, setPackLineData] = useState<IBookingPackLines>(
        createBookingPackLinesInitialValue,
    )
    const [packLineParams, setPackLineParams] = useState<
        'update' | 'add' | 'duplicate' | 'delete'
    >('update')

    // formik
    const formikEditBooking = useFormik<IBookingDetails>({
        validationSchema: editBookingValidation,
        initialValues: bookingDetails,
        onSubmit: () => {
            Toast({
                header: 'Succes',
                message: 'Booking successfully edited',
                type: 'success',
            })
            navigate('/booking-details')
        },
    })
    const formikEditBookingPackLines =
        useFormik<ICreateBookingPackLinesValidation>({
            validationSchema: createBookingPackLinesValidation,
            initialValues: packLineData,
            enableReinitialize: true,
            onSubmit: (values, { resetForm }) => {
                let updateData = []
                let resetValues = null

                switch (packLineParams) {
                    case 'add':
                        updateData = [
                            ...packLinesData,
                            { ...values, id: packLinesData.length + 1 },
                        ]
                        resetValues = createBookingPackLinesInitialValue
                        break
                    case 'update':
                        updateData = packLinesData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        break
                    case 'duplicate':
                        updateData = [
                            ...packLinesData,
                            { ...values, id: packLinesData.length + 1 },
                        ]
                        break
                    case 'delete':
                        updateData = packLinesData.filter(
                            (item) => item.id !== values.id,
                        )
                        resetValues = createBookingPackLinesInitialValue
                        break
                    default:
                        updateData = packLinesData
                        break
                }

                setPackLinesData(updateData)
                if (resetValues !== null) {
                    resetForm({ values: resetValues })
                }
            },
        })

    // functions
    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: (
                <Details
                    consignorTabFilter={consignorTabFilter}
                    setConsignorTabFilter={setConsignorTabFilter}
                    consigneeTabFilter={consigneeTabFilter}
                    setConsigneeTabFilter={setConsigneeTabFilter}
                    formik={formikEditBooking}
                />
            ),
            // packLines: (
            //     <PackLines
            //         packLinesData={packLinesData}
            //         formik={formikEditBookingPackLines}
            //         rowOnClick={(data) => {
            //             setPackLineData(data)
            //         }}
            //         setPackLineParams={(data) => setPackLineParams(data)}
            //     />
            // ),
        }
        return contents[tabFilter.value]
    }

    return { setTabFilter, getContent, navigate, formikEditBooking, tabFilter }
}

export default useEditBooking
