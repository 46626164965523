import TableExpandableDetail from '@components/table-expandable-detail/table-expandable-detail.component'
import { TrackingSummaryDetailHeader } from 'client/ifb/pages/trackings/trackings.static'
import GoogleMap from '@components/google-map/google-map.component'
import { ITrackingSumary } from 'client/ifb/pages/trackings/trackings.interface'
import { IMeta } from 'common/common.interface'

const Tracking = ({
    data,
    meta,
    handlingPageNumberTracking,
}: {
    data: ITrackingSumary[]
    meta: IMeta
    handlingPageNumberTracking: (pageNumber: number) => void
}) => {
    return (
        <div className="flex sm:flex-wrap w-full h-[calc(100vh-290px)]">
            <div className="w-1/2 sm:w-full sm:h-[50%]">
                <TableExpandableDetail<ITrackingSumary>
                    data={data || []}
                    loading={false}
                    summaryDetail={TrackingSummaryDetailHeader}
                    nextHandling={(page) => handlingPageNumberTracking(page)}
                    previousHandling={(page) =>
                        handlingPageNumberTracking(page)
                    }
                    moduleTitle={'Trackings'}
                    enableExport={false}
                    meta={meta}
                />
            </div>
            <div className="w-1/2 sm:w-full themes-bg-white rounded-[10px] p-[5px]  sm:mt-[60px] md:h-full sm:h-[40%] h-[calc(100vh-290px)] shadow-md ">
                <GoogleMap />
            </div>
        </div>
    )
}

export default Tracking
