import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import { ITracking, ITrackingFilter } from './interface/tracking.interface'

export const getTrackingsData = async (filter: ITrackingFilter) => {
    try {
        const response = await fetch<
            IResponseData<ITracking[]>,
            ITrackingFilter
        >({
            endpoint: ifbEndpoints.tracking_getAll,
            params: filter,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<ITracking[]>
        err.isSuccess = false
        return err
    }
}

// export const getShipmentDetails = async (shipmentId: string) => {
//     try {
//         const response = await fetch<IResponseData<IShipmentDetails>, string>({
//             endpoint: `${ifbEndpoints.shipment_getAll}/${shipmentId}`,
//         })
//         return response
//     } catch (error: any) {
//         console.error(error)
//         const err = error as IResponseData<IShipmentDetails>
//         err.isSuccess = false
//         return err
//     }
// }
