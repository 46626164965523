import { useState } from 'react'
import Details from './content/details.component'
import { ITabItem } from '@components/tab/tab.interface'
import { useModal } from '@components//modal/modal.service'
import EDocs from './content/e-docs.component'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'
import {
    IDocketLinesOrders,
    IDocumentsOrders,
    IMilestonesOrders,
    IEventsOrders,
    IReletedInvoiceOrders,
    IReservedOrders,
} from '../orders.interface'
import { ISTColumn } from '@components/simple-table/simple-table.interface'
import {
    DocketLinesOrdersConfigureColumns,
    DocumentsOrdersConfigureColumns,
    EventsOrdersConfigureColumns,
    ReservedOrdersConfigureColumns,
    MilestonesOrdersConfigureColumns,
    ReletedInvoiceOrdersConfigureColumns,
    DocumentsOrdersHeader,
    DocketLinesOrdersHeader,
    ReservedOrdersOrdersHeader,
    ReletedInvoiceOrdersHeader,
    MilestonesOrdersHeader,
    EventsOrdersHeader,
} from './orders-details.static'
import Documents from './content/documents.component'
import DocketLines from './content/docketLines.component'
import Reserved from './content/reserved.component'
import Milestones from './content/milestones.component'
import Event from './content/event.component'
import ReletedInvoice from './content/related-invoice.component'
import Message from './content/message.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

export const useOrdersDetails = () => {
    const [headersDocuments, setHeadersDocuments] = useState<
        ISTColumn<IDocumentsOrders>[]
    >(DocumentsOrdersHeader)
    const [headersDocketLines, setHeadersDocketLines] = useState<
        ISTColumn<IDocketLinesOrders>[]
    >(DocketLinesOrdersHeader)
    const [headersReserved, setHeadersReserved] = useState<
        ISTColumn<IReservedOrders>[]
    >(ReservedOrdersOrdersHeader)
    const [headersReletedInvoice, setHeadersReletedInvoice] = useState<
        ISTColumn<IReletedInvoiceOrders>[]
    >(ReletedInvoiceOrdersHeader)
    const [headersMilestones, setHeadersMilestones] = useState<
        ISTColumn<IMilestonesOrders>[]
    >(MilestonesOrdersHeader)
    const [headersEvents, setHeadersEvents] =
        useState<ISTColumn<IEventsOrders>[]>(EventsOrdersHeader)
    const [fromPeople, setFromPeople] = useState<IDropdownItem<undefined>[]>([])

    const updatePeople = (addPeople: IDropdownItem<undefined>) => {
        if (
            addPeople &&
            !fromPeople.some((person) => person.value === addPeople.value)
        ) {
            setFromPeople((prevPeople) => [...prevPeople, addPeople])
        }
    }

    const removePerson = (person: IDropdownItem<undefined>) => {
        setFromPeople((prevPeople) =>
            prevPeople.filter((p) => p.value !== person.value),
        )
    }

    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'View Orders Details',
        key: 'status',
        value: 'details',
        className: 'brand',
    })

    // modal
    const ConfigureColumnsModalServiceDocuments = useModal()
    const ConfigureColumnsModalServiceDocketLines = useModal()
    const ConfigureColumnsModalServiceReserved = useModal()
    const ConfigureColumnsModalServiceReletedInvoice = useModal()
    const ConfigureColumnsModalServiceMilestones = useModal()
    const ConfigureColumnsModalServiceEvents = useModal()

    const getContentOrdersDetails = (tabFilter: ITabItem) => {
        const items: Record<string, JSX.Element> = {
            details: Details(),
            docketLines: DocketLines(),
            reserved: Reserved(),
            relatedInvoices: ReletedInvoice(),
            documents: Documents(),
            eDocs: EDocs(),
            messages: Message(),
            milestones: Milestones(),
            events: Event(),
        }
        return items[tabFilter?.value] || <></>
    }

    // // segment general
    const allModalDocuments = (
        <>
            <ConfigureColumnsModal<IDocumentsOrders>
                modalService={ConfigureColumnsModalServiceDocuments}
                columns={DocumentsOrdersConfigureColumns}
                setHeaders={setHeadersDocuments}
            />
        </>
    )

    const allModalDocketLines = (
        <>
            <ConfigureColumnsModal<IDocketLinesOrders>
                modalService={ConfigureColumnsModalServiceDocketLines}
                columns={DocketLinesOrdersConfigureColumns}
                setHeaders={setHeadersDocketLines}
            />
        </>
    )

    const allModalReserved = (
        <>
            <ConfigureColumnsModal<IReservedOrders>
                modalService={ConfigureColumnsModalServiceReserved}
                columns={ReservedOrdersConfigureColumns}
                setHeaders={setHeadersReserved}
            />
        </>
    )

    const allModalReletedInvoice = (
        <>
            <ConfigureColumnsModal<IReletedInvoiceOrders>
                modalService={ConfigureColumnsModalServiceReletedInvoice}
                columns={ReletedInvoiceOrdersConfigureColumns}
                setHeaders={setHeadersReletedInvoice}
            />
        </>
    )

    const allModalMilestones = (
        <>
            <ConfigureColumnsModal<IMilestonesOrders>
                modalService={ConfigureColumnsModalServiceMilestones}
                columns={MilestonesOrdersConfigureColumns}
                setHeaders={setHeadersMilestones}
            />
        </>
    )

    const allModalEvents = (
        <>
            <ConfigureColumnsModal<IEventsOrders>
                modalService={ConfigureColumnsModalServiceEvents}
                columns={EventsOrdersConfigureColumns}
                setHeaders={setHeadersEvents}
            />
        </>
    )

    return {
        getContentOrdersDetails,
        setTabFilter,
        tabFilter,
        // Documents
        allModalDocuments,
        headersDocuments,
        ConfigureColumnsModalServiceDocuments,
        //DocketLines
        allModalDocketLines,
        headersDocketLines,
        ConfigureColumnsModalServiceDocketLines,
        // Reserved
        allModalReserved,
        headersReserved,
        ConfigureColumnsModalServiceReserved,
        //ReletedInvoice
        allModalReletedInvoice,
        headersReletedInvoice,
        ConfigureColumnsModalServiceReletedInvoice,
        //Milestones,
        allModalMilestones,
        headersMilestones,
        ConfigureColumnsModalServiceMilestones,
        //Events
        allModalEvents,
        headersEvents,
        ConfigureColumnsModalServiceEvents,
        //massage
        fromPeople,
        updatePeople,
        removePerson,
    }
}
