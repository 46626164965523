import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import { BarChartCustom, BarChartDouble } from '@components/rechart-component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import Pagination from '@components/pagination/pagination.component'
import {
    itemListShipmentsTransit,
    tabPerformanceItems,
} from '../performances.static'
import {
    IMilestoneTransitTime,
    IMilestoneTransitTimeItemAdditionalValue,
    IUsePerformances,
} from '../performances.interface'
import Tab from '@components/tab/tab.component'
import TableMilestoneShipment from '../components/table-milestone-shipment.component'
import { numberWithCommas } from 'common/common.service'

function Transit({
    performancesService,
}: {
    performancesService: IUsePerformances
}) {
    return (
        <>
            <CardsDashboard className="!p-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[750px] xl:h-full">
                <div className="p-2 h-1/2 border-b flex flex-col">
                    <div className="font-bold text-size-S 2xl:text-size-M">
                        Average Transit Time
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.averageTransitTime
                                .infoCardGroupItems
                        }
                        titleClass="!text-size-S"
                        descClass="!text-size-XS"
                    />
                    <BarChartCustom
                        data={performancesService.averageTransitTime.chartData}
                        isLoading={
                            performancesService.loadingTransitTime
                                .averageTransitTime
                        }
                    />
                </div>
                <div className="flex flex-col h-1/2 px-2 pb-2">
                    <div className="font-bold  text-size-S 2xl:text-size-M">
                        Average Transit Time by Mode (Days)
                    </div>
                    <div className="flex-grow">
                        <BarChartCustom
                            data={performancesService.averageTransitTimeByMode}
                            isLoading={
                                performancesService.loadingTransitTime
                                    .averageTransitTimeByMode
                            }
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex gap-3 w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  lg:flex-row lg:h-[492px] md:flex-col md:h-[500px] sm:flex-col sm:h-[500px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold flex-grow  text-size-S 2xl:text-size-M">
                            Shipments Transit Time by Ports
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[645px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListShipmentsTransit}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex"
                                valueClass={{
                                    valueNumber: '!text-size-XS',
                                    valueString: '!text-size-XS',
                                }}
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={
                                    performancesService
                                        .shipmentsTransitTimeByPorts.meta
                                }
                                previousHandling={(page) =>
                                    performancesService.setPageNumberShipmentsTransitTimeByPorts(
                                        page,
                                    )
                                }
                                nextHandling={(page) =>
                                    performancesService.setPageNumberShipmentsTransitTimeByPorts(
                                        page,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartDouble
                            data={
                                performancesService.shipmentsTransitTimeByPorts
                                    .chartData
                            }
                            isLoading={
                                performancesService.loadingTransitTime
                                    .shipmentsTransitTimeByPorts
                            }
                            renderTooltipValue={({ value, dataKey }) => {
                                const loadLabel =
                                    dataKey === 'pv'
                                        ? 'Planned Transit Time'
                                        : 'Actual Transit Time'
                                const loadValue = value
                                    ? numberWithCommas(value.toString())
                                    : 0
                                return `${loadLabel}: ${loadValue}`
                            }}
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex w-full col-span-12 row-span-2 lg:h-[492px] xl:col-span-8 xl:row-span-1 xl:h-full lg:flex-row md:flex-col md:h-[500px] sm:flex-col sm:h-[722px]">
                <div className="flex flex-col w-full h-full">
                    <div className="flex gap-2 justify-between items-center border-b flex-wrap ">
                        <Tab
                            containerClassName="!overflow-y-hidden !text-size-XS flex flex-grow items-center pt-3 pl-3"
                            items={tabPerformanceItems}
                            tabFilter={performancesService.tabFilterTransitTime}
                            onChange={(item) => {
                                performancesService.setTabFilterTransitTime(
                                    item,
                                )
                                // reset pagination
                                performancesService.setPageNumbersTableTransitTime(
                                    1,
                                )
                            }}
                        />

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[820px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={[
                                    {
                                        label: 'Actual Transit Time',
                                        color: 'gray-v5',
                                    },
                                ]}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex"
                                valueClass={{
                                    valueNumber: '!text-size-XS',
                                    valueString: '!text-size-XS',
                                }}
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer text-size-XS"
                                meta={performancesService.tableTransitTime.meta}
                                previousHandling={(page) =>
                                    performancesService.setPageNumbersTableTransitTime(
                                        page,
                                    )
                                }
                                nextHandling={(page) =>
                                    performancesService.setPageNumbersTableTransitTime(
                                        page,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <TableMilestoneShipment<
                        IMilestoneTransitTime,
                        IMilestoneTransitTimeItemAdditionalValue
                    >
                        data={performancesService.tableTransitTime.tableData}
                        pageContent={performancesService.tabFilter}
                        isLoading={
                            performancesService.loadingTransitTime
                                .tableTransitTime
                        }
                    />
                </div>
            </CardsDashboard>
        </>
    )
}

export default Transit
