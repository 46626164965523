var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTableExpandableRow } from "./table-expandable-row.services";
import "../table-expandable.style.css";
import { useEffect, useRef } from "react";
import Button from "@components/button/button.component";
export function TableExpandableRow(_a) {
    var props = __rest(_a, []);
    var _b = useTableExpandableRow(props), data = _b.data, isExpand = _b.isExpand, childData = _b.childData, setIsExpand = _b.setIsExpand;
    var iconExpand = isExpand ? "ri-arrow-up-s-line " : "ri-arrow-down-s-line ";
    var rowBgColor = !isExpand || props.highlightOnExpand === false
        ? "themes-bg-white"
        : "themes-bg-brand-v2";
    var additionalClass = !isExpand ? "hide" : "show";
    var contentRef = useRef(null);
    useEffect(function () {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = !isExpand
                ? "0px"
                : "".concat(contentRef.current.scrollHeight, "px");
        }
    }, [isExpand, childData]);
    return (_jsxs(_Fragment, { children: [_jsxs("tr", __assign({ className: "tr-expandable  ".concat(rowBgColor, " ").concat(additionalClass) }, { children: [_jsx("td", __assign({ className: "th-expandable !w-[50px] ", onClick: function () {
                            setIsExpand(!isExpand);
                        } }, { children: _jsx("i", { className: "".concat(iconExpand) }) })), props.headerParent.map(function (col, idx) {
                        var accessor = col.accessor;
                        // skip icon button
                        if (accessor === "expandable-button-icon") {
                            return _jsx(_Fragment, {});
                        }
                        // get the value
                        var value = null;
                        var tempData = __assign({}, data);
                        if (accessor.includes(".")) {
                            var accessorArray = accessor.split(".");
                            for (var _i = 0, accessorArray_1 = accessorArray; _i < accessorArray_1.length; _i++) {
                                var key = accessorArray_1[_i];
                                value = tempData === null || tempData === void 0 ? void 0 : tempData[key];
                            }
                        }
                        else {
                            value = tempData[accessor];
                        }
                        var width = col.width ? col.width + "px" : "";
                        var minWidth = col.minWidth ? col.minWidth + "px" : "";
                        var height = col.height ? col.height + "px" : "";
                        var val = !value || value === "" ? (_jsx("div", __assign({ className: "text-center w-full" }, { children: "-" }))) : (value);
                        var ellipsis = col.ellipsis ? " text-ellipsis overflow-hidden" : "";
                        var inline = col.customBuild ? "" : "!inline-block align-middle";
                        //dimas
                        // return
                        return (_jsx("td", __assign({ className: "td-expandable", onClick: function () {
                                if (accessor !== "action") {
                                    setIsExpand(!isExpand);
                                }
                            }, style: {
                                width: width,
                                minWidth: minWidth,
                            } }, { children: _jsx("div", __assign({ style: {
                                    height: height,
                                    width: col.ellipsis ? width : "",
                                    minWidth: minWidth,
                                }, className: "".concat((col === null || col === void 0 ? void 0 : col.className) || "", "  ").concat(inline, " ").concat(ellipsis) }, { children: !col.customBuild ? val : col.customBuild(val, data) })) }), "col-" + idx));
                    })] })), _jsxs("tr", __assign({ className: "tr-child hide" }, { children: [_jsx("td", {}), _jsx("td", __assign({ colSpan: props.headerParent.length - 1 }, { children: _jsx("div", __assign({ className: "extendable", ref: contentRef }, { children: _jsx("div", __assign({ className: "m-2  rounded border themes-border-colorthemes-bg-gray-v2" }, { children: _jsxs("table", __assign({ className: "table-child" }, { children: [_jsx("thead", __assign({ className: "child-thead-expandable" }, { children: props.headerChild.map(function (data, idx) {
                                                var showLabel = data.showLabel === undefined ? true : data.showLabel;
                                                var width = data.width ? data.width + "px" : "";
                                                var minWidth = data.minWidth ? data.minWidth + "px" : "";
                                                return (_jsx("th", __assign({ style: {
                                                        width: width,
                                                        minWidth: minWidth,
                                                    }, className: "th-expandable" }, { children: _jsx("div", __assign({ className: "".concat((data === null || data === void 0 ? void 0 : data.headerClassName) || "") }, { children: !showLabel ? null : data.label }), data.accessor) }), "header-" + idx));
                                            }) })), _jsxs("tbody", { children: [childData.length > 0 ? (_jsx(_Fragment, {})) : (_jsxs("tr", { children: [_jsx("td", __assign({ colSpan: props.headerChild.length - 1, className: "themes-text-gray-v6 pl-2" }, { children: "Please add Line Item(s)" })), _jsx("td", __assign({ className: "td-expandable text-right h-[40px]" }, { children: _jsx(Button, { onClick: function () {
                                                                    props.addChildRowHandling &&
                                                                        props.addChildRowHandling();
                                                                }, style: { height: "100%" }, iconClassName: "text-logistical-blue", className: "!p-0 !h-[30px] !w-[50px]", variant: "brand-inverse", icon: "ri-add-line ri-1x" }) }))] })), childData.map(function (data, rowIndex) {
                                                    return (_jsx("tr", { children: props.headerChild.map(function (col, idx) {
                                                            var accessor = col.accessor;
                                                            // get the value
                                                            var value = null;
                                                            if (accessor.includes(".")) {
                                                                var accessorArray = accessor.split(".");
                                                                for (var _i = 0, accessorArray_2 = accessorArray; _i < accessorArray_2.length; _i++) {
                                                                    var key = accessorArray_2[_i];
                                                                    value = data === null || data === void 0 ? void 0 : data[key];
                                                                }
                                                            }
                                                            else {
                                                                value = data[accessor];
                                                            }
                                                            var ellipsis = col.ellipsis
                                                                ? " text-ellipsis overflow-hidden"
                                                                : "";
                                                            var width = col.width ? col.width + "px" : "";
                                                            var height = col.height
                                                                ? col.height + "px"
                                                                : "50px";
                                                            var minWidth = col.minWidth
                                                                ? col.minWidth + "px"
                                                                : "";
                                                            // return
                                                            var val = !value || value === "" ? (_jsx("div", __assign({ className: "text-center w-full" }, { children: "-" }))) : (value);
                                                            var inline = col.customBuild
                                                                ? ""
                                                                : "!inline-block align-middle";
                                                            return (_jsx("td", __assign({ style: {
                                                                    width: width,
                                                                    height: height,
                                                                    minWidth: minWidth,
                                                                }, className: "td-expandable" }, { children: _jsx("div", __assign({ style: {
                                                                        width: col.ellipsis ? width : "",
                                                                    }, className: "".concat((col === null || col === void 0 ? void 0 : col.className) || "", " ").concat(inline, " ").concat(ellipsis) }, { children: !col.customBuild
                                                                        ? val
                                                                        : col.customBuild(val, data, props.index, rowIndex) })) }), "col-" + idx));
                                                        }) }));
                                                })] })] })) })) })) }))] }))] }));
}
