import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import { IFilterPerformance } from './interface/performance.interface'
import moment from 'moment'
import { IFilterCO } from '../form-validation/control-tower.validation'
import { IRelatedKeyFiguresResponse } from './interface/control-tower.interface'

const getLastDateOfMonth = (monthYear: string): string => {
    const endMonth = monthYear
    const lastDate = moment(endMonth, 'MM/YYYY').endOf('month')

    if (!lastDate.isValid()) {
        return 'Invalid Date'
    }

    return lastDate.format('YYYY-MM-DD')
}

const setUpdatedFilter = (filter: IFilterCO) => {
    const { startMonth, endMonth } = filter

    const [_startMonth, _startYear] = startMonth.split('/')

    const formattedStartMonth = `${_startYear}-${_startMonth}-01`
    const formattedEndMonth = getLastDateOfMonth(endMonth)

    const updatedFilter = {
        StartDate: formattedStartMonth,
        EndDate: formattedEndMonth,
        ShipmentDateBy: filter.dateType,
    }

    return updatedFilter
}

export const fetchRelatedKeyFigures = async (filter: IFilterCO) => {
    const updatedFilter = setUpdatedFilter(filter)
    try {
        const response = await fetch<
            IResponseData<IRelatedKeyFiguresResponse>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.control_tower_RelatedKeyFigure,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IRelatedKeyFiguresResponse>
        console.error(err)
        err.isSuccess = false
        return err
    }
}
