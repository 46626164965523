import Button from '@components/button/button.component'
import {
    ReceiptsEDocsDummy,
    ReceiptsEDocsHeaders,
} from '../receipts-details.static'
import SimpleTable from '@components/simple-table/simple-table.component'
import Input from '@components/input/input.component'
import Dropdown from '@components/dropdown/dropdown.component'
import { useTranslation } from 'react-i18next'
import { metaDummy } from '../../receipts.dummy'
import { IReceiptsDocs } from '../../receipts.interface'

const EDocumentation = () => {
    const { t } = useTranslation()
    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <div className="h-full grid grid-cols-12">
                <div className="col-span-9">
                    <SimpleTable<IReceiptsDocs>
                        headers={ReceiptsEDocsHeaders}
                        data={ReceiptsEDocsDummy}
                        usePagination
                        nextHandling={(page) => {
                            console.log('hit Next API -> ' + page)
                        }}
                        previousHandling={(page) => {
                            console.log('hit Prev API -> ' + page)
                        }}
                        meta={metaDummy}
                    />
                </div>
                <div className="col-span-3 border-y-2 flex flex-col justify-between">
                    <div>
                        <div className="flex justify-between items-center px-3 py-2 text-[16px] border-b-2">
                            <span className="themes-text-gray-v5 ">
                                {t('eDocs.selected_edocs')}
                            </span>
                            <div className="text-[24px] flex">
                                <div className="border-x-2 px-2">
                                    <i className="ri-file-copy-line themes-text-hover-green-v3" />
                                </div>
                                <div className="pl-2">
                                    <i className="ri-delete-bin-line themes-text-hover-red-v3" />
                                </div>
                            </div>
                        </div>
                        <div className="p-3 flex flex-col gap-3">
                            <Input
                                label={t('eDocs.file_name')}
                                value="invoice_12345.pdf"
                            />
                            <Dropdown
                                options={[
                                    {
                                        label: 'Invoice for order #12345',
                                        value: 'Invoice for order #12345',
                                    },
                                ]}
                                defaultValue={[
                                    {
                                        label: 'Invoice for order #12345',
                                        value: 'Invoice for order #12345',
                                    },
                                ]}
                                label={t('eDocs.description')}
                                onClick={() => {}}
                                placeholder={t('action.select', {
                                    value: t('eDocs.description'),
                                })}
                                isClearable={true}
                                isMultiSelect={true}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 p-3 border-t-2">
                        <Button
                            onClick={() => {}}
                            label={t('action.save') + ' ' + t('action.change')}
                            className="w-full"
                            variant="brand"
                        />
                        <Button
                            onClick={() => {}}
                            label={t('action.re_upload')}
                            className="w-full"
                            variant="brand-inverse"
                        />
                        <Button
                            onClick={() => {}}
                            label={t('action.discard', {
                                value: t('action.change'),
                            })}
                            className="w-full"
                            variant="red-inverse"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EDocumentation
