import { IMeta } from 'common/common.interface'

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
