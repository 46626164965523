import Button from '@components/button/button.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import { useOrdersDetails } from '../orders-details.service'
import { linesData, metaDummy } from '../../orders.dummy'
import { IReservedOrders } from '../../orders.interface'

const Reserved = () => {
    const {
        allModalReserved,
        headersReserved,
        ConfigureColumnsModalServiceReserved,
    } = useOrdersDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <SimpleTable<IReservedOrders>
                headers={headersReserved}
                data={linesData}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy}
                additionalButtonBottom={
                    <>
                        <Button
                            onClick={() =>
                                ConfigureColumnsModalServiceReserved.openModalHandling()
                            }
                            label="Configure Column"
                            className="w-[10rem] ml-0"
                            variant="brand-inverse"
                        />
                    </>
                }
            />
            {allModalReserved}
        </div>
    )
}

export default Reserved
