import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import {
    IBookingStatusRespon,
    IContainerModerRespon,
    INewsData,
    IScfiData,
    IShipmentStatusRespon,
    IShipmentStatusRespon2,
} from '../pages/dashboard/dashboard.interface'
import { Toast } from '@components/toast/toast.component'
import { IDashboardFilterValidation } from '../form-validation/dashboard.validation'
import { IShipment } from './interface/shipment.interface'
import { metaDataDropdown } from '../pages/bookings/bookings.interface'

export const fetchNewsData = async () => {
    try {
        const response: IResponseData<INewsData[]> | undefined = await fetch({
            endpoint: ifbEndpoints.news_scrapper,
        })

        if (!response) {
            return []
        }

        if (response.isSuccess) {
            return response.data
        } else {
            Toast({
                header: 'Error',
                message: 'Failed to fetch news data.',
                type: 'error',
            })
            return []
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'News',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'News',
                message: `Unknown error occurred while fetching news data."`,
                type: 'error',
            })
        }
        return []
    }
}

export const fetchShipmenteStatus = async (
    filter: IDashboardFilterValidation,
) => {
    try {
        const { StartMonth, EndMonth, TransportModeCode, ...rest } = filter

        const [startMonth, startYear] = StartMonth.split('/')
        const [endMonth, endYear] = EndMonth.split('/')

        const formattedStartMonth = `${startYear}-${startMonth}-01`
        const formattedEndMonth = `${endYear}-${endMonth}-28`

        const updatedFilter = {
            ...rest,
            StartMonth: formattedStartMonth,
            EndMonth: formattedEndMonth,
            TransportModeCode:
                TransportModeCode === 'all' ? '' : TransportModeCode,
        }
        const response = await fetch<
            IResponseData<IShipmentStatusRespon>,
            IDashboardFilterValidation
        >({
            endpoint: ifbEndpoints.shipmentStatus,
            params: updatedFilter,
        })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<IShipmentStatusRespon>
        err.isSuccess = false
        return err
    }
}

export const fetchShipmenteStatus2 = async (
    filter: IDashboardFilterValidation,
) => {
    try {
        const { StartMonth, EndMonth, TransportModeCode, ...rest } = filter

        const [startMonth, startYear] = StartMonth.split('/')
        const [endMonth, endYear] = EndMonth.split('/')

        const formattedStartMonth = `${startYear}-${startMonth}-01`
        const formattedEndMonth = `${endYear}-${endMonth}-28`

        const updatedFilter = {
            ...rest,
            StartMonth: formattedStartMonth,
            EndMonth: formattedEndMonth,
            TransportModeCode:
                TransportModeCode === 'all' ? '' : TransportModeCode,
        }
        const response = await fetch<
            IResponseData<IShipmentStatusRespon2>,
            IDashboardFilterValidation
        >({
            endpoint: ifbEndpoints.shipmentMilestoneStatus,
            params: updatedFilter,
        })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<IShipmentStatusRespon2>
        err.isSuccess = false
        return err
    }
}

export const fetchBookingStatus = async (
    filter: IDashboardFilterValidation,
) => {
    try {
        const { StartMonth, EndMonth, TransportModeCode, ...rest } = filter

        const [startMonth, startYear] = StartMonth.split('/')
        const [endMonth, endYear] = EndMonth.split('/')

        const formattedStartMonth = `${startYear}-${startMonth}-01`
        const formattedEndMonth = `${endYear}-${endMonth}-28`

        const updatedFilter = {
            ...rest,
            StartMonth: formattedStartMonth,
            EndMonth: formattedEndMonth,
            TransportModeCode:
                TransportModeCode === 'all' ? '' : TransportModeCode,
        }
        const response = await fetch<
            IResponseData<IBookingStatusRespon>,
            IDashboardFilterValidation
        >({
            endpoint: ifbEndpoints.bookingStatus,
            params: updatedFilter,
        })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<IBookingStatusRespon>
        err.isSuccess = false
        return err
    }
}

export const fetchtDropdownDashboard = async () => {
    try {
        const endpoint = `${ifbEndpoints.bookingsGetMetadata}`

        const response = await fetch<metaDataDropdown, string>({
            endpoint: endpoint.toString(),
        })

        return response
    } catch (error: unknown) {
        const errorMessage =
            error instanceof Error
                ? error.message
                : 'Unknown error occurred while fetching Bookings Metadata.'

        Toast({
            header: 'Bookings Metadata',
            message: errorMessage,
            type: 'error',
        })

        return error
    }
}

export const fetchShipmentsData = async (
    filter: IDashboardFilterValidation,
) => {
    try {
        const { StartMonth, EndMonth, TransportModeCode, ...rest } = filter

        const [startMonth, startYear] = StartMonth.split('/')
        const [endMonth, endYear] = EndMonth.split('/')

        const formattedStartMonth = `${startYear}-${startMonth}-01`
        const formattedEndMonth = `${endYear}-${endMonth}-28`

        const updatedFilter = {
            ...rest,
            StartMonth: formattedStartMonth,
            EndMonth: formattedEndMonth,
            TransportModeCode:
                TransportModeCode === 'all' ? '' : TransportModeCode,
        }

        const response = await fetch<
            IResponseData<IShipment[]>,
            IDashboardFilterValidation
        >({
            endpoint: ifbEndpoints.shipment_getAll,
            params: updatedFilter,
        })

        if (!response.isSuccess) {
            Toast({
                header: 'Shipments data',
                message: `Failed to fetch Shipments data.`,
                type: 'error',
            })
        }

        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Shipments data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Shipments data',
                message: `Unknown error occurred while fetching Shipments data."`,
                type: 'error',
            })
        }

        const err = error as IResponseData<IShipment[]>
        err.isSuccess = false
        return err
    }
}

export const fetchContainerModeStatus = async (
    filter: IDashboardFilterValidation,
) => {
    try {
        const { StartMonth, EndMonth, TransportModeCode, ...rest } = filter

        const [startMonth, startYear] = StartMonth.split('/')
        const [endMonth, endYear] = EndMonth.split('/')

        const formattedStartMonth = `${startYear}-${startMonth}-01`
        const formattedEndMonth = `${endYear}-${endMonth}-28`

        const updatedFilter = {
            ...rest,
            StartMonth: formattedStartMonth,
            EndMonth: formattedEndMonth,
            TransportModeCode:
                TransportModeCode === 'all' ? '' : TransportModeCode,
        }
        const response = await fetch<
            IResponseData<IContainerModerRespon>,
            IDashboardFilterValidation
        >({
            endpoint: ifbEndpoints.containerModeStatus,
            params: updatedFilter,
        })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<IContainerModerRespon>
        err.isSuccess = false
        return err
    }
}

export const fetchScfiData = async () => {
    try {
        const response: IResponseData<IScfiData[]> | undefined = await fetch({
            endpoint: ifbEndpoints.scfi_index,
        })

        if (!response) {
            return []
        }
        if (response.isSuccess) {
            return response.data
        } else {
            Toast({
                header: 'SCFI',
                message: `Failed to fetch SCFI data.`,
                type: 'error',
            })
            return []
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'SCFI data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'SCFI data',
                message: `Unknown error occurred while fetching SCFI data."`,
                type: 'error',
            })
        }
        return []
    }
}
