import Dropdown from '@components/dropdown/dropdown.component'
import Input from '@components/input/input.component'
import DatePicker from '@components/date-picker/date-picker.component'
import { filterWarehouse } from '../orders.static'
import { useTranslation } from 'react-i18next'

const OrderFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full space-y-1">
                <label className={`text-size-S font-bold`}>
                    {t('orders.required_date')}
                </label>
                <DatePicker isRange={true} disableDays="future" />
            </div>
            <div className="flex-col w-full space-y-1">
                <label className={`text-size-S font-bold`}>
                    {t('orders.finalised_date')}
                </label>
                <DatePicker isRange={true} disableDays="future" />
            </div>
            <div className="flex-col w-full">
                <Input
                    label={t('orders.external_reference').toUpperCase()}
                    placeholder={t('action.enter', {
                        value: t('orders.external_reference'),
                    })}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={filterWarehouse}
                    onClick={() => {}}
                    label={t('orders.warehouse_name').toUpperCase()}
                    placeholder={t('action.select', {
                        value: t('orders.warehouse_name'),
                    })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'Total Units',
                            value: 'totalUnits',
                        },
                    ]}
                    onClick={() => {}}
                    placeholder={t('action.add', { value: t('action.filter') })}
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                />
            </div>
        </div>
    )
}

export default OrderFilter
