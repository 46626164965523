var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Info-card-group.style.css';
var InfoCardGroup = function (_a) {
    var items = _a.items, className = _a.className, classNameContainer = _a.classNameContainer, _b = _a.titleClass, titleClass = _b === void 0 ? '' : _b, _c = _a.descClass, descClass = _c === void 0 ? '' : _c;
    var gridCols = items.length === 3 ? 'grid-cols-3' : "grid-cols-".concat(items.length);
    var renderSubValue = function (subValue) {
        var firstChar = subValue.slice(0, 1);
        var classNames = '';
        if (firstChar === '+') {
            classNames = 'themes-text-green-v4';
        }
        else if (firstChar === '-') {
            classNames = 'themes-text-red-v4';
        }
        return (_jsx("span", __assign({ className: "".concat(classNames, " text-[10px] pl-1") }, { children: subValue })));
    };
    return (_jsx("div", __assign({ className: "grid ".concat(gridCols, " themes-bg-gray-v1 border-y themes-border-gray-v4 mb-2 ").concat(classNameContainer) }, { children: items.map(function (item, index) { return (_jsxs("div", __assign({ className: "ml-2  themes-border-gray-v4 ".concat(index + 1 !== items.length && 'border-r', " ").concat(className, " leading-none py-1") }, { children: [_jsxs("div", __assign({ className: "font-bold infoCardGroupTitle flex items-center ".concat(titleClass) }, { children: [item.value, item.subValue && renderSubValue(item.subValue)] })), _jsx("div", __assign({ className: "infoCardGroupDesc ".concat(descClass) }, { children: item.label }))] }), index)); }) })));
};
export default InfoCardGroup;
