var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import './tracker.style.css';
var Tracker = function (_a) {
    var data = _a.data, _b = _a.bgColor, bgColor = _b === void 0 ? 'white' : _b, _c = _a.isLoading, isLoading = _c === void 0 ? true : _c;
    return (_jsxs("div", __assign({ className: "tracker-bg-".concat(bgColor, " h-full rounded-sm p-4 overflow-auto") }, { children: [isLoading ? (_jsx("div", __assign({ className: "text-center pt-2 pb-2" }, { children: " Please Wait " }))) : (_jsx(_Fragment, {})), isLoading ? (_jsx(_Fragment, {})) : (data.map(function (item, idx) {
                var styleCircle = 'w-[12px] h-[12px]';
                var styleLine = 'mx-[4px]';
                var title = item.title && item.title !== ''
                    ? item.title
                    : 'Empty Title';
                var description = item.description && item.description !== ''
                    ? item.description
                    : 'Empty Description';
                var tempStamp = moment(item.at).format('MMM D, YYYY, HH:mm');
                return (_jsxs("div", __assign({ className: "flex ".concat(idx === 0 ? 'active' : 'inactive') }, { children: [_jsxs("div", { children: [_jsx("div", { className: "".concat(styleCircle, " flex items-center mt-3 justify-center rounded-full circle") }), _jsx("div", { className: "h-[102px] w-[4px] ".concat(styleLine, " my-1 bg-black rounded-sm line") })] }), _jsxs("div", __assign({ className: "ml-3" }, { children: [_jsx("div", __assign({ className: "item-status text-size-XL" }, { children: title })), _jsx("div", __assign({ className: "item-description text-size-M" }, { children: description })), _jsxs("div", __assign({ className: "item-details" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", __assign({ className: "w-40" }, { children: "By User" })), _jsxs("div", { children: [": ", item.byUserName] })] })), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", __assign({ className: "w-40" }, { children: "Date/Timestamp" })), _jsxs("div", { children: [": ", tempStamp] })] }))] }))] }))] }), idx));
            }))] })));
};
export default Tracker;
