import {
    IDataCustomersTable,
    IUseControlTower,
} from '../control-tower.interface'
import SplitTable from '@components/split-table/split-table.component'
import {
    tabItemFilterTradeLanes,
    tableCustomersHeaders,
} from '../control-tower.static'
import Tab from '@components/tab/tab.component'
import Pagination from '@components/pagination/pagination.component'
import { metaDummy } from '../../bookings/bookings.dummy'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import ProgressBarChart from '../components/progress-bar-chart.component'
import ProgressMultipleBars from '@components/progress-multiple-bars/progress-multiple-bars.component'

const Sales = ({ service }: { service: IUseControlTower }) => {
    return (
        <>
            <div className="col-span-8 border-r" style={{ height: 'inherit' }}>
                <div className="flex justify-between items-center px-2 pt-2 pb-[3px]">
                    <Tab
                        items={tabItemFilterTradeLanes}
                        tabFilter={service.tabFilterTradeLanes}
                        onChange={(item) => {
                            service.setTabFilterTradeLanes(item)
                        }}
                        containerClassName="!overflow-y-hidden !text-size-XS !p-0 !border-none"
                    />
                    <Pagination
                        containerClassName="!p-0 flex"
                        className="!h-[27px]"
                        btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                        meta={metaDummy}
                        previousHandling={(page) => {}}
                        nextHandling={(page) => {}}
                    />
                </div>
                <InfoCardGroup
                    items={[
                        {
                            value: '300,000',
                            label: 'Overall Shipments',
                        },
                        {
                            value: 'AUD 26,000.00',
                            label: 'Overall Revenue',
                        },
                        {
                            value: 'AUD 7,500.00',
                            label: 'Overall Gross Profit',
                        },
                    ]}
                    titleClass="!text-size-M"
                    descClass="!text-size-XS"
                />
                <div className="flex items-center pl-2">
                    <div className="themes-text-gray-v4 text-size-XS font-bold leading-none">
                        <div>%</div>
                        <div>Revenue</div>
                    </div>
                    <ProgressMultipleBars
                        containerClassNames={`w-full px-2 text-size-S`}
                        data={service.customerChartOrganisationData.data}
                        useTooltip={false}
                        keyItems={
                            service.customerChartOrganisationData.keyItems
                        }
                    />
                </div>
                <div className="h-[calc(100vh-21.5rem)] border-t w-full">
                    <SplitTable<IDataCustomersTable>
                        columns={tableCustomersHeaders}
                        data={service.customerTableData}
                        thClass="text-size-XS"
                        tdClass="text-size-XS"
                        onRowClick={service.loadHistoryData}
                    />
                </div>
            </div>
        </>
    )
}

export default Sales
