import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import useBookings from './bookings.service'
import { IBookings } from './bookings.interface'
import BookingFilterOverlay from './components/booking-filter-overlay.component'
import { useTranslation } from 'react-i18next'

const Bookings = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        generateReportModalService,
        tabFilter,
        ConfigureColumnsModalService,
        allMNodal,
        loading,
        headers,
        navigate,
        tabItems,
        setTabFilter,
        handleSearch,
        bookingsData,
        bookingsDataMeta,
        setPageData,
    } = useBookings()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-1.5 xl:pt-0 sm:pt-0">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={tabFilter}
                    onChange={(item) => {
                        setTabFilter(item)
                    }}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Booking Number'}
                    onSearchSubmit={handleSearch}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <BookingFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/create-booking')
                        },
                        label: t('menu.bookings_new').toUpperCase(),
                        className: '!text-size-S',
                    }}
                />
                <Table<IBookings>
                    headers={headers}
                    data={bookingsData}
                    loading={loading}
                    nextHandling={(page) => {
                        setPageData(page)
                    }}
                    previousHandling={(page) => {
                        setPageData(page)
                    }}
                    meta={bookingsDataMeta}
                    moduleTitle={'Booking'}
                    onRowClick={(data) => {
                        const id = data.bookingNo
                        navigate('/booking-details/' + id)
                    }}
                    enableExport={true}
                    exportModalService={generateReportModalService}
                />
            </div>
            {allMNodal}
        </div>
    )
}

export default Bookings
