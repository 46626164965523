import { ThemeType } from 'common/common.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { currentThemeSelector, setTheme } from 'config/config.slice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getTimezonesDropdown } from 'client/common/repository/timezones.repository'

export const availableThemes: IDropdownItem<ThemeType>[] = [
    { label: 'Bright Sky Blue', value: 'bright-sky-blue-themes' },
    { label: 'Dark Sapphire Blue', value: 'dark-sapphire-blue-themes' },
]

export const useThemes = () => {
    const dispatch = useDispatch()

    const currentThemes = useSelector(currentThemeSelector)

    const [themes] = useState<IDropdownItem<ThemeType>[]>(availableThemes)
    const [selectedThemes, setSelectedThemes] =
        useState<IDropdownItem<ThemeType>>()
    const [timezonesList] = useState(getTimezonesDropdown())

    useEffect(() => {
        themes.forEach((d) => {
            if (currentThemes && d.value === currentThemes) {
                setSelectedThemes(d)
            }
        })
    }, [currentThemes])

    const themesChangesHandling = (val: IDropdownItem<ThemeType>) => {
        dispatch(setTheme(val.value as ThemeType))
    }

    return {
        themes,
        selectedThemes,
        timezonesList,
        themesChangesHandling,
    }
}
