import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {
    IPerformanceFilterValidation,
    usePerformanceFilterValidation,
} from 'client/ifb/form-validation/performance.validation'
import { ITabItem } from '@components/tab/tab.interface'
import { tabPerformanceItems } from './performances.static'
import {
    IFilterPageNumber,
    ILoadAvarageDaysDelayed,
    ILoadingCost,
    ILoadingFetchPerformance,
    ILoadingTransitTime,
    IPerformanceContent,
    IUsePerformances,
} from './performances.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
    avarageDaysDelayedSelector,
    averageTransitTimeByModeSelector,
    averageTransitTimeSelector,
    milestoneGroupDayRangeTableSelector,
    tableTransitTimeSelector,
    monthlyTotalCostSelector,
    portChargesSelector,
    setAllMetaCurrentPage,
    setAvarageDaysDelayed,
    setAverageTransitTime,
    setAverageTransitTimeByMode,
    setLoadShipmentStatus,
    setMilestoneGroupDayRangeTable,
    setMonthlyTotalCost,
    setPageShipmentPerformanceByPorts,
    setPageShipmentsTransitTimeByPorts,
    setPageTotalCostByPorts,
    setPortCharges,
    setShipmentPerformanceByPorts,
    setShipmentsTransitTimeByPorts,
    setTotalCostByModes,
    setTotalCostByPorts,
    shipmentPerformanceByPortsSelector,
    shipmentPerformanceStatusSelector,
    shipmentsTransitTimeByPortsSelector,
    totalCostByModesSelector,
    totalCostByPortsSelector,
    setTableTransitTime,
    resetMilestoneGroupDayRangeTableMetaData,
    resetTableTransitTimeMetaData,
} from './performances.slice'
import {
    fetchAverageDaysDelayed,
    fetchAverageTransitTime,
    fetchAverageTransitTimeByMode,
    fetchMilestoneGroupDayRange,
    fetchMonthlyTotalCost,
    fetchPortCharges,
    fetchShipmentPerformanceByPorts,
    fetchShipmentPerformanceStatus,
    fetchShipmentsTransitTimeByPorts,
    fetchTotalCostByModes,
    fetchTotalCostByPorts,
    fetchTransitTimeRouteGroup,
} from 'client/ifb/repository/performance.repository'
import moment from 'moment'
import { IBarchartStacktedDataItem } from '@components/rechart-component/rechart-interface'
import { Toast } from '@components/toast/toast.component'
import { IToastProps } from '@components/toast/toast.interface'
import { numberToFixedInt } from 'common/common.service'

const usePerformances = (): IUsePerformances => {
    const dispatch = useDispatch()

    // shipment selector
    const shipmentPerformanceStatus = useSelector(
        shipmentPerformanceStatusSelector,
    )
    const shipmentPerformanceByPorts = useSelector(
        shipmentPerformanceByPortsSelector,
    )
    const avarageDaysDelayed = useSelector(avarageDaysDelayedSelector)
    const milestoneGroupDayRangeTable = useSelector(
        milestoneGroupDayRangeTableSelector,
    )

    // selector transit time
    const averageTransitTime = useSelector(averageTransitTimeSelector)
    const shipmentsTransitTimeByPorts = useSelector(
        shipmentsTransitTimeByPortsSelector,
    )
    const averageTransitTimeByMode = useSelector(
        averageTransitTimeByModeSelector,
    )
    const tableTransitTime = useSelector(tableTransitTimeSelector)

    // selector cost
    const totalCostByModes = useSelector(totalCostByModesSelector)
    const monthlyTotalCost = useSelector(monthlyTotalCostSelector)
    const portCharges = useSelector(portChargesSelector)
    const totalCostByPorts = useSelector(totalCostByPortsSelector)

    // states
    const [tabFilter, setTabFilter] =
        useState<IPerformanceContent>('performance')
    const [isInitialRender, setIsInitialRender] = useState(true) // handle first time render
    const [toastError, setToastError] = useState<IToastProps | null>(null)
    const [filterPageNumber, setFilterPageNumber] = useState<IFilterPageNumber>(
        {
            milestoneGroupDayRangeTable: 1,
            tableTransitTime: 1,
        },
    )

    // state performance
    const [tabFilterPerformance, setTabFilterPerformance] = useState<ITabItem>(
        tabPerformanceItems[0],
    )
    const [loadingPerformance, setLoadingPeformance] =
        useState<ILoadingFetchPerformance>({
            shipmentStatus: true,
            avarageDaysDelayed: true,
            shipmentPerformanceByPorts: true,
            milestoneGroupDayRangeTable: true,
        })

    // state transit time
    const [tabFilterTransitTime, setTabFilterTransitTime] = useState<ITabItem>(
        tabPerformanceItems[0],
    )
    const [loadingTransitTime, setLoadingTransitTime] =
        useState<ILoadingTransitTime>({
            averageTransitTime: true,
            averageTransitTimeByMode: true,
            shipmentsTransitTimeByPorts: true,
            tableTransitTime: true,
        })

    // state costs
    const [loadingCost, setLoadingCost] = useState<ILoadingCost>({
        monthlyTotalCost: true,
        totalCostByModes: true,
        portCharges: true,
        totalCostByPorts: true,
    })

    // formik validation
    const { peformanceFilterValidation, peformanceFilterInitialValue } =
        usePerformanceFilterValidation()

    // formik
    const formikPerformance = useFormik<IPerformanceFilterValidation>({
        validationSchema: peformanceFilterValidation,
        initialValues: peformanceFilterInitialValue,
        onSubmit: async () => {},
    })

    // functions
    const wrapFetchDataContent = () => {
        if (
            formikPerformance.errors.endMonth ||
            formikPerformance.errors.startMonth
        ) {
            Toast({
                header: 'Improper Filter Selection!',
                message: formikPerformance.errors.endMonth || '',
                type: 'error',
            })
            return
        }

        setMetaAndRawDataWhenFilterChanges()
        if (tabFilter === 'performance') {
            setLoadingPeformance({
                shipmentStatus: true,
                avarageDaysDelayed: true,
                shipmentPerformanceByPorts: true,
                milestoneGroupDayRangeTable: true,
            })
            fetchData()
        } else if (tabFilter === 'transitTime') {
            setLoadingTransitTime({
                averageTransitTime: true,
                averageTransitTimeByMode: true,
                shipmentsTransitTimeByPorts: true,
                tableTransitTime: true,
            })
            fetchDataTransitTime()
        } else if (tabFilter === 'costs') {
            setLoadingCost({
                monthlyTotalCost: true,
                totalCostByModes: true,
                portCharges: true,
                totalCostByPorts: true,
            })
            fetchDataCosts()
        }
    }
    const setMetaAndRawDataWhenFilterChanges = () => {
        dispatch(setAllMetaCurrentPage())
        dispatch(resetMilestoneGroupDayRangeTableMetaData())
        dispatch(resetTableTransitTimeMetaData())
    }

    // functions performance
    const fetchData = async () => {
        try {
            const statusShipment = fetchShipmentPerformanceStatus(
                formikPerformance.values,
            )
                .then((statusShipment) => {
                    if (statusShipment.isSuccess) {
                        dispatch(setLoadShipmentStatus(statusShipment.data))
                    }
                    if (!statusShipment.isSuccess && !toastError) {
                        setToastError({
                            header: 'Shipments Performance Status',
                            message: `${statusShipment.message}`,
                            type: 'error',
                        })
                    }
                    setLoadingPeformance((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                    setLoadingPeformance((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const shipmentPerformanceByPortsDebounce =
                getshipmentPerformanceByPorts()
            const avarageDaysDelayed = loadAvarageDaysDelayed()
            const milestoneGroupDayRangeTable =
                loadMilestoneGroupDayRangeTable(1)

            await Promise.allSettled([
                statusShipment,
                avarageDaysDelayed,
                shipmentPerformanceByPortsDebounce,
                milestoneGroupDayRangeTable,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setIsInitialRender(false)
        }
    }
    const setPageNumberPerformanceByPorts = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageShipmentPerformanceByPorts(pageNumber))
    }
    const getshipmentPerformanceByPorts = async () => {
        try {
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: true,
            }))
            const actionResult = await fetchShipmentPerformanceByPorts(
                formikPerformance.values,
            )

            if (actionResult.isSuccess) {
                dispatch(setShipmentPerformanceByPorts(actionResult.data))
            }
            if (!actionResult.isSuccess && !toastError) {
                setToastError({
                    header: 'Shipments Performance by Ports',
                    message: `${actionResult.message}`,
                    type: 'error',
                })
            }

            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: false,
            }))
        } catch (error) {
            console.error('Error fetching sea data:', error)
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: false,
            }))
        }
    }
    const loadAvarageDaysDelayed = async () => {
        const start = moment(formikPerformance.values.startMonth, 'MM/YYYY')
        const end = moment(formikPerformance.values.endMonth, 'MM/YYYY')
        let index = 1
        try {
            const fecthData = await fetchAverageDaysDelayed(
                formikPerformance.values,
            )
            if (!fecthData.isSuccess && !toastError) {
                setToastError({
                    header: 'Shipments Performance by Ports',
                    message: `${fecthData.message}`,
                    type: 'error',
                })
                return
            }
            const data: ILoadAvarageDaysDelayed = {
                line1: [],
                line2: [],
                tickItems: [],
                totalAvarageDaysDelayed:
                    fecthData.data.totalData.totalDelayed || 0,
            }
            while (start.isSameOrBefore(end, 'month')) {
                const findDataByMonth = fecthData.data.monthlyData.find(
                    (item) => item.currentPeriod === start.format('YYYY-MM'),
                )
                data.tickItems.push(start.format('MMM'))
                data.line1.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: numberToFixedInt(Number(findDataByMonth?.averageDelay)),
                    keyId: 'line1',
                })
                data.line2.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: numberToFixedInt(
                        Number(findDataByMonth?.previousAverageDelay),
                    ),
                    keyId: 'line2',
                })

                start.add(1, 'month')
                index++
            }

            dispatch(setAvarageDaysDelayed(data))
        } catch (error) {
            console.error('Error fetching data avarage days delayed:', error)
        } finally {
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                avarageDaysDelayed: false,
            }))
        }
    }
    const loadMilestoneGroupDayRangeTable = async (
        filterPageNumberProp: number,
    ) => {
        try {
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                milestoneGroupDayRangeTable: true,
            }))

            const actionResult = await fetchMilestoneGroupDayRange(
                formikPerformance.values,
            )
            if (actionResult.isSuccess) {
                dispatch(
                    setMilestoneGroupDayRangeTable({
                        data: actionResult.data,
                        filterTranportMode: tabFilterPerformance.value,
                        filterPageNumber: filterPageNumberProp,
                    }),
                )
            }
            if (!actionResult.isSuccess && !toastError) {
                setToastError({
                    header: 'Milestone Table',
                    message: `${actionResult.message}`,
                    type: 'error',
                })
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                milestoneGroupDayRangeTable: false,
            }))
        }
    }
    const setPageNumberMilestoneGroupDayRangeTable = (pageNumber: number) => {
        if (pageNumber <= 0) return
        setFilterPageNumber((prevState) => ({
            ...prevState,
            milestoneGroupDayRangeTable: pageNumber,
        }))
    }

    // functions transit time
    const fetchDataTransitTime = async () => {
        try {
            const averageTransitTime = fetchAverageTransitTime(
                formikPerformance.values,
            )
                .then((res) => {
                    if (res.isSuccess) {
                        dispatch(setAverageTransitTime(res.data[0]))
                    }
                    if (!res.isSuccess && !toastError) {
                        setToastError({
                            header: 'Average Transit Time',
                            message: `${res.message}`,
                            type: 'error',
                        })
                    }
                })
                .catch((error) => {
                    console.error('Error fetching sea data:', error)
                })
                .finally(() => {
                    setLoadingTransitTime((prevLoading) => ({
                        ...prevLoading,
                        averageTransitTime: false,
                    }))
                })

            const shipmentsTransitTimeByPorts = getShipmentsTransitTimeByPorts()

            const averageTransitTimeByMode = fetchAverageTransitTimeByMode(
                formikPerformance.values,
            )
                .then((res) => {
                    if (res.isSuccess) {
                        dispatch(setAverageTransitTimeByMode(res.data))
                    }
                    if (!res.isSuccess && !toastError) {
                        setToastError({
                            header: 'Average transit time by mode',
                            message: `${res.message}`,
                            type: 'error',
                        })
                    }
                })
                .catch((error) => {
                    console.error('Error fetching sea data:', error)
                })
                .finally(() => {
                    setLoadingTransitTime((prevLoading) => ({
                        ...prevLoading,
                        averageTransitTimeByMode: false,
                    }))
                })
            const tableTransitTime = loadTableTransitTime(1)

            await Promise.allSettled([
                averageTransitTime,
                shipmentsTransitTimeByPorts,
                averageTransitTimeByMode,
                tableTransitTime,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const getShipmentsTransitTimeByPorts = async () => {
        try {
            setLoadingTransitTime((prevLoading) => ({
                ...prevLoading,
                shipmentsTransitTimeByPorts: true,
            }))
            const actionResult = await fetchShipmentsTransitTimeByPorts(
                formikPerformance.values,
            )

            if (actionResult.isSuccess) {
                dispatch(setShipmentsTransitTimeByPorts(actionResult.data))
            }
            if (!actionResult.isSuccess && !toastError) {
                setToastError({
                    header: 'Shipments Transit Time by Ports',
                    message: `${actionResult.message}`,
                    type: 'error',
                })
            }

            setLoadingTransitTime((prevLoading) => ({
                ...prevLoading,
                shipmentsTransitTimeByPorts: false,
            }))
        } catch (error) {
            console.error('Error fetching sea data:', error)
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: false,
            }))
        }
    }
    const setPageNumberShipmentsTransitTimeByPorts = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageShipmentsTransitTimeByPorts(pageNumber))
    }
    const loadTableTransitTime = async (pageNumber: number) => {
        try {
            setLoadingTransitTime((prevLoading) => ({
                ...prevLoading,
                tableTransitTime: true,
            }))
            const actionResult = await fetchTransitTimeRouteGroup(
                formikPerformance.values,
            )

            if (actionResult.isSuccess) {
                dispatch(
                    setTableTransitTime({
                        data: actionResult.data,
                        filterTranportMode: tabFilterTransitTime.value,
                        pageNumber,
                    }),
                )
            }
            if (!actionResult.isSuccess && !toastError) {
                setToastError({
                    header: 'Milestone Table',
                    message: `${actionResult.message}`,
                    type: 'error',
                })
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoadingTransitTime((prevLoading) => ({
                ...prevLoading,
                tableTransitTime: false,
            }))
        }
    }
    const setPageNumbersTableTransitTime = (pageNumber: number) => {
        if (pageNumber <= 0) return
        setFilterPageNumber((prevState) => ({
            ...prevState,
            tableTransitTime: pageNumber,
        }))
    }

    // functions costs
    const fetchDataCosts = async () => {
        try {
            const totalCostByModes = fetchTotalCostByModes(
                formikPerformance.values,
            )
                .then((res) => {
                    if (res.isSuccess) {
                        dispatch(setTotalCostByModes(res.data))
                    }
                    if (!res.isSuccess && !toastError) {
                        setToastError({
                            header: 'Total cost by modes',
                            message: `${res.message}`,
                            type: 'error',
                        })
                    }
                    setLoadingCost((prevLoading) => ({
                        ...prevLoading,
                        totalCostByModes: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                    setLoadingCost((prevLoading) => ({
                        ...prevLoading,
                        totalCostByModes: false,
                    }))
                })

            const monthlyTotalCost = loadMonthlyTotalCost()

            const portCharges = fetchPortCharges(formikPerformance.values)
                .then((res) => {
                    if (res.isSuccess) {
                        dispatch(setPortCharges(res.data))
                    }
                    if (!res.isSuccess && !toastError) {
                        setToastError({
                            header: 'Port charges',
                            message: `${res.message}`,
                            type: 'error',
                        })
                    }
                    setLoadingCost((prevLoading) => ({
                        ...prevLoading,
                        portCharges: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                    setLoadingCost((prevLoading) => ({
                        ...prevLoading,
                        portCharges: false,
                    }))
                })

            const totalCostByPorts = getTotalCostByPorts()

            await Promise.allSettled([
                totalCostByModes,
                monthlyTotalCost,
                portCharges,
                totalCostByPorts,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const loadMonthlyTotalCost = async () => {
        const start = moment(formikPerformance.values.startMonth, 'MM/YYYY')
        const end = moment(formikPerformance.values.endMonth, 'MM/YYYY')
        const data: IBarchartStacktedDataItem[] = []

        try {
            const fecthData = await fetchMonthlyTotalCost(
                formikPerformance.values,
            )
            if (!fecthData.isSuccess && !toastError) {
                setToastError({
                    header: 'Monthly total cost',
                    message: `${fecthData.message}`,
                    type: 'error',
                })
                return
            }

            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('M')
                const year = start.format('YYYY')
                const sea = fecthData.data
                    .find(({ TransportMode }) => TransportMode === 'SEA')
                    ?.Data.find(
                        (x) =>
                            x.month.toString() === month &&
                            x.year.toString() === year,
                    )
                const air = fecthData.data
                    .find(({ TransportMode }) => TransportMode === 'AIR')
                    ?.Data.find(
                        (x) =>
                            x.month.toString() === month &&
                            x.year.toString() === year,
                    )
                const roa = fecthData.data
                    .find(({ TransportMode }) => TransportMode === 'ROA')
                    ?.Data.find(
                        (x) =>
                            x.month.toString() === month &&
                            x.year.toString() === year,
                    )
                const rai = fecthData.data
                    .find(({ TransportMode }) => TransportMode === 'RAI')
                    ?.Data.find(
                        (x) =>
                            x.month.toString() === month &&
                            x.year.toString() === year,
                    )
                const courier = fecthData.data
                    .find(({ TransportMode }) => TransportMode === 'COURIER')
                    ?.Data.find(
                        (x) =>
                            x.month.toString() === month &&
                            x.year.toString() === year,
                    )

                data.push({
                    name: start.format('MMM'),
                    secName: year,
                    ...(sea?.totalAmount && {
                        f1: sea?.totalAmount,
                    }),
                    ...(air?.totalAmount && {
                        f2: air?.totalAmount,
                    }),
                    ...(roa?.totalAmount && {
                        f3: roa?.totalAmount,
                    }),
                    ...(rai?.totalAmount && {
                        f2: rai?.totalAmount,
                    }),
                    ...(courier?.totalAmount && {
                        f2: courier?.totalAmount,
                    }),
                    amt: 10,
                })

                start.add(1, 'month')
            }

            dispatch(setMonthlyTotalCost(data))
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoadingCost((prevLoading) => ({
                ...prevLoading,
                monthlyTotalCost: false,
            }))
        }
    }
    const getTotalCostByPorts = async () => {
        try {
            setLoadingCost((prevLoading) => ({
                ...prevLoading,
                totalCostByPorts: true,
            }))
            const actionResult = await fetchTotalCostByPorts(
                formikPerformance.values,
            )

            if (actionResult.isSuccess) {
                dispatch(setTotalCostByPorts(actionResult.data))
            }
            if (!actionResult.isSuccess && !toastError) {
                setToastError({
                    header: 'Total cost by ports',
                    message: `${actionResult.message}`,
                    type: 'error',
                })
            }
        } catch (error) {
            console.error('Error fetching sea data:', error)
        } finally {
            setLoadingCost((prevLoading) => ({
                ...prevLoading,
                totalCostByPorts: false,
            }))
        }
    }
    const setPageNumberTotalCostByPorts = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageTotalCostByPorts(pageNumber))
    }

    // useEffects
    useEffect(() => {
        formikPerformance.submitForm()
    }, [])
    useEffect(() => {
        // handle multiple toast
        if (toastError) {
            Toast(toastError)
            setToastError(null)
        }
    }, [toastError])
    useEffect(() => {
        const handler = setTimeout(() => {
            wrapFetchDataContent()
        }, 500)
        return () => clearTimeout(handler)
    }, [formikPerformance.values, formikPerformance.errors])
    useEffect(() => {
        if (!isInitialRender) {
            wrapFetchDataContent()
        }
    }, [tabFilter])

    // useEffect performance
    useEffect(() => {
        if (!isInitialRender) {
            getshipmentPerformanceByPorts()
        }
    }, [shipmentPerformanceByPorts.meta.current_page])
    useEffect(() => {
        if (!isInitialRender) {
            dispatch(resetMilestoneGroupDayRangeTableMetaData()) // reset pagination
            loadMilestoneGroupDayRangeTable(
                filterPageNumber.milestoneGroupDayRangeTable,
            )
        }
    }, [tabFilterPerformance, filterPageNumber.milestoneGroupDayRangeTable])

    // useEffect transit time
    useEffect(() => {
        if (!isInitialRender) {
            getShipmentsTransitTimeByPorts()
        }
    }, [shipmentsTransitTimeByPorts.meta.current_page])
    useEffect(() => {
        if (!isInitialRender) {
            dispatch(resetTableTransitTimeMetaData()) // reset pagination
            loadTableTransitTime(filterPageNumber.tableTransitTime)
        }
    }, [tabFilterTransitTime, filterPageNumber.tableTransitTime])

    // useEffect costs
    useEffect(() => {
        if (!isInitialRender) {
            getTotalCostByPorts()
        }
    }, [totalCostByPorts.meta.current_page])

    return {
        formikPerformance,
        tabFilter,
        tabFilterPerformance,
        tabFilterTransitTime,
        setTabFilterTransitTime,
        setTabFilterPerformance,
        setTabFilter,
        loading: loadingPerformance,
        shipmentPerformanceStatus,
        shipmentPerformanceByPorts,
        setPageNumberPerformanceByPorts,
        avarageDaysDelayed,
        loadingTransitTime,
        averageTransitTime,
        monthlyTotalCost,
        loadingCost,
        shipmentsTransitTimeByPorts,
        setPageNumberShipmentsTransitTimeByPorts,
        averageTransitTimeByMode,
        totalCostByModes,
        portCharges,
        totalCostByPorts,
        setPageNumberTotalCostByPorts,
        milestoneGroupDayRangeTable,
        tableTransitTime,
        setPageNumberMilestoneGroupDayRangeTable,
        setPageNumbersTableTransitTime,
    }
}

export default usePerformances
