import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import { IVolumeFilterValidation } from '../form-validation/volume.validation'
import { IBarDataRespon, IDonatChartShipmentRespon } from '../pages/volumes/volumes.interface'


export const fetchBarData = async (filter: IVolumeFilterValidation, type: string) => {
    try {
        const { StartDate, EndDate, ...rest } = filter;

        const [startMonth, startYear] = StartDate.split('/');
        const [endMonth, endYear] = EndDate.split('/');

        const formattedStartMonth = `${startYear}-${startMonth}-01`;
        const formattedEndMonth = `${endYear}-${endMonth}-28`;

        const updatedFilter = {
            ...rest,
            StartDate: formattedStartMonth,
            EndDate: formattedEndMonth,
            transportMode: type
        };
        const response = await fetch<
            IResponseData<IBarDataRespon>, IVolumeFilterValidation>({
                endpoint: ifbEndpoints.barChartVolume,
                params: updatedFilter
            })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<IBarDataRespon>
        err.isSuccess = false
        return err
    }
}

export const fetchDonatData = async (filter: IVolumeFilterValidation) => {
    try {
        const { StartDate, EndDate, ...rest } = filter;

        const [startMonth, startYear] = StartDate.split('/');
        const [endMonth, endYear] = EndDate.split('/');

        const formattedStartMonth = `${startYear}-${startMonth}-01`;
        const formattedEndMonth = `${endYear}-${endMonth}-28`;

        const updatedFilter = {
            ...rest,
            StartDate: formattedStartMonth,
            EndDate: formattedEndMonth,
        };
        const response = await fetch<
            IResponseData<IDonatChartShipmentRespon>, IVolumeFilterValidation>({
                endpoint: ifbEndpoints.donatChartVolume,
                params: updatedFilter
            })
        return response
    } catch (error: unknown) {
        const err = error as IResponseData<IDonatChartShipmentRespon>
        err.isSuccess = false
        return err
    }
}

