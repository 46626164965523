var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { ErrorMessage, useField } from "formik";
import MeasurementInput from "@components/measurement-input/measurement-input.component";
function FormMeasurementInput(_a) {
    var _b, _c;
    var _d = _a.options, options = _d === void 0 ? [] : _d, _e = _a.parentDivClassName, parentDivClassName = _e === void 0 ? "" : _e, _f = _a.showErrorMessage, showErrorMessage = _f === void 0 ? true : _f, props = __rest(_a, ["options", "parentDivClassName", "showErrorMessage"]);
    //formik
    var _g = useField(props.name), field = _g[0], meta = _g[1], helpers = _g[2];
    var measurement = {
        qty: (_b = field === null || field === void 0 ? void 0 : field.value) === null || _b === void 0 ? void 0 : _b.qty,
        unit: (_c = field === null || field === void 0 ? void 0 : field.value) === null || _c === void 0 ? void 0 : _c.unit,
    };
    var borderColorClass = meta.error && meta.touched ? "themes-border-red-v3" : "";
    var isError = meta.error && meta.touched;
    var errorClassMessage = isError ? "" : "hidden";
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName) }, { children: [_jsx(MeasurementInput, __assign({ options: options, measurement: measurement, setMeasurement: function (change) {
                    helpers.setValue(change);
                }, borderError: borderColorClass }, props)), showErrorMessage && (_jsx("div", __assign({ className: "ml-small-x text-size-XS text-red-500 ".concat(errorClassMessage) }, { children: _jsx(ErrorMessage, __assign({ name: props.name, component: "p" }, { children: function (msg) { return _jsx("p", { children: msg }); } })) })))] })));
}
export default FormMeasurementInput;
