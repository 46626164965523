import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    filterSelector,
    tabStatusFilterSelector,
    trackingsMetaSelector,
    trackingsDataSelector,
    trackingsStatusTotalSelector,
    setTrackingData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
} from './trackings.slice'
import { ITrackingFilter } from 'client/ifb/repository/interface/tracking.interface'
import { getTrackingsData } from 'client/ifb/repository/tracking.repository'
import { isEmptyArray } from 'formik'
import { Toast } from '@components/toast/toast.component'
import { initialFilter } from 'common/common.static'

const useTrackings = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // selector
    const trackingsData = useSelector(trackingsDataSelector)
    const totalStatus = useSelector(trackingsStatusTotalSelector)
    const tabFilter = useSelector(tabStatusFilterSelector)
    const filter = useSelector(filterSelector)
    const trackingsDataMeta = useSelector(trackingsMetaSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [widthSize, setWidthSize] = useState(window.innerWidth)

    // variable

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()

    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    // variable
    const { pageNumber, pageSize, containerNumber, consolStatus } = filter

    // useEffect(() => {
    //     dispatch(setFilter(initialFilter))
    //     // setIsInitialized(true)
    // }, [])

    // get data
    useEffect(() => {
        // if (isInitialized)
        loadData()
    }, [
        pageNumber,
        pageSize,
        consolStatus,
        tabFilter,
        containerNumber,
        // isInitialized,
    ])
    // Get Data Function
    const loadData = async () => {
        const status = tabFilter.value
        let tmpFilter: ITrackingFilter = { ...filter, consolStatus: status }

        if (status === 'all') {
            tmpFilter.consolStatus = ''
        }

        try {
            setLoading(true)
            const actionResult = await getTrackingsData(tmpFilter)
            console.log('actionResult', actionResult)

            dispatch(setTrackingData(actionResult))
            setLoading(false)
            if (isEmptyArray(actionResult.data)) {
                Toast({
                    header: 'Search Not Found!',
                    message:
                        "The keyword you've entered does not display any result.",
                    type: 'error',
                })
            }
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = () => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Mode',
                labelTranslationCode: 'status.all_trackings',
                totalData: totalStatus.all,
                key: 'status',
                value: 'all',
                className: 'brand',
            },
            {
                label: 'SEA',
                labelTranslationCode: 'status.sea',
                totalData: totalStatus.sea,
                key: 'status',
                value: 'SEA',
                className: 'green',
            },
            {
                label: 'AIR',
                labelTranslationCode: 'status.air',
                totalData: totalStatus.air,
                key: 'status',
                value: 'AIR',
                className: 'yellow',
            },
            {
                label: 'ROAD',
                labelTranslationCode: 'status.road',
                totalData: totalStatus.roa,
                key: 'status',
                value: 'LAND',
                className: 'red',
            },
        ]

        setTabItems(tabItems)
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(
                setFilter({
                    ...filter,
                    containerNumber: values,
                    pageNumber: 1,
                }),
            )
        } else if (values.length <= 0)
            dispatch(
                setFilter({ ...filter, containerNumber: '', pageNumber: 1 }),
            )
    }

    const updateDimensions = () => {
        setWidthSize(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    })

    useEffect(() => {
        generateTabItems()
    }, [totalStatus])

    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        trackingsData,
        loading,
        errorMessage,
        widthSize,
        trackingsDataMeta,
        handleSearch,
        setLoading,
        setErrorMessage,
        navigate,
        setTabItems,
        setTabFilter,
        setPageData,
    }
}

export default useTrackings
