/* eslint-disable no-unused-vars */
import LogisticsEntitiesForm from '../../components/logistics-entities-form.component'
import Tab from '@components/tab/tab.component'
import { ITabItem } from '@components/tab/tab.interface'
import OriginForm from '../../components/origin-form.component'
import DestinationForm from '../../components/destination-form.component'
import ChargesForm from '../../components/charges-form.component'
import ShipmentInformationForm from '../../components/shipment-information.component'
import { FormikContextType, FormikProvider } from 'formik'
import { IBookingDetails } from '../../booking-details/booking-details.interface'
import { useTranslation } from 'react-i18next'
import { tabConsigneeHeaders } from '../../bookings.static'

const Details = ({
    consignorTabFilter,
    setConsignorTabFilter,
    consigneeTabFilter,
    setConsigneeTabFilter,
    formik,
}: {
    consignorTabFilter: ITabItem
    setConsignorTabFilter: (data: ITabItem) => void
    consigneeTabFilter: ITabItem
    setConsigneeTabFilter: (data: ITabItem) => void
    formik: FormikContextType<IBookingDetails>
}) => {
    const consignorEntity =
        consignorTabFilter.value === 'pickupAddress'
            ? 'consignorPickupAddress'
            : 'consignorDocumentaryAddress'
    const consigneeEntity =
        consigneeTabFilter.value === 'pickupAddress'
            ? 'consigneePickupAddress'
            : 'consigneeDocumentaryAddress'

    const { t } = useTranslation()
    return (
        <div className="grid grid-cols-4 gap-3 p-3 h-full">
            <FormikProvider value={formik}>
                <div className="flex flex-col gap-3">
                    <div>
                        <div>{t('bookings.consignor')}</div>
                        <Tab
                            items={tabConsigneeHeaders}
                            tabFilter={consignorTabFilter}
                            onChange={(item) => {
                                setConsignorTabFilter(item)
                            }}
                        />
                    </div>
                    <LogisticsEntitiesForm
                        entity={consignorEntity}
                        companyOptions={[
                            {
                                label: 'OneByOne Logistical PTY LTD',
                                value: 'OneByOne Logistical PTY LTD',
                            },
                        ]}
                        contactNameOptions={[
                            {
                                label: 'Aimar',
                                value: 'Aimar',
                            },
                        ]}
                        countryOptions={[
                            {
                                label: 'Noelle',
                                value: 'Noelle',
                            },
                        ]}
                    />
                    <OriginForm />
                </div>
                <div className="flex flex-col gap-3">
                    <div>
                        <div>{t('bookings.consignee')}</div>
                        <Tab
                            items={tabConsigneeHeaders}
                            tabFilter={consigneeTabFilter}
                            onChange={(item) => {
                                setConsigneeTabFilter(item)
                            }}
                        />
                    </div>
                    <LogisticsEntitiesForm
                        entity={consigneeEntity}
                        companyOptions={[
                            {
                                label: 'ABC Company',
                                value: 'ABC Company',
                            },
                        ]}
                        contactNameOptions={[
                            {
                                label: 'Noelle',
                                value: 'Noelle',
                            },
                        ]}
                        countryOptions={[
                            {
                                label: 'Noelle',
                                value: 'Noelle',
                            },
                        ]}
                    />
                    <DestinationForm />
                </div>
                <div className="flex flex-col gap-3">
                    <ChargesForm />
                    <div>{t('bookings.request_bill_to_party')}</div>
                    <LogisticsEntitiesForm
                        entity={'requestBillToParty'}
                        companyOptions={[
                            {
                                label: 'ABC Company',
                                value: 'ABC Company',
                            },
                        ]}
                        contactNameOptions={[
                            {
                                label: 'Noelle',
                                value: 'Noelle',
                            },
                        ]}
                        countryOptions={[
                            {
                                label: 'Noelle',
                                value: 'Noelle',
                            },
                        ]}
                        
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <ShipmentInformationForm />
                </div>
            </FormikProvider>
        </div>
    )
}

export default Details
