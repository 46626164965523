import moment from 'moment'
import * as Yup from 'yup'

// interface for validation
export interface IFilterCO {
    dateType: string
    startMonth: string
    endMonth: string
    company: string
    transportMode: string
    containerMode: string
}

// form validation YUP for formik
export const filterCOValidation = Yup.object({
    startMonth: Yup.string()
        .required('Start Month is required')
        .matches(
            /^(0[1-9]|1[0-2])\/\d{4}$/,
            'Invalid Start Month format (MM/YYYY)',
        )
        .test(
            'is-greater',
            'End Month cannot be earlier then the start month',
            function (value) {
                const { endMonth } = this.parent
                if (!value || !endMonth) return false

                const start = moment(endMonth, 'MM/YYYY')
                const end = moment(value, 'MM/YYYY')

                return start.isAfter(end)
            },
        )
        .test(
            'max-range',
            'Date filter range cannot be more than 12 months',
            function (value) {
                const { endMonth } = this.parent
                if (!value || !endMonth) return false

                const end = moment(endMonth, 'MM/YYYY')
                const start = moment(value, 'MM/YYYY')

                return end.diff(start, 'months') <= 12
            },
        ),

    endMonth: Yup.string()
        .required('End Month is required')
        .matches(
            /^(0[1-9]|1[0-2])\/\d{4}$/,
            'Invalid End Month format (MM/YYYY)',
        )
        .test(
            'not-same',
            'Start month and end month cannot be the same selection',
            function (value) {
                const { startMonth } = this.parent
                if (!value || !startMonth) return false

                return value !== startMonth
            },
        )
        .test(
            'is-greater',
            'End Month cannot be earlier then the start month',
            function (value) {
                const { startMonth } = this.parent
                if (!value || !startMonth) return false

                const start = moment(startMonth, 'MM/YYYY')
                const end = moment(value, 'MM/YYYY')

                return end.isAfter(start)
            },
        )
        .test(
            'max-range',
            'Date filter range cannot be more than 12 months',
            function (value) {
                const { startMonth } = this.parent
                if (!value || !startMonth) return false

                const start = moment(startMonth, 'MM/YYYY')
                const end = moment(value, 'MM/YYYY')

                return end.diff(start, 'months') <= 12
            },
        ),
})

// form initial data
const monthNow = moment().subtract(0, 'months').format('MM/YYYY')
const sixMonthsAgo = moment().subtract(5, 'months').format('MM/YYYY')
export const filterCOInitialValue: IFilterCO = {
    dateType: 'eta',
    startMonth: sixMonthsAgo,
    endMonth: monthNow,
    company: 'All Company',
    transportMode: 'All Modes',
    containerMode: 'All Modes',
}
