var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import './text-area.style.css';
var Textarea = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.readonly, readonly = _c === void 0 ? false : _c, _d = _a.required, required = _d === void 0 ? false : _d, _e = _a.resize, resize = _e === void 0 ? false : _e, _f = _a.disabledVariant, disabledVariant = _f === void 0 ? 'gray' : _f, _g = _a.maxLength, maxLength = _g === void 0 ? 225 : _g, _h = _a.containerClassName, containerClassName = _h === void 0 ? '' : _h, // Ubah properti parentTextareaClassName menjadi containerClassName
    props = __rest(_a, ["disabled", "readonly", "required", "resize", "disabledVariant", "maxLength", "containerClassName"]);
    var textareaRef = useRef(null);
    var _j = useState(false), focus = _j[0], setFocus = _j[1];
    var showLabelClass = !props.label || props.label === '' ? 'hidden' : '';
    var isRequired = required ? 'required' : '';
    var isDisabled = disabled ? "disabled-".concat(disabledVariant) : '';
    var isFocus = focus ? 'themes-text-brand-v5' : '';
    var isResize = !resize ? 'none' : 'both';
    var handleFocusEvent = function () {
        setFocus(true);
    };
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (textareaRef.current &&
                !textareaRef.current.contains(event.target)) {
                setFocus(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return function () {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "textareaParent-style ".concat(containerClassName, " ") }, { children: [_jsx("label", __assign({ className: "".concat(showLabelClass, " themes-text-gray-v5 ") }, { children: _jsx("div", { children: _jsx("p", __assign({ className: "".concat(isRequired, " ").concat(isFocus) }, { children: props.label })) }) })), _jsx("textarea", __assign({}, props, { ref: textareaRef, disabled: disabled, className: "".concat(props.className, " ").concat(isDisabled), placeholder: props.placeholder, value: props.value, readOnly: readonly, required: required, maxLength: maxLength, onChange: function (e) {
                    if (props.onChange)
                        props.onChange(e);
                }, onFocus: handleFocusEvent, style: { resize: isResize } }))] })));
};
export default Textarea;
