import { IShipmentDetails } from 'client/ifb/repository/interface/shipment.interface'

const Details = ({
    data,
    shipmentId,
}: {
    data?: IShipmentDetails
    laoding: boolean
    shipmentId?: string
}) => {
    const loadDataComponent = ({
        label,
        value,
    }: {
        label: string
        value?: string | number
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label}</div>
            <div className="themes-text-gray-v9">{value || '-'}</div>
        </div>
    )

    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 sm:grid-cols-2 mobile:!grid-cols-1 gap-3 h-full">
                {/* shipments details */}
                <div className="flex flex-wrap flex-col gap-2">
                    <div className="text-size-L themes-text-gray-v9 mb-1">
                        Shipment Details
                    </div>
                    {loadDataComponent({
                        label: 'Shipment No.',
                        value: shipmentId,
                    })}
                    {loadDataComponent({
                        label: 'House Bill',
                        value: data?.houseBill,
                    })}
                    {loadDataComponent({
                        label: 'Shipper’s Ref',
                        value: data?.shippersRef,
                    })}
                    {loadDataComponent({
                        label: 'Order Reference Number',
                        value: data?.orderRef,
                    })}
                    {loadDataComponent({
                        label: 'Container Mode',
                        value: data?.containerMode,
                    })}
                    {loadDataComponent({
                        label: 'Transport Mode',
                        value: data?.transportMode,
                    })}
                    {loadDataComponent({
                        label: 'Service Level',
                        value: data?.serviceLevelDescription,
                    })}
                    {loadDataComponent({
                        label: 'Outer Packs',
                        value: `${data?.outerPacksUnitCode ?? ''} - ${data?.outerPacksUnitDescription ?? ''}`,
                    })}
                    {loadDataComponent({
                        label: 'Weight Measure',
                        value: `${data?.totalWeight ?? ''} ${data?.totalWeightUnit ?? ''}`,
                    })}
                    {loadDataComponent({
                        label: 'Volume Measure',
                        value: `${data?.totalVolume ?? ''} ${data?.totalVolumeUnit ?? ''}`,
                    })}
                </div>

                {/* Origin & destination */}
                <div className="flex flex-col gap-3 mobile:border-none mobile:pl-0 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Origin
                        </div>
                        {loadDataComponent({
                            label: 'Origin',
                            value: data?.origin.portName,
                        })}
                        {loadDataComponent({
                            label: 'ETD',
                            value: data?.origin.etdShipment,
                        })}
                        {loadDataComponent({
                            label: 'Estimated Pickup',
                            value: data?.origin.estimatedPickup,
                        })}
                        {loadDataComponent({
                            label: 'Pickup Required By',
                            value: data?.origin.picRequiredBy,
                        })}
                        {loadDataComponent({
                            label: 'Pickup Cartage Advised',
                            value: data?.origin.pickupTrnAdvised,
                        })}
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 my-1">
                            Destination
                        </div>
                        {loadDataComponent({
                            label: 'Destination',
                            value: data?.destination.portName,
                        })}
                        {loadDataComponent({
                            label: 'ETA',
                            value: data?.destination.etaShipment,
                        })}
                        {loadDataComponent({
                            label: 'Estimated Delivery',
                            value: data?.destination.estimatedDelivery,
                        })}
                        {loadDataComponent({
                            label: 'Delivery Required By',
                            value: data?.destination.dlvRequiredBy,
                        })}
                        {loadDataComponent({
                            label: 'Delivery Cartage Advised',
                            value: data?.destination
                                .deliveryPortTransportAdvised,
                        })}
                    </div>
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3 border-l pl-3 sm:border-none sm:pl-0">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Charges
                        </div>
                        {loadDataComponent({
                            label: 'INCO Term',
                            value: data?.charges.incoTerm,
                        })}
                        {loadDataComponent({
                            label: 'Goods Value',
                            value: `${data?.charges.goodsValue ?? ''} ${data?.charges.goodsValueCurrencyCode ?? ''}`,
                        })}
                        {loadDataComponent({
                            label: 'Additional Terms',
                            value: data?.charges.additionalTerms,
                        })}
                        {loadDataComponent({
                            label: 'Insurance Value',
                            value: `${data?.charges.insuranceValue ?? ''} ${data?.charges.insuranceValueCurrencyCode ?? ''}`,
                        })}
                        {loadDataComponent({
                            label: 'Charges Apply',
                            value: data?.charges.chargesApply,
                        })}
                    </div>
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3 border-l pl-3 mobile:pl-0 mobile:border-none mobile:pb-4">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Notes
                        </div>
                        {loadDataComponent({
                            label: 'Special Instructions',
                            value: data?.notes.specialInstructions,
                        })}
                        {loadDataComponent({
                            label: 'Marks and Numbers',
                            value: data?.notes.marksAndNumbers,
                        })}
                        {loadDataComponent({
                            label: 'Goods Description',
                            value: data?.notes.detailedGoodsDesc,
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details
