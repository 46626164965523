import Button from '@components/button/button.component'
import Overlay from '@components/overlay/overlay.component'
import { IUseOverlay } from '@components/overlay/overlay.service'
import ManageUsersFilter from './manage-users-filter.component'
import { useTranslation } from 'react-i18next'

const ManageUsersFilterOverlay = ({
    filterOverlayService,
}: {
    filterOverlayService: IUseOverlay
}) => {
    const { t } = useTranslation()
    return (
        <Overlay overlayService={filterOverlayService} className="filter flex">
            <div className={`w-full`}>
                <div className="flex font-bold text-size-L justify-between items-center px-5 py-3">
                    {t('action.filters')}
                    <Button
                        className="!w-4 !h-4 !border-0 mb-2"
                        icon="ri-close-fill"
                        onClick={() => {
                            filterOverlayService.closeOverlayHandling()
                        }}
                    />
                </div>
                <div className="border-b border-gray-40"></div>
                <ManageUsersFilter />
                <div className="border-b border-gray-40 mt-2"></div>
                <div className="w-full flex gap-4 px-5 py-3">
                    <Button
                        onClick={async () => {}}
                        useUpperCase={true}
                        label={t('action.reset')}
                        className="w-1/4"
                        variant="default"
                    />
                    <Button
                        onClick={() => {}}
                        useUpperCase={true}
                        label={t('action.apply', { value: t('action.filter') })}
                        className="w-3/4"
                        variant="brand"
                    />
                </div>
            </div>
        </Overlay>
    )
}

export default ManageUsersFilterOverlay
