var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './progress-bar.style.css';
var ProgressBar = function (_a) {
    var total = _a.total, current = _a.current, _b = _a.returned, returned = _b === void 0 ? 0 : _b, variant = _a.variant, icon = _a.icon;
    var completedProgress = (current / total) * 100;
    var remaining = total - current;
    var isFull = completedProgress >= 100;
    return (_jsxs("div", __assign({ className: "progress-bar progress-bar-".concat(variant, " ") }, { children: [_jsx("div", __assign({ className: "w-[60px] flex flex-row-reverse mr-2" }, { children: variant === 'outline-blue' ? "" : "".concat(current) })), _jsx("div", __assign({ className: "rounded flex-1 bg-bar min-w-[120px] max-w-[120px]  -mt-1" }, { children: _jsxs("div", __assign({ className: " h-5 rounded overflow-hidden border-bar" }, { children: [_jsx("div", __assign({ className: "bg-progress  top-0 left-0  h-full transition-width duration-500 ease-in-out", style: {
                                width: "".concat(completedProgress, "%"),
                            } }, { children: isFull && (_jsx("div", __assign({ className: "flex w-full h-full items-center justify-center z-10" }, { children: _jsx("i", { className: "".concat(icon) }) }))) })), variant === 'outline-green' && (
                        /* Returned Progress Bar */
                        _jsx("div", { className: " top-0 left-0  h-full transition-width duration-500 ease-in-out themes-bg-red-v4", style: {
                                width: "".concat(returned, "%"),
                                marginLeft: "".concat(completedProgress, "%"),
                            } }))] })) })), _jsxs("div", __assign({ className: "ml-2 w-[60px]" }, { children: [' ', variant === 'outline-blue'
                        ? "".concat(current, "/").concat(total)
                        : variant === 'outline-green'
                            ? "".concat(returned)
                            : "".concat(remaining)] }))] })));
};
export default ProgressBar;
