/* eslint-disable no-unused-vars */
import Modal from '@components//modal/modal.component'
import { IPODetail } from '../purchase-order-detail.interface'
import { IUseModal } from '@components//modal/modal.service'
import Button from '@components/button/button.component'

const DetachReattachConfirmationModal = ({
    modalService,
    data,
    isReattach,
    onSubmit,
}: {
    data: IPODetail
    isReattach: boolean
    modalService: IUseModal
    onSubmit: () => void
}) => {
    console.log(isReattach)
    const poNo = data.poNo
    const label = !isReattach
        ? 'DETACH ALL SHIPMENTS OF PO NO ' + poNo
        : 'REATTACH ALL SHIPMENTS OF PO NO ' + poNo
    const btnLabel = !isReattach ? 'YES, DETACH' : 'YES, REATTACH'
    const desc = !isReattach
        ? `This will remove all shipments from PO ${poNo}. The PO status will change to "Open." Are you sure you want to proceed?`
        : `This action will restore all attached shipments from PO 1446019574 to their previous state. The PO status will change to 'In Progress'.
        Are you sure you want to proceed?`

    return (
        //for JPL
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="lg:w-1/3 md:w-1/2 mobile:w-1/2"
        >
            <div className="flex flex-col gap-4 justify-content">
                <div className="font-bold text-size-L themes-text-gray-v7">
                    {label}
                </div>
                <div className="border-b themes-border-color"></div>
                <div className="whitespace-pre-wrap">{desc}</div>
                <div className="border-b themes-border-color"></div>
                <div className="flex justify-content gap-4">
                    <div className="w-full">
                        <Button
                            label="CANCEL"
                            variant="default"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                            className="w-full"
                        />
                    </div>
                    <div className="w-full">
                        <Button
                            label={btnLabel}
                            variant="brand"
                            onClick={() => {
                                modalService.closeModalHandling()
                                onSubmit()
                            }}
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DetachReattachConfirmationModal
