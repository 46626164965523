import { IResponseData } from 'common/common.interface'
import {
    IShipment,
    IShipmentDetails,
    IShipmentFilter,
} from './interface/shipment.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import { Toast } from '@components/toast/toast.component'

export const getShipmentsData = async (filter: IShipmentFilter) => {
    try {
        const response = await fetch<
            IResponseData<IShipment[]>,
            IShipmentFilter
        >({
            endpoint: ifbEndpoints.shipment_getAll,
            params: filter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipments data',
                message: `Failed to fetch Shipments data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Shipments data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Shipments data',
                message: `Unknown error occurred while fetching Shipments data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IShipment[]>
        err.isSuccess = false
        return err
    }
}

export const getShipmentDetails = async (shipmentId: string) => {
    try {
        const response = await fetch<IResponseData<IShipmentDetails>, string>({
            endpoint: `${ifbEndpoints.shipment_getAll}/${shipmentId}`,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipment Details',
                message: `Failed to fetch Shipment Details data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Shipments Details',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Shipments Details',
                message: `Unknown error occurred while fetching Shipments Details."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IShipmentDetails>
        err.isSuccess = false
        return err
    }
}
