var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Accordion from '@components/accordion/accordion.component';
import Button from '@components/button/button.component';
import List from '@components/list/list.component';
import Modal from '@components/modal/modal.component';
import useConfigureColumnsModalService from './configure-columns-modal.service';
import { useTranslation } from 'react-i18next';
var ConfigureColumnsModal = function (_a) {
    var modalService = _a.modalService, columns = _a.columns, setHeaders = _a.setHeaders;
    var t = useTranslation().t;
    var _b = useConfigureColumnsModalService(columns, setHeaders), selectedColumns = _b.selectedColumns, availableColumns = _b.availableColumns, configureColumns = _b.configureColumns, setConfigureColumns = _b.setConfigureColumns, handleSearchAvailableColumns = _b.handleSearchAvailableColumns, handleSearchSelectedColumns = _b.handleSearchSelectedColumns, moveItem = _b.moveItem, moveItemUp = _b.moveItemUp, moveItemDown = _b.moveItemDown, loadHeaders = _b.loadHeaders;
    var configureColumnsAccordion = (_jsx(Accordion, { header: t('additional.available_column'), items: availableColumns, onSearchSubmit: handleSearchAvailableColumns }));
    var configureColumnsList = (_jsx(List, { header: t('additional.selected_column'), items: selectedColumns, onSearchSubmit: handleSearchSelectedColumns, moveItem: moveItem, moveItemUp: moveItemUp, moveItemDown: moveItemDown, isDraggable: true }));
    return (_jsx(Modal, __assign({ isModalOpen: modalService.isModalOpen, className: "w-full md:max-w-[700px] lg:max-w-[700px] px-0" }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ className: "flex justify-between items-center px-3 pb-3" }, { children: [_jsx("div", { children: t('additional.configure_column') }), _jsx(Button, { onClick: function () { return modalService.closeModalHandling(); }, icon: "ri-close-fill", className: "border-none w-btnIconWidth" })] })), _jsx("div", { className: "border-b" }), _jsxs("div", __assign({ className: "p-3 overflow-auto flex  mobile:flex-wrap gap-3 h-[calc(100vh-15rem)]" }, { children: [_jsx("div", __assign({ className: "w-1/2 mobile:w-full" }, { children: configureColumnsAccordion })), _jsx("div", __assign({ className: "w-1/2 mobile:w-full" }, { children: configureColumnsList }))] })), _jsx("div", { className: "border-b" }), _jsxs("div", __assign({ className: "flex gap-3 px-3 mt-3" }, { children: [_jsx(Button, { useUpperCase: true, onClick: function () { return setConfigureColumns(configureColumns); }, label: t('action.reset'), className: "w-1/4" }), _jsx(Button, { useUpperCase: true, onClick: function () {
                                loadHeaders();
                                modalService.closeModalHandling();
                            }, label: t('action.apply', { value: '' }), variant: "brand", className: "w-3/4" })] }))] }) })));
};
export default ConfigureColumnsModal;
