import { IBookAgainValidation } from 'client/ifb/form-validation/book-again.validation'
import { FormikContextType, FormikProvider } from 'formik'

const Details = ({
    formik,
    getContentDetails,
}: {
    formik: FormikContextType<IBookAgainValidation>
    getContentDetails: JSX.Element
}) => {
    return (
        <div className="p-3 h-full">
            <FormikProvider value={formik}>{getContentDetails}</FormikProvider>
        </div>
    )
}

export default Details
