var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TableExpandableRow } from './row/table-expandable-row.component';
import { useTableExpandable } from './table-expandable.services';
import './table-expandable.style.css';
function TableExpandable(_a) {
    var _b = _a.notFoundMessage, notFoundMessage = _b === void 0 ? "No data found" : _b, props = __rest(_a, ["notFoundMessage"]);
    var _c = useTableExpandable(props), data = _c.data, header = _c.header, headerChild = _c.headerChild, childAccessor = _c.childAccessor;
    return (_jsx("div", __assign({ className: "table-expandable h-full w-full  flex flex-col overflow-auto" }, { children: _jsx("div", __assign({ className: "flex-1 ".concat(!data.length && 'flex') }, { children: _jsxs("table", __assign({ className: "w-full table-auto" }, { children: [_jsx("thead", __assign({ className: "thead-expandable" }, { children: _jsx("tr", { children: header.map(function (data, idx) {
                                var showLabel = data.showLabel === undefined
                                    ? true
                                    : data.showLabel;
                                var width = data.width
                                    ? data.width + 'px'
                                    : '';
                                var minWidth = data.minWidth
                                    ? data.minWidth + 'px'
                                    : '';
                                return (_jsx("th", __assign({ className: "th-expandable", style: {
                                        width: width,
                                        minWidth: minWidth,
                                    } }, { children: _jsx("div", __assign({ className: "".concat((data === null || data === void 0 ? void 0 : data.headerClassName) || '') }, { children: !showLabel ? null : data.label }), data.accessor) }), 'header-' + idx));
                            }) }) })), _jsxs("tbody", { children: [data.length < 1 ? (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 11.5 }, { children: _jsx("div", __assign({ className: "w-full h-full flex justify-center items-center" }, { children: notFoundMessage })) })) })) : (_jsx(_Fragment, {})), data.map(function (row, idx) {
                                return (_jsx(TableExpandableRow, { index: idx, data: row, headerParent: header, headerChild: headerChild, childAccessor: childAccessor, addChildRowHandling: function () {
                                        var _a;
                                        return props.addChildRowHandling &&
                                            props.addChildRowHandling((_a = row['id']) !== null && _a !== void 0 ? _a : '');
                                    }, highlightOnExpand: props.highlightOnExpand }, 'row-' + idx + (row === null || row === void 0 ? void 0 : row.id)));
                            })] })] })) })) })));
}
export default TableExpandable;
