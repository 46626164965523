import { IFinanceTableData, IUseControlTower } from '../control-tower.interface'
import SplitTable from '@components/split-table/split-table.component'
import {
    itemListShipmentsFinance,
    tabItemFilterFinance,
    tableFinanceHeaders,
} from '../control-tower.static'
import Tab from '@components/tab/tab.component'
import Pagination from '@components/pagination/pagination.component'
import { metaDummy } from '../../bookings/bookings.dummy'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import {
    DoughnutChart,
    JointLineScatterChart,
} from '@components/rechart-component'
import ColorPieChart from '../components/color-pie-chart.component'

const Finance = ({ service }: { service: IUseControlTower }) => {
    return (
        <>
            <div
                className="col-span-4 border-r flex flex-col"
                style={{ height: 'inherit' }}
            >
                <div className="p-2 h-1/2 flex flex-col">
                    <div className="flex justify-between">
                        <div className="font-bold text-size-S">
                            Avg. Monthly Performance
                        </div>
                        <ItemListInfo itemListInfo={itemListShipmentsFinance} />
                    </div>
                    <InfoCardGroup
                        items={[
                            {
                                value: 'AUD 125,517.00',
                                label: 'Overall Average Revenue',
                            },
                        ]}
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <div className="flex-grow">
                        <JointLineScatterChart
                            data={service.averageMonthlyPerformance.chartData}
                            tickItems={
                                service.averageMonthlyPerformance.tickItems
                            }
                            isLoading={
                                service.loadingFinance.averageMonthlyPerformance
                            }
                        />
                    </div>
                </div>
                <div className="border-b"></div>
                <div className="px-2 h-1/2">
                    <div className="font-bold text-size-S">Open Invoices</div>
                    <InfoCardGroup
                        items={[
                            {
                                value: '2,203',
                                label: 'Total open invoices',
                            },
                            {
                                value: '2,145',
                                label: 'Total open amount',
                            },
                        ]}
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <div className="flex-grow">
                        <DoughnutChart
                            data={service.financePieChart}
                            colorChart={
                                <ColorPieChart
                                    items={service.financePieChart}
                                    content={service.buttonContent}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="col-span-4 border-r" style={{ height: 'inherit' }}>
                <div className="flex justify-between items-center px-2 pt-2 pb-[3px]">
                    <Tab
                        items={tabItemFilterFinance}
                        tabFilter={service.tabFilterFinance}
                        onChange={(item) => {
                            service.setTabFilterFinance(item)
                        }}
                        containerClassName="!overflow-y-hidden !text-size-XS !p-0 !border-none"
                    />
                    <Pagination
                        containerClassName="!p-0 flex"
                        className="!h-[27px]"
                        btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                        meta={metaDummy}
                        previousHandling={(page) => {}}
                        nextHandling={(page) => {}}
                    />
                </div>

                <div className="h-[calc(100vh-17rem)] border-t">
                    <SplitTable<IFinanceTableData>
                        columns={tableFinanceHeaders}
                        data={service.financeTableData}
                        thClass="text-size-XS"
                        tdClass="text-size-XS"
                        onRowClick={service.loadHistoryData}
                    />
                </div>
            </div>
        </>
    )
}

export default Finance
