var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@components/button/button.component';
import './simple-table.style.css';
import SpinnerTable from '@components/spinner-table/spinner-table.component';
import EmptyResult from '@components/empty-result/empty-result.component';
import { useState } from 'react';
import Pagination from '@components/pagination/pagination.component';
import { useTranslation } from 'react-i18next';
function SimpleTable(_a) {
    var headers = _a.headers, data = _a.data, _b = _a.customBuildParam2, customBuildParam2 = _b === void 0 ? function () { return _jsx(_Fragment, {}); } : _b, _c = _a.isAddRow, isAddRow = _c === void 0 ? false : _c, _d = _a.useNumber, useNumber = _d === void 0 ? false : _d, _e = _a.isBorder, isBorder = _e === void 0 ? true : _e, _f = _a.variant, variant = _f === void 0 ? 'white' : _f, _g = _a.headerCostum, headerCostum = _g === void 0 ? 'themes-bg-white' : _g, _h = _a.customBuildParam4, customBuildParam4 = _h === void 0 ? function () { return ({
        func1: function () { },
    }); } : _h, 
    // property class
    _j = _a.thClass, 
    // property class
    thClass = _j === void 0 ? '' : _j, _k = _a.tdClass, tdClass = _k === void 0 ? '' : _k, props = __rest(_a, ["headers", "data", "customBuildParam2", "isAddRow", "useNumber", "isBorder", "variant", "headerCostum", "customBuildParam4", "thClass", "tdClass"]);
    var t = useTranslation().t;
    var _l = useState(0), active = _l[0], setActive = _l[1];
    var cursorClass = props.onRowClick ? 'cursor-pointer' : '';
    var loadingMessage = "Loading Data . . .";
    var notFoundMessage = "No data found";
    var headerLength = useNumber ? headers.length : (headers === null || headers === void 0 ? void 0 : headers.length) - 1;
    var useTitleClass = props.title ? '' : 'rounded-t';
    var useFooterClass = props.footer ? '' : 'rounded-b';
    return (_jsxs(_Fragment, { children: [props.title && (_jsx("div", __assign({ className: "themes-bg-white border-t rounded-t border-x p-3" }, { children: props.title }))), _jsx("div", __assign({ className: "simple-table-container ".concat(isBorder && 'border', " h-full overflow-auto justify-between themes-text-gray-v5 variant-").concat(variant, " ").concat(useTitleClass, " ").concat(useFooterClass) }, { children: props.loading === true ? (_jsx("div", __assign({ className: "h-full" }, { children: _jsx(SpinnerTable, { className: "w-full", message: loadingMessage }) }))) : data.length <= 0 && !isAddRow ? (_jsx("div", __assign({ className: "h-full" }, { children: _jsx(EmptyResult, { className: "w-full", message: notFoundMessage }) }))) : (_jsxs("table", __assign({ className: "simple-table table-auto" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [!useNumber ? _jsx(_Fragment, {}) : _jsx("th", { children: "No." }), headers.map(function (column, idx) {
                                        var label = column.labelTranslation
                                            ? t(column.labelTranslation)
                                            : column.label;
                                        var header = column.showLabel === false ? (_jsx(_Fragment, {})) : column.customHeader ? (column.customHeader()) : (label);
                                        return (_jsx("th", __assign({ className: "".concat(headerCostum, " ").concat(thClass, " z-[1] ") }, { children: _jsx("div", __assign({ className: "row-container" }, { children: header })) }), idx));
                                    })] }) }), _jsxs("tbody", __assign({ className: "overflow-auto", id: "table-body" }, { children: [data.map(function (row, idx2) {
                                    var activeClass = props.onRowClick && active === idx2
                                        ? 'active'
                                        : '';
                                    return (_jsxs("tr", __assign({ className: "".concat(cursorClass), onClick: function () {
                                            if (props.onRowClick) {
                                                props.onRowClick(row);
                                                setActive(idx2);
                                            }
                                        } }, { children: [!useNumber ? (_jsx(_Fragment, {})) : (_jsx("td", { children: idx2 + 1 })), headers.map(function (column, idx3) {
                                                return (_jsx("td", __assign({ className: "".concat(activeClass, " ").concat(tdClass) }, { children: _jsx("div", __assign({ className: "row-container w-max ".concat((column === null || column === void 0 ? void 0 : column.isComponent) ? '' : 'max-w-[200px] overflow-hidden text-ellipsis', " flex items-center") }, { children: column.customBuild
                                                            ? column.customBuild(row[column
                                                                .accessor], customBuildParam2, idx2, customBuildParam4, row)
                                                            : row[column
                                                                .accessor] || '-' })) }), "".concat(idx3, "-").concat(column.accessor)));
                                            })] }), idx2));
                                }), isAddRow && (_jsxs("tr", { children: [_jsx("td", __assign({ colSpan: headerLength, className: "p-0 " }, { children: props.addRowLabel
                                                ? props.addRowLabel
                                                : 'Add new row' })), _jsx("td", __assign({ className: "pr-4 w-[65px]" }, { children: _jsx("div", __assign({ className: "w-full flex justify-center" }, { children: _jsx(Button, { onClick: function () {
                                                        (props === null || props === void 0 ? void 0 : props.addRowClick) &&
                                                            props.addRowClick();
                                                    }, icon: "ri-add-fill", className: "!p-0 !w-[55px] !h-[1.8rem]", variant: "brand-inverse" }) })) }))] }))] }))] }))) })), props.footer && (_jsx("div", __assign({ className: "themes-bg-white p-3 border-x border-b rounded-b" }, { children: props.footer }))), props.usePagination && (_jsxs("div", __assign({ className: "border-t themes-border-color flex justify-between" }, { children: [_jsx(Pagination, { meta: props.meta, previousHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.previousHandling) &&
                                props.previousHandling(page);
                        }, nextHandling: function (page) {
                            (props === null || props === void 0 ? void 0 : props.nextHandling) && props.nextHandling(page);
                        } }), _jsx("div", __assign({ className: "m-4 flex gap-2" }, { children: props === null || props === void 0 ? void 0 : props.additionalButtonBottom }))] })))] }));
}
export default SimpleTable;
