/* eslint-disable @typescript-eslint/no-unused-vars */
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { useTranslation } from 'react-i18next'
import useCreateBooking from '../create-booking/create-booking.service'
import { ICreateBookingValidation } from 'client/ifb/form-validation/booking-details.validation'
import { FormikContextType } from 'formik'
import { useSelector } from 'react-redux'
import { bookingsDataDropdownSelector } from '../bookings.slice'
import {
    ILoadOptionPromise,
    ILoadOptionsParams,
} from '../create-booking/create-booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

const DestinationForm = ({
    formik,
    loadOptions,
}: {
    formik?: FormikContextType<ICreateBookingValidation>
    loadOptions?: (args_0: ILoadOptionsParams) => void
}) => {
    const { t } = useTranslation()
    const dropdownData = useSelector(bookingsDataDropdownSelector)
    return (
        <div>
            <div>{t('bookings.destination')}</div>
            <div className="flex flex-col gap-3 mt-3">
                <FormDropdown
                    isSearchable
                    label={t('bookings.destination_port')}
                    name={`destinationPortCode`}
                    placeholder={t('action.enter', {
                        value: t('bookings.destination_port'),
                    })}
                    parentDivClassName="!mb-0"
                    options={dropdownData.destinations}
                    isAsync={true}
                    loadOptions={(e: string) =>
                        loadOptions && loadOptions({ destinationItem: e })
                    }
                    defaultValue={dropdownData.destinations}
                    additionalOnClick={(data) => {
                        if (!formik) return
                        const _data = data as IDropdownItem
                        const label = _data?.label as string
                        const value = label?.split('-')[1].trim()
                        formik?.setFieldValue(`destinationPortName`, value)
                    }}
                    isClearable
                />
                <FormDropdown
                    isSearchable
                    label={'Delivery Equipment Needed'}
                    name={`deliveryEquipmentNeededCode`}
                    placeholder={t('action.enter', {
                        value: 'Delivery Equipment Needed',
                    })}
                    parentDivClassName="!mb-0"
                    options={dropdownData.deliveryEquipmentNeeded}
                    additionalOnClick={(data) => {
                        if (!formik) return
                        const _data = data as IDropdownItem
                        const label = _data?.label as string
                        const value = label?.split('-')[1].trim()
                        formik?.setFieldValue(
                            `deliveryEquipmentNeededDescription`,
                            value,
                        )
                    }}
                    isClearable
                />
                <div className="relative">
                    <FormDatePicker
                        name={'estimatedDelivery'}
                        isRange={false}
                        label="Estimated Delivery"
                        placeholder="Pickup Required By"
                    />
                </div>
                <div className="relative">
                    <FormDatePicker
                        name={'deliveryRequiredBy'}
                        isRange={false}
                        label="Delivery Required By"
                        placeholder="Delivery Required By"
                    />
                </div>
            </div>
        </div>
    )
}

export default DestinationForm
