import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { IUseCreateBooking } from '../create-booking.interface'
import { useTranslation } from 'react-i18next'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import { IOption } from '@components/measurement-input/measurement-input.interface'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import OriginForm from '../../components/origin-form.component'
import DestinationForm from '../../components/destination-form.component'
import { ICreateBookingValidation } from 'client/ifb/form-validation/booking-details.validation'

const Details = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const { t } = useTranslation()
    return (
        <div>
            <div className={`grid grid-cols-4 gap-3 m-3 h-full`}>
                {/* Shipment Details */}
                <div className="flex flex-col gap-3">
                    <div>Shipment Details</div>
                    <FormInput
                        label={'Container Mode'}
                        name={`containerMode`}
                        placeholder={t('action.enter', {
                            value: 'Container Mode',
                        })}
                        disabled
                        parentDivClassName="!mb-0 "
                    />
                    <FormDropdown
                        isSearchable
                        label={'Transport Mode'}
                        name={`transportModeCode`}
                        placeholder={t('action.select', {
                            value: 'Transport Mode',
                        })}
                        parentDivClassName="!mb-0"
                        options={CBService.sortDataDropdown()}
                        isClearable
                    />
                    {/* hide for new feature */}
                    {/* <FormInput
                        label={t('bookings.shippers_ref')}
                        name={`loglinesBookingCreateBookingDetails.shippersRef`}
                        placeholder={t('action.enter', {
                            value: 'Shipper's Ref',
                        })}
                        parentDivClassName="!mb-0"
                    /> */}
                    <FormDropdown
                        isSearchable
                        label={t('bookings.service_level')}
                        name={`loglinesBookingCreateBookingDetails.serviceLevelCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.service_level'),
                        })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.serviceLevel}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.serviceLevelDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                    />
                    {CBService.modeTipe !== 'fcl' && (
                        <>
                            <FormInput
                                label={t('bookings.packs')}
                                name={`loglinesBookingCreateBookingDetails.packs`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.packs'),
                                })}
                                type="number"
                                parentDivClassName="!mb-0"
                            />
                            <FormDropdown
                                isSearchable
                                label={t('bookings.outer_pack_unit')}
                                name={`loglinesBookingCreateBookingDetails.outerPacksUnitCode`}
                                placeholder={t('action.select', {
                                    value: t('bookings.outer_pack_unit'),
                                })}
                                parentDivClassName="!mb-0"
                                options={CBService.dropdownData.packsUnit}
                                isClearable
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .weightMeasure as IOption[]
                                }
                                name={'specialField.weightMeasure'}
                                label={t('bookings.weight_measure')}
                                parentDivClassName=""
                                placeholder={t('action.enter', {
                                    value: t('bookings.weight_measure'),
                                })}
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .volumeMeasure as IOption[]
                                }
                                name={'specialField.volumeMeasure'}
                                label={t('bookings.volume_measure')}
                                parentDivClassName=""
                                placeholder={t('action.enter', {
                                    value: t('bookings.volume_measure'),
                                })}
                            />
                        </>
                    )}
                </div>

                {/* orgin & destination */}
                <div className="flex flex-col gap-3 w-full">
                    <OriginForm
                        formik={CBService.formikCreateBooking}
                        loadOptions={CBService.loadOptions}
                    />
                    <DestinationForm
                        formik={CBService.formikCreateBooking}
                        loadOptions={CBService.loadOptions}
                    />
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3">
                    <div>Charges</div>
                    <FormDropdown
                        isSearchable
                        label={t('bookings.incoterm')}
                        name={`loglinesBookingCreateBookingDetails.incoTermCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.incoterm'),
                        })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.incOterm}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.incoTermDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                    />
                    <FormInput
                        label={t('bookings.additional_terms')}
                        name={`loglinesBookingCreateBookingDetails.additionalTerms`}
                        placeholder={t('action.enter', {
                            value: t('bookings.additional_terms'),
                        })}
                        parentDivClassName="!mb-0 "
                    />
                    <FormInput
                        label={t('bookings.goods_value')}
                        name={`loglinesBookingCreateBookingDetails.goodsValue`}
                        placeholder={t('action.enter', {
                            value: t('bookings.goods_value'),
                        })}
                        type="number"
                        parentDivClassName="!mb-0"
                        step="0.01"
                    />
                    <FormDropdown
                        isSearchable
                        label={t('bookings.goods_value_currency')}
                        name={`loglinesBookingCreateBookingDetails.goodsValueCurrencyCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.goods_value_currency'),
                        })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.currency}
                        defaultValue={CBService.dropdownData.currency}
                        isAsync={true}
                        loadOptions={(e: string) =>
                            CBService.loadOptions({ currencyItem: e })
                        }
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.goodsValueCurrencyDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                    />
                    <FormInput
                        label={t('bookings.insurance_value')}
                        name={`loglinesBookingCreateBookingDetails.insuranceValue`}
                        placeholder={t('action.enter', {
                            value: t('bookings.insurance_value'),
                        })}
                        parentDivClassName="!mb-0"
                        type="number"
                    />
                    <FormDropdown
                        isSearchable
                        label={t('bookings.insurance_value_currency')}
                        name={`loglinesBookingCreateBookingDetails.insuranceValueCurrencyCode`}
                        placeholder={t('action.select', {
                            value: t('bookings.insurance_value_currency'),
                        })}
                        parentDivClassName="!mb-0"
                        isAsync={true}
                        loadOptions={(e: string) =>
                            CBService.loadOptions({ insuranceCurrencyItem: e })
                        }
                        options={CBService.dropdownData.insuranceCurrency}
                        defaultValue={CBService.dropdownData.insuranceCurrency}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.insuranceValueCurrencyDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                    />
                    <FormDropdown
                        isSearchable
                        label={'Charges Apply'}
                        name={`chargesApplyCode`}
                        placeholder={t('action.select', {
                            value: 'Charges Apply',
                        })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.chargesApply}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'chargesApplyDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                    />
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3">
                    <div>Notes</div>
                    <FormTextarea
                        name={
                            'loglinesBookingCreateBookingDetails.goodsDescription'
                        }
                        label={t('bookings.goods_description')}
                        parentDivClassName="h-[140px]"
                        placeholder={t('action.enter', {
                            value: t('bookings.goods_description'),
                        })}
                    />
                    <FormTextarea
                        name={
                            'loglinesBookingCreateBookingDetails.marksAndNumbers'
                        }
                        label={t('bookings.marks_and_numbers')}
                        parentDivClassName="h-[220px]"
                        placeholder={t('action.enter', {
                            value: t('bookings.marks_and_numbers'),
                        })}
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
