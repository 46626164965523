import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import {
    BarChartStacked,
    DoughnutChart,
    JointLineScatterChart,
} from '@components/rechart-component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import Pagination from '@components/pagination/pagination.component'
import {
    itemListDelayedShipments,
    itemListShipmentsPerformance,
    itemListTableCardPerfom,
    tabPerformanceItems,
} from '../performances.static'
import Tab from '@components/tab/tab.component'
import {
    IMilestonePerformance,
    IMilestonePerformanceItemAdditionalValue,
    IUsePerformances,
} from '../performances.interface'
import TableMilestoneShipment from '../components/table-milestone-shipment.component'
import { numberWithCommas } from 'common/common.service'

function Perfom({
    performancesService,
}: {
    performancesService: IUsePerformances
}) {
    return (
        <>
            <CardsDashboard className="!px-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[850px] xl:h-full lg:h-[600px] gap-1">
                <div className="h-1/2 px-3 border-b pb-3 flex flex-col xl:h-1/2 lg:h-[45%]">
                    <div className="font-bold text-size-S 2xl:text-size-M">
                        Shipments Performance Status
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.shipmentPerformanceStatus
                                .infoCardGroupItems
                        }
                        titleClass="!text-size-S"
                        descClass="!text-size-XS"
                    />
                    <div className="flex-grow mb-3">
                        <DoughnutChart
                            data={
                                performancesService.shipmentPerformanceStatus
                                    .chartData
                            }
                            isLoading={
                                performancesService.loading.shipmentStatus
                            }
                        />
                    </div>
                </div>
                <div className="h-1/2 px-2 flex flex-col xl:h-1/2 lg:h-[55%]">
                    <div className="flex justify-between items-center border-b flex-wrap">
                        <div className="font-bold flex-grow text-size-S 2xl:text-size-M">
                            Average Days Delayed
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[764px]
                            flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListDelayedShipments}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-wrap
                                "
                                itemClassNames="flex-grow mobile:!w-[110px]"
                            />
                        </div>
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.avarageDaysDelayed
                                .infoCardGroupItems
                        }
                        titleClass="!text-size-S"
                        descClass="!text-size-XS"
                    />
                    <div className="flex-grow">
                        <JointLineScatterChart
                            data={
                                performancesService.avarageDaysDelayed.chartData
                            }
                            tickItems={
                                performancesService.avarageDaysDelayed.tickItems
                            }
                            isLoading={
                                performancesService.loading.avarageDaysDelayed
                            }
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex gap-3  w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  lg:flex-row lg:h-[492px] md:flex-col md:h-[500px] sm:flex-col sm:h-[500px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold flex-grow text-size-S 2xl:text-size-M">
                            Shipments Performance by Ports
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[645px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListShipmentsPerformance}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex"
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                valueClass={{
                                    valueNumber: '!text-size-XS',
                                    valueString: '!text-size-XS',
                                }}
                                meta={
                                    performancesService
                                        .shipmentPerformanceByPorts.meta
                                }
                                previousHandling={(page) =>
                                    performancesService.setPageNumberPerformanceByPorts(
                                        page,
                                    )
                                }
                                nextHandling={(page) =>
                                    performancesService.setPageNumberPerformanceByPorts(
                                        page,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartStacked
                            data={
                                performancesService.shipmentPerformanceByPorts
                                    .chartData
                            }
                            isLoading={
                                performancesService.loading
                                    .shipmentPerformanceByPorts
                            }
                            renderTooltipValue={(data) => {
                                let loadContent = ''
                                const dataValue = data?.value
                                    ? numberWithCommas(data.value)
                                    : 0
                                switch (data?.name) {
                                    case 'f1':
                                        loadContent = `All Delayed Shipments:  ${dataValue}`
                                        break
                                    case 'f2':
                                        loadContent = `In Time Shipments:  ${dataValue}`
                                        break
                                }
                                return loadContent
                            }}
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex w-full col-span-12 row-span-2 lg:h-[492px] xl:col-span-8 xl:row-span-1 xl:h-full lg:flex-row md:flex-col md:h-[500px] sm:flex-col sm:h-[722px]">
                <div className="flex flex-col w-full h-full">
                    <div className="flex justify-end items-center flex-wrap">
                        <Tab
                            containerClassName="!overflow-y-hidden !text-size-XS flex flex-grow items-center pt-3 pl-3 "
                            items={tabPerformanceItems}
                            tabFilter={performancesService.tabFilterPerformance}
                            onChange={(item) => {
                                performancesService.setTabFilterPerformance(
                                    item,
                                )
                                // reset pagination
                                performancesService.setPageNumberMilestoneGroupDayRangeTable(
                                    1,
                                )
                            }}
                        />

                        <div
                            className="flex gap-1 flex-grow pl-2 justify-end 
                            mobile:flex-wrap
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListTableCardPerfom}
                                containerClassNames="themes-bg-white
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex"
                                className="!h-[27px] !text-size-XS"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={
                                    performancesService
                                        .milestoneGroupDayRangeTable.meta
                                }
                                valueClass={{
                                    valueNumber: '!text-size-XS',
                                    valueString: '!text-size-XS',
                                }}
                                previousHandling={(page) =>
                                    performancesService.setPageNumberMilestoneGroupDayRangeTable(
                                        page,
                                    )
                                }
                                nextHandling={(page) =>
                                    performancesService.setPageNumberMilestoneGroupDayRangeTable(
                                        page,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="border-b"></div>
                    <TableMilestoneShipment<
                        IMilestonePerformance,
                        IMilestonePerformanceItemAdditionalValue
                    >
                        data={
                            performancesService.milestoneGroupDayRangeTable
                                .tableData
                        }
                        isLoading={
                            performancesService.loading
                                .milestoneGroupDayRangeTable
                        }
                        pageContent={performancesService.tabFilter}
                    />
                </div>
            </CardsDashboard>
        </>
    )
}

export default Perfom
