var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './progress-multiple-bars.style.css';
var ProgressMultipleBars = function (_a) {
    var containerClassNames = _a.containerClassNames, _b = _a.useTooltip, useTooltip = _b === void 0 ? true : _b, data = _a.data, keyItems = _a.keyItems, props = __rest(_a, ["containerClassNames", "useTooltip", "data", "keyItems"]);
    return (_jsx("div", __assign({ className: "progress-multiple-bars ".concat(containerClassNames), style: props === null || props === void 0 ? void 0 : props.style }, { children: keyItems === null || keyItems === void 0 ? void 0 : keyItems.map(function (item, idx) {
            var total = (data === null || data === void 0 ? void 0 : data.total) || 0;
            var numericKeyValue = data.items.value[item.key]
                ? data.items.value[item.key]
                : 0;
            var widthValue = (numericKeyValue / total) * 100;
            var width = widthValue % 1 === 0
                ? "".concat(widthValue, "%")
                : "".concat(widthValue.toFixed(1), "%");
            var renderValue = item.rendervalue &&
                item.rendervalue({
                    value: data.items.value,
                    additionalValue: data === null || data === void 0 ? void 0 : data.items.additionalValue,
                });
            if (numericKeyValue === 0)
                return _jsx(_Fragment, {});
            return (_jsxs("div", __assign({ className: "".concat(item.bgColor, " bar-item-container"), style: {
                    width: width,
                    backgroundColor: item.bgColor,
                } }, { children: [useTooltip && (_jsx("div", __assign({ className: "bar-tooltip" }, { children: item.renderTooltip
                            ? item.renderTooltip({
                                value: data.items.value,
                                additionalValue: data.items.additionalValue,
                            })
                            : "Value: ".concat(numericKeyValue) }))), renderValue && (_jsx("div", __assign({ className: "bar-value ".concat(item.textColor), style: {
                            color: item.textColor,
                        } }, { children: renderValue })))] }), idx));
        }) })));
};
export default ProgressMultipleBars;
