import Dropdown from '@components/dropdown/dropdown.component'
import Toggle from '@components/toggle/toggle.component'
import { languageDropdown } from './settings.static'
import { useThemes } from './settings.services'
import { useTranslation } from 'react-i18next'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

export default function Settings() {
    const { t } = useTranslation()
    const { themes, timezonesList, selectedThemes, themesChangesHandling } =
        useThemes()
    const { i18n } = useTranslation()
    const currentLanguage = i18n.language
    const selectedLanguageItem =
        languageDropdown.find((d) => d.value === currentLanguage) ??
        languageDropdown[0]
    const borderComponent = <div className={`border-b`}></div>

    return (
        <div className="flex flex-col rounded-lg shadow-md themes-bg-white container-global content-full-height">
            <div className="overflow-auto">
                {/* Visual Preferences */}
                <div className="p-4">{t('settings.header1')}</div>
                {borderComponent}
                <div className="flex flex-col gap-y-2 py-4">
                    <div className="flex w-[70%] gap-x-4 px-4">
                        <div className="w-[35%]">
                            <Toggle
                                lableEnable={t('additional.light')}
                                lableDisable={t('additional.dark')}
                            />
                        </div>
                        <div className="w-full">
                            <div className="text-size-S">
                                {t('settings.title1')}
                            </div>
                            <div className="text-size-XS leading-none">
                                {t('settings.subTitle1')}
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex w-[70%] gap-x-4 px-4">
                        <div className="w-[35%]">
                            <Dropdown
                                options={typographySize}
                                defaultValue={[typographySize[1]]}
                            />
                        </div>
                        <div className="w-full">
                            <div className="text-size-S">Typography Size</div>
                            <div className="text-size-XS leading-none">
                                Adjust your prefered timezones, this will modify
                                all of the time and dates in the portal.
                            </div>
                        </div>
                    </div> */}
                    <div className="flex w-[70%] gap-x-4 px-4">
                        <div className="w-[35%]">
                            <Dropdown
                                options={themes}
                                placeholder="Select Available Reports"
                                value={selectedThemes}
                                onClick={(val) => {
                                    if (!val) return
                                    const _value = val as IDropdownItem
                                    themesChangesHandling(_value)
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <div className="text-size-S">
                                {' '}
                                {t('settings.title2')}s
                            </div>
                            <div className="text-size-XS leading-none">
                                {t('settings.subTitle2')}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Portal Preferences */}
                <div className="p-4">{t('settings.header2')}</div>
                {borderComponent}
                <div className="flex flex-col gap-y-2 pt-4">
                    <div className="flex w-[70%] gap-x-4 px-4">
                        <div className="w-[35%]">
                            <Dropdown
                                options={languageDropdown}
                                defaultValue={[selectedLanguageItem]}
                                onClick={(val) => {
                                    const _value = val as IDropdownItem
                                    i18n.changeLanguage(_value?.value as string)
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <div className="text-size-S">
                                {t('settings.title3')}
                            </div>
                            <div className="text-size-XS leading-none">
                                {t('settings.subTitle3')}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-[70%] gap-x-4 px-4">
                        <div className="w-[35%]">
                            <Dropdown
                                options={timezonesList}
                                defaultValue={[timezonesList[0]]}
                            />
                        </div>
                        <div className="w-full">
                            <div className="text-size-S">
                                {' '}
                                {t('settings.title4')}
                            </div>
                            <div className="text-size-XS leading-none">
                                {t('settings.subTitle4')}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-[70%] gap-x-4 px-4">
                        <div className="w-[35%]">
                            <Toggle
                                lableEnable={t('additional.enable')}
                                lableDisable={t('additional.disable')}
                            />
                        </div>
                        <div className="w-full">
                            <div className="text-size-S">
                                {t('settings.title5')}
                            </div>
                            <div className="text-size-XS leading-none">
                                {t('settings.subTitle5')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
