var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from 'react';
var DonutLoading = function () {
    var _a = useState(0), diameter = _a[0], setDiameter = _a[1];
    var ref = useRef(null);
    useLayoutEffect(function () {
        var handleResize = function () {
            if (ref.current) {
                var diameter_1 = Math.min(ref.current.offsetWidth * 0.4, ref.current.offsetHeight);
                setDiameter(diameter_1);
            }
        };
        // Initial dimension set
        handleResize();
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "flex items-center space-x-8 p- w-full animate-pulse ", ref: ref }, { children: [_jsxs("div", __assign({ className: "relative aspect-square", style: {
                    width: diameter,
                    height: diameter,
                } }, { children: [_jsx("div", { className: "absolute inset-0 bg-purple-200 rounded-full" }), _jsx("div", { className: "absolute inset-4 bg-white rounded-full" })] })), _jsx("div", __assign({ className: "space-y-4 flex-grow" }, { children: Array.from({ length: 4 }).map(function (_, index) { return (_jsxs("div", __assign({ className: "flex items-center space-x-2" }, { children: [_jsx("div", { className: "bg-gray-300 rounded w-8 h-4" }), _jsxs("div", __assign({ className: "flex flex-col space-y-1" }, { children: [_jsx("div", { className: "bg-gray-300 rounded w-28 h-4" }), _jsx("div", { className: "bg-gray-300 rounded w-16 h-3" })] }))] }), index)); }) }))] })));
};
export default DonutLoading;
