var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var BarLoading = function () {
    return (_jsxs("div", __assign({ className: "py-3 animate-pulse space-y- flex flex-col h-full w-full justify-between" }, { children: [_jsxs("div", __assign({ className: "flex justify-between mb-4" }, { children: [_jsx("div", { className: "bg-gray-300 rounded w-1/4 h-6" }), _jsx("div", { className: "bg-gray-300 rounded w-1/4 h-6" })] })), _jsx("div", __assign({ className: "relative flex justify-between items-end space-x-4 w-full overflow-hidden" }, { children: Array.from({ length: 5 }).map(function (_, index) { return (_jsxs("div", __assign({ className: "flex flex-col items-center space-y-2" }, { children: [_jsx("div", { className: "bg-purple-300 rounded w-1", style: { height: "".concat(Math.random() * 100, "%") } }), _jsx("div", { className: "bg-gray-300 rounded w-12 h-[20px]" })] }), index)); }) }))] })));
};
export default BarLoading;
