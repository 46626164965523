import Modal from '@components//modal/modal.component'
import { IUseModal } from '@components//modal/modal.service'
import Button from '@components/button/button.component'
import { IUserDetail } from 'client/common/repository/interface/user.interface'
import { IManageUser } from 'client/ifb/pages/manage-users/manage-users.interface'

const SuspendUserModal = ({
    isActive,
    modalService,
    data,
    onSubmit,
}: {
    isActive: boolean
    data: IUserDetail | IManageUser | undefined
    modalService: IUseModal
    onSubmit: () => void
}) => {
    const label = !isActive ? 'Unsuspend User' : 'Suspend User'
    const subs = !isActive ? 'unsuspend' : 'suspend'
    return (
        //for JPL
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3">
            <div className="flex flex-col gap-4 justify-content">
                <div className="font-bold text-size-L">{label}</div>
                <div className="border-b border-gray-40"></div>
                <div className="themes-text-gray-v3">
                    Are you sure want to {subs} user `{data?.fullName ?? '-'}` ?
                </div>
                <div className="border-b border-gray-40"></div>
                <div className="flex justify-content ">
                    <div className="flex-grow">
                        <Button
                            label="BACK"
                            variant="default"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            label="YES"
                            variant="brand"
                            onClick={() => {
                                modalService.closeModalHandling()
                                onSubmit()
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SuspendUserModal
