import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useModal } from '@components//modal/modal.service'
import { useDispatch, useSelector } from 'react-redux'
import {
    getManageUserID,
    getManageUserIDLog,
} from 'client/ifb/repository/manage-user.repository'
import {
    manageUserDataLogSelector,
    manageUserDetailSelector,
    setManageUserDataDetail,
    setManageUserDataLog,
} from '../manage-users.slice'
import { Toast } from '@components/toast/toast.component'
import { changeUserStatus } from 'client/common/repository/user.repository'

const useManageUserDetails = () => {
    // initial
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // state
    const manageUserDataLog = useSelector(manageUserDataLogSelector)
    const manageUserDetail = useSelector(manageUserDetailSelector)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    // modal service
    const modalConfirmationService = useModal()

    function openEmailClient() {
        var emailAddress = 'test@gmail.com' // Replace with the desired email address
        var subject = 'Subject'
        var body = '--- Body ---'

        var mailtoUrl =
            'mailto:' +
            encodeURIComponent(emailAddress) +
            '?subject=' +
            encodeURIComponent(subject) +
            '&body=' +
            encodeURIComponent(body)

        window.open(mailtoUrl)
    }

    useEffect(() => {
        loadData()
    }, [])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            const actionResultDetail = await getManageUserID({ id })
            const actionResult = await getManageUserIDLog({ id })
            dispatch(setManageUserDataDetail(actionResultDetail.data))
            dispatch(setManageUserDataLog(actionResult))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    async function changeStatus() {
        try {
            await changeUserStatus(
                manageUserDetail?.id ?? '',
                !manageUserDetail.isActive,
            )
            loadData()
            const message = manageUserDetail.isActive
                ? 'User has Successfully Suspended!'
                : 'User has Successfully Activated!'
            Toast({
                header: message,
                message: '',
                type: 'success',
            })
        } catch (error: any) {
            const errorMessage =
                error.title ?? error.message ?? 'Failed to suspend user'
            Toast({
                header: 'Failed!',
                message: errorMessage,
                type: 'error',
            })
        }
    }

    return {
        id,
        loading,
        errorMessage,
        manageUserDataLog,
        manageUserDetail,
        modalConfirmationService,
        navigate,
        openEmailClient,
        changeStatus,
    }
}

export default useManageUserDetails
