import {
    IDataCustomersTable,
    IDataOverviewTable1,
    IDataOverviewTable2,
    IDataTradeLanesTable,
    IFinanceTableData,
    IMarkShipmentCustomers,
} from './control-tower.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { IItemListInfo } from '@components/item-list-info/item-list-info.interface'
import { ISPTColumn } from '@components/split-table/split-table.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IProgressMultipleBarKeys } from '@components/progress-multiple-bars/progress-multiple-bars.interface'
import { numberToCurrency } from 'common/common.service'

// segment common
export const filterDateType: IDropdownItem[] = [
    {
        label: 'ETA',
        value: 'eta',
    },
    {
        label: 'ETD',
        value: 'etd',
    },
]

// segment overview
export const tabItemFilterOverview: ITabItem[] = [
    {
        label: 'Customer',
        key: 'status',
        value: 'Customer',
        className: 'brand',
    },
    {
        label: 'Branch',
        key: 'status',
        value: 'Branch',
        className: 'brand',
    },
    {
        label: 'Agents',
        key: 'status',
        value: 'Agents',
        className: 'brand',
    },
]

export const overviewHistoryDevelpmentInfo: IItemListInfo[] = [
    {
        label: 'This Period',
        color: 'gray-v5',
    },
    {
        label: 'Last Period',
        color: 'gray-v3',
    },
]

export const overviewHistorySplitRecord: IItemListInfo[] = [
    {
        label: 'Nomination',
        color: 'gray-v5',
    },
    {
        label: 'Local Control',
        color: 'gray-v3',
    },
]

export const tableOverviewHeaders1: ISPTColumn<IDataOverviewTable1>[] = [
    {
        header: [
            { accessor: 'item', label: 'Items' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '55%',
    },
    {
        header: [
            {
                accessor: 'change',
                label: 'Change',
                customBuild: ({ change }) => {
                    let classNames: string = ''
                    let valueConetnt: string = ''

                    if (change.amount > 0) {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                        valueConetnt = `+${numberToCurrency(change.amount)} ${change.unit ? change.unit : change?.currency || ''}`
                    } else if (change.amount === 0) {
                        classNames =
                            'themes-border-gray-v2 themes-bg-gray-v1 themes-text-gray-v9'
                        valueConetnt = `${numberToCurrency(change.amount)} ${change.unit ? change.unit : change?.currency || ''}`
                    } else {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                        valueConetnt = `${numberToCurrency(change.amount)} ${change.unit ? change.unit : change?.currency || ''}`
                    }
                    return (
                        <div
                            className={`${classNames}  rounded w-[145px] px-2 border`}
                        >
                            {valueConetnt}
                        </div>
                    )
                },
            },
        ],
        width: '45%',
    },
]

export const tableOverviewHeaders2: ISPTColumn<IDataOverviewTable2>[] = [
    {
        header: [
            { accessor: 'name', label: 'Name' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '55%',
    },
    {
        header: [
            {
                accessor: 'change',
                label: 'Change',
                customBuild: ({ change }) => {
                    var firstChar = change.slice(0, 1)

                    let classNames: string = ''

                    if (firstChar === '+') {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                    } else if (firstChar === '-') {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                    }

                    return (
                        <div
                            className={`${classNames}  rounded w-[145px] px-2 border`}
                        >
                            {change}
                        </div>
                    )
                },
            },
        ],
        width: '45%',
    },
]

// segment trade lanes
export const tabItemFilterTradeLanes: ITabItem[] = [
    {
        label: 'Countries',
        key: 'status',
        value: 'Countries',
        className: 'brand',
    },
    {
        label: 'Ports',
        key: 'status',
        value: 'Ports',
        className: 'brand',
    },
    {
        label: 'Lanes',
        key: 'status',
        value: 'Lanes',
        className: 'brand',
    },
]
export const tableTradeLanesHeaders: ISPTColumn<IDataTradeLanesTable>[] = [
    {
        header: [
            { accessor: 'company', label: 'Company' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '55%',
    },
    {
        header: [
            {
                accessor: 'change',
                label: 'Change',
                customBuild: ({ change }) => {
                    var firstChar = change.slice(0, 1)

                    let classNames: string = ''

                    if (firstChar === '+') {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                    } else if (firstChar === '-') {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                    }

                    return (
                        <div
                            className={`${classNames}  rounded w-[145px] px-2 border`}
                        >
                            {change}
                        </div>
                    )
                },
            },
        ],
        width: '45%',
    },
]

// segment customers
export const tabItemFilterCustomers: ITabItem[] = [
    {
        label: 'Organisations',
        key: 'status',
        value: 'Organisations',
        className: 'brand',
    },
    {
        label: 'Contact',
        key: 'status',
        value: 'Contact',
        className: 'brand',
    },
]
export const tableCustomersHeaders: ISPTColumn<IDataCustomersTable>[] = [
    {
        header: [
            {
                accessor: 'mark',
                label: 'Mark',
                customBuild: ({ mark }) => (
                    <div
                        className={`w-[20px] h-[20px] rounded`}
                        style={{ backgroundColor: mark }}
                    ></div>
                ),
            },
            { accessor: 'name', label: 'Name' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '35%',
    },
    {
        header: [
            {
                accessor: 'development',
                label: 'Development',
                customBuild: ({ development }) => {
                    var firstChar = development.slice(0, 1)

                    let classNames: string = ''

                    if (firstChar === '+') {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                    } else if (firstChar === '-') {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                    }

                    return (
                        <div
                            className={`${classNames}  rounded w-[145px] px-2 border`}
                        >
                            {development}
                        </div>
                    )
                },
            },
        ],
        width: '20%',
    },
    {
        header: [
            { accessor: 'rev', label: '% of Rev' },
            { accessor: 'gp', label: '% of GP' },
            { accessor: 'vol', label: '% of VOL' },
            { accessor: 'gpPerShipment', label: 'Avg. GP per Shipment' },
        ],
        width: '45%',
    },
]
export const progressMultipleBarKeyControlTower: IProgressMultipleBarKeys<
    IMarkShipmentCustomers,
    IMarkShipmentCustomers
>[] = [
    {
        key: 'blue',
        bgColor: '#5280C6',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.blue.toString() + ' %',
    },
    {
        key: 'aqua',
        bgColor: '#76C6CC',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.aqua.toString() + '%',
    },
    {
        key: 'purple',
        bgColor: '#9D75AD',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.purple.toString() + '%',
    },
    {
        key: 'orange',
        bgColor: '#C98167',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.orange.toString() + '%',
    },
    {
        key: 'yellow',
        bgColor: '#E3CD9A',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.yellow.toString() + '%',
    },
    {
        key: 'red',
        bgColor: '#C9677F',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.red.toString() + '%',
    },
    {
        key: 'gray',
        bgColor: '#D9DDE1',
        rendervalue: ({ additionalValue }) =>
            additionalValue?.gray.toString() + '%',
    },
]

// segment finance
export const tabItemFilterFinance: ITabItem[] = [
    {
        label: 'P&L',
        key: 'status',
        value: 'P&L',
        className: 'brand',
    },
    {
        label: 'Credit Limit',
        key: 'status',
        value: 'Credit Limit',
        className: 'brand',
    },
]
export const tableFinanceHeaders: ISPTColumn<IFinanceTableData>[] = [
    {
        header: [
            { accessor: 'name', label: 'Name' },
            { accessor: 'thisPeriod', label: 'This Period' },
            { accessor: 'lastPeriod', label: 'Last Period' },
        ],
        width: '55%',
    },
    {
        header: [
            {
                accessor: 'change',
                label: 'Change',
                customBuild: ({ change }) => {
                    var firstChar = change.slice(0, 1)

                    let classNames: string = ''

                    if (firstChar === '+') {
                        classNames =
                            'themes-border-green-v2 themes-bg-green-v1 themes-text-green-v4'
                    } else if (firstChar === '-') {
                        classNames =
                            'themes-border-red-v2 themes-bg-red-v1 themes-text-red-v4'
                    }

                    return (
                        <div
                            className={`${classNames}  rounded w-[145px] px-2 border`}
                        >
                            {change}
                        </div>
                    )
                },
            },
        ],
        width: '45%',
    },
]
export const itemListShipmentsFinance: IItemListInfo[] = [
    {
        label: 'Avg. GP',
        color: 'extra-blue-v4',
    },
    {
        label: 'Avg. OPEX',
        color: 'extra-red-v4',
    },
    {
        label: 'Avg. NP',
        color: 'extra-blue-v2',
    },
]
