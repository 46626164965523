import { IPieChartData } from '@components/rechart-component/pie/doughnut-chart.component'

const ColorPieChart = ({
    items,
    content = 'tradeLanes',
}: {
    items: IPieChartData[]
    content?: string
}) => {
    const totalValue = items.reduce((acc, itemData) => acc + itemData.value, 0)

    return (
        <div className="col-span-6 lg:col-span-7 grid lg:grid-cols-2 gap-3">
            {items.map((itemData, index) => {
                const { value, name, fill, textColor } = itemData
                const percentage = ((value / totalValue) * 100).toFixed(2)
                const formattedValue = value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })

                if (content === 'tradeLanes') {
                    return (
                        <div
                            key={index}
                            className="flex flex-col text-size-XS leading-none"
                        >
                            {name && (
                                <div className="thames-text-gray-v9 pt-1">
                                    {name}
                                </div>
                            )}
                            <div
                                style={{ background: `${fill}` }}
                                className={`w-card-donat-rechart h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex  py-3`}
                            >
                                <div
                                    style={{ color: `${textColor}` }}
                                    className=" font-bold leading-[18px]"
                                >
                                    {itemData.value} ({' '}
                                    {percentage !== 'NaN' ? percentage : 0}%)
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div
                            key={index}
                            className="flex flex-col text-size-XS leading-none"
                        >
                            {name && (
                                <div className="thames-text-gray-v9 pt-1">
                                    {name}
                                </div>
                            )}
                            <div className="thames-text-gray-v9 text-size-XS font-bold">{`US$ ${formattedValue}`}</div>
                            <div
                                style={{ background: `${fill}` }}
                                className={`w-card-donat-rechart h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex  py-3`}
                            >
                                <div
                                    style={{ color: `${textColor}` }}
                                    className=" font-bold leading-[18px]"
                                >
                                    {percentage !== 'NaN' ? percentage : 0}%
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default ColorPieChart
