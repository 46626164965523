import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { InventoryDataDummy } from './inventory.dummy'
import { IInventory } from './inventory.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const InventoryStatusHeader: ITabItem[] = [
    {
        label: 'All Inventory',
        labelTranslationCode: 'status.all_inventory',
        totalData: InventoryDataDummy.length,
        key: 'status',
        value: 'allInventory',
        className: 'brand',
    },
    {
        label: 'In Stock',
        labelTranslationCode: 'status.in_stock',
        totalData: InventoryDataDummy.filter(
            (x: any) => x.inventoryStatus === 'In Stock',
        ).length,
        key: 'status',
        value: 'In Stock',
        className: 'green',
    },
    {
        label: 'On Order',
        labelTranslationCode: 'status.on_order',
        totalData: InventoryDataDummy.filter(
            (x: any) => x.inventoryStatus === 'On Order',
        ).length,
        key: 'status',
        value: 'On Order',
        className: 'yellow',
    },
    {
        label: 'Out of Stock',
        labelTranslationCode: 'status.out_of_stock',
        totalData: InventoryDataDummy.filter(
            (x: any) => x.inventoryStatus === 'Out of Stock',
        ).length,
        key: 'status',
        value: 'Out of Stock',
        className: 'red',
    },
    {
        label: 'Others',
        labelTranslationCode: 'status.others',
        totalData: InventoryDataDummy.filter(
            (x: any) => x.inventoryStatus === 'Others',
        ).length,
        key: 'status',
        value: 'Others',
        className: 'gray',
    },
]

export const InventorySummaryHeader: ITableColumn[] = [
    {
        label: 'Warehouse',
        accessor: 'warehouseName',
    },
    {
        label: 'Part Number',
        accessor: 'partNumber',
    },
    {
        label: 'Description',
        accessor: 'partDesription',
    },
    {
        label: 'Total Units',
        accessor: 'totalUnits',
    },
    {
        label: 'Inventory Status',
        accessor: 'inventoryStatus',
    },
    {
        label: 'Available Units',
        accessor: 'availableUnits',
    },
    {
        label: 'Committed Units',
        accessor: 'committedUnits',
    },
    {
        label: 'Reserved Units',
        accessor: 'reservedUnits',
    },
    {
        label: 'Unit UQ',
        accessor: 'unitUQ',
    },
]

export const InventoryConfigureColumns: IConfigureColumns<IInventory>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Warehouse',
                labelTranslation: 'inventory.warehouse_name',
                accessor: 'warehouseName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Part Number',
                labelTranslation: 'inventory.part_number',
                accessor: 'partNumber',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Description',
                labelTranslation: 'inventory.part_description',
                accessor: 'partDesription',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Units',
                labelTranslation: 'inventory.total_units',
                accessor: 'totalUnits',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Inventory Status',
                labelTranslation: 'inventory.inventory_status',
                accessor: 'inventoryStatus',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Available Units',
                labelTranslation: 'inventory.available_units',
                accessor: 'availableUnits',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Committed Units',
                labelTranslation: 'inventory.committed_units',
                accessor: 'committedUnits',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Reserved Units',
                labelTranslation: 'inventory.reserved_units',
                accessor: 'reservedUnits',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Unit UQ',
                labelTranslation: 'inventory.unit_uq',
                accessor: 'unitUQ',
            },
        ],
    },
]
