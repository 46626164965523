var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import './tab.style.css';
import { useTranslation } from 'react-i18next';
var Tab = function (_a) {
    var items = _a.items, selectedItem = _a.selectedItem, onChange = _a.onChange, tabFilter = _a.tabFilter, _b = _a.containerClassName, containerClassName = _b === void 0 ? '' : _b;
    var t = useTranslation().t;
    var getSelectedIndex = items.findIndex(function (x) { return x.value === (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value); });
    var _c = useState(getSelectedIndex < 0 ? 0 : getSelectedIndex), activeIndex = _c[0], setActiveIndex = _c[1];
    return (_jsx("div", __assign({ className: "".concat(containerClassName, " header-status") }, { children: items.map(function (dt, idx) {
            var _a;
            var isActive = tabFilter
                ? dt.value === tabFilter.value
                : activeIndex === idx;
            var classActive = isActive ? 'active' : '';
            var paddingTop = dt.totalData !== undefined ? 'pt-2' : '';
            var className = 'themes-status-' + ((_a = dt.className) !== null && _a !== void 0 ? _a : 'gray');
            var label = dt.labelTranslationCode
                ? t(dt.labelTranslationCode)
                : dt.label;
            return (_jsxs("div", __assign({ className: "item ".concat(paddingTop, " ").concat(className, " ").concat(classActive), onClick: function () {
                    setActiveIndex(idx);
                    onChange(dt);
                } }, { children: [label, dt.totalData !== undefined && (_jsx("span", { children: dt.totalData })), _jsx("br", {}), _jsx("div", { className: 'bottom-line transition duration-300 pt-[3px] mt-2' })] }), idx));
        }) })));
};
export default Tab;
