/* eslint-disable no-unused-vars */
import { ILoginValidation } from 'client/common/form-validation/login.validation'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    forgotPassword,
    postLogin,
    postResetPassword,
} from 'client/common/repository/user.repository'
import { useAppDispatch } from 'store'
import { setLoginData, userDataSelector } from './login.slice'
import { IForgotPassword } from 'client/common/form-validation/forgot-password.validation'
import { Toast } from '@components/toast/toast.component'
import { useSelector } from 'react-redux'
import { IUserAuth } from 'client/common/repository/interface/user-auth.interface'
import { getClient } from 'common/common.service'
import { IResetPassword } from 'client/common/form-validation/reset-password.validation'
import {
    useLoginValidation,
    IIFBLoginValidation,
} from 'client/common/form-validation/ifb-login.validation'

export const useLogin = () => {
    const client: string = getClient()
    return {
        client,
    }
}

export const useLoginService = () => {
    // initial
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const user: IUserAuth = useSelector(userDataSelector)
    const client: string = getClient()
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    useEffect(() => {
        if (user.token) {
            navigate('/')
        }
    }, [])

    // add function (can more than one)
    const submitLogin = async (values: ILoginValidation) => {
        try {
            setLoading(true)
            const actionResult = await postLogin(values)

            setLoading(false)
            const response = actionResult
            if (!response.isSuccess) {
                setErrorMessage(response.message)
                return
            }
            const data = {
                ...response.data,
                OrganizationCode: values.OrganizationCode,
            }
            dispatch(setLoginData(data))

            navigate('/dashboard')
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const submitLoginNoApi = (values: IIFBLoginValidation) => {
        //temporary user
        const tempUser = [
            ['dev@onebyone.io', 'Qq121212'],
            ['ifb@gmail.com', 'IFB2024'],
        ]
        let isAuthenticated = false
        tempUser.forEach((d) => {
            if (d[0] === values.email && d[1] === values.password) {
                isAuthenticated = true
            }
        })

        // temporary for IFB
        const user = {
            organizationCode: 'ifb',
            email: values.email,
            password: '',
            role: 'admin',
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3ByaW1hcnlzaWQiOiI2ZTdiNTZiYy0xZjMzLTQzZDAtYmYyYS1hZjRkNzlkMDdhMDYiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJvbmVieW9uZS5sb2dpc3RpY2FsQGdtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoianBsIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQWRtaW4iLCJleHAiOjE3MTIyOTkyODQsImlzcyI6Imh0dHBzOi8vMC4wLjAuMDo3MDIyIiwiYXVkIjoiaHR0cHM6Ly8wLjAuMC4wOjcwMjIifQ.cSH5SlqGwGsrTq_N3oD0USFAXm8IS6aH0c8uyysCW0A',
            id: 'user1',
            portalLogin: 'ifb',
            expires_in: 100,
        }
        try {
            setLoading(true)
            if (isAuthenticated) {
                dispatch(setLoginData(user))
                navigate('/dashboard')
            } else if (
                values.email === user.email ||
                values.password === user.password
            ) {
                setErrorMessage('Email or password entered incorrectly')
            } else {
                setErrorMessage('Email not found')
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // return function or variable
    return {
        submitLogin,
        submitLoginNoApi,
        loading,
        errorMessage,
        client,
        user,
    }
}

export const useForgotPassword = () => {
    // initial
    const location = useLocation()
    const navigate = useNavigate()
    const [isLoginForm, setIsLoginForm] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [changeContent, setChangeContent] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.toString()) {
            setChangeContent(true)
            setIsLoginForm(false)
        } else {
            setChangeContent(false)
            setIsLoginForm(true)
        }
    }, [location.search])

    // add function (can more than one)
    const submitForgotPassword = async (values: IForgotPassword) => {
        try {
            setLoading(true)
            await forgotPassword(values)

            Toast({
                header: 'Success',
                message: 'Reset Password link sent to your email',
                type: 'success',
            })
            setLoading(false)
        } catch (e) {
            const errorMessage =
                typeof e !== 'string'
                    ? 'Email not found or incorrect. Please enter a valid email.'
                    : e
            setLoading(false)
            Toast({
                header: 'Failed!',
                message: errorMessage,
                type: 'error',
            })
        }
    }

    // add function (can more than one)
    const submitConfirmPassword = async (values: IResetPassword) => {
        try {
            setLoading(true)
            await postResetPassword(values)

            Toast({
                header: 'Success',
                message: `Please check your email for the reset password confirmation. If you haven’t receive the email, please try again or contact our support team for help via ${email}`,
                type: 'success',
            })
            navigate('/login')
            setLoading(false)
        } catch (e) {
            const errorMessage =
                typeof e !== 'string'
                    ? `Please try to reset your password again. If the error still occurs, please contact our support team for help via ${email}`
                    : e
            setLoading(false)
            Toast({
                header: 'Failed!',
                message: errorMessage,
                type: 'error',
            })
        }
    }

    // return function or variable
    return {
        submitForgotPassword,
        submitConfirmPassword,
        setEmail,
        loading,
        changeContent,
        errorMessage,
        isLoginForm,
        location,
        setIsLoginForm,
        setLoading,
        setErrorMessage,
    }
}
