import { IItemListInfo } from '@components/item-list-info/item-list-info.interface'

export const itemListRoad: IItemListInfo[] = [
    {
        label: 'FCL',
        color: '#9D75AD',
    },
    {
        label: 'LCL',
        color: '#D1B0DE',
    },
    {
        label: 'FTL',
        color: '#EDDAFC',
    },
]

export const itemListSea: IItemListInfo[] = [
    {
        label: 'FCL',
        color: '#5280C6',
    },
    {
        label: 'LCL',
        color: '#DADEFC',
    },
]
export const itemListAir: IItemListInfo[] = [
    {
        label: 'LSE',
        color: '#76C6CC',
    },
]

export const itemListRail: IItemListInfo[] = [
    {
        label: 'FCL',
        color: '#E3CD9A',
    },
    {
        label: 'LCL',
        color: '#FFF6E1',
    },
]

export const itemListCourier: IItemListInfo[] = [
    {
        label: 'COU',
        color: '#C98167',
    },
]
