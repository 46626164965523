import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import Modal from '@components//modal/modal.component'
import Table from '@components/table/table.component'
import Tooltip from '@components/tooltip/tooltip.component'
import { FormikProvider, useFormik } from 'formik'
import { IUseAttachPO } from '../attach-po/attach-po.interface'
import { IPoLineItem } from 'client/jpl/pages/purchase-order/purchase-order-detail/purchase-order-detail.interface'
import { modalAttachPoLineItemsHeaders } from '../attach-po/attach-po.static'

const AttachPoLinesModal = ({
    attachPoService,
}: {
    attachPoService: IUseAttachPO
}) => {
    const modalService = attachPoService.attachPoLinesModalService
    const { poLinesData, poLinesMeta, poLinesLoading, setPagePoLineData } =
        attachPoService
    const formik = useFormik<{ searchTerm: string }>({
        initialValues: { searchTerm: '' },
        onSubmit: (values) => attachPoService.searchPoLines(values.searchTerm),
        validate: (values) => {
            const errors: any = {}
            if (
                values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0
            ) {
                errors.searchTerm = 'Search term must be at least 3 characters'
            }
            return errors
        },
    })

    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="min-h-[calc(100vh-100px)] !p-0 flex flex-col"
        >
            <>
                <div className="border-b themes-border-color p-4">
                    {/* header */}
                    <div className="flex justify-between">
                        <div className="text-size-L font-bold flex items-center">
                            Attach PO
                        </div>
                        <div
                            className="text-size-M font-bold flex items-center cursor-pointer"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                        >
                            <i className="ri-close-fill  ri-xl"></i>
                        </div>
                    </div>

                    {/* search */}
                    <div className="flex mobile:flex-col flex-wrap justify-between pt-4">
                        <div className="text-size-M font-bold mobile:pb-2">
                            Select PO(s)
                        </div>
                        <div className="text-size-M font-bold flex  items-center cursor-pointer">
                            <FormikProvider value={formik}>
                                <form
                                    className="w-full flex justify-end"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        formik.handleSubmit()
                                    }}
                                >
                                    <Tooltip
                                        text={
                                            'Search term must be at least 3 characters'
                                        }
                                        isShow={
                                            formik.errors.searchTerm
                                                ? true
                                                : false
                                        }
                                    >
                                        <FormInput
                                            autoComplete="auto-off"
                                            hideError={true}
                                            placeholder="Search Any Keyword"
                                            parentDivClassName="w-full min-w-[240px] mobile:min-w-0 mobile:w-full !mb-0"
                                            icon="ri-search-line"
                                            name="searchTerm"
                                            onKeyDown={(
                                                event: React.KeyboardEvent<HTMLInputElement>,
                                            ) => {
                                                if (
                                                    event.key === 'Backspace' &&
                                                    formik.values.searchTerm
                                                        .length === 1
                                                ) {
                                                    formik.values.searchTerm =
                                                        ''
                                                    formik.handleSubmit()
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </form>
                            </FormikProvider>
                        </div>
                    </div>
                </div>
                <div className="flex-grow">
                    <div className="  h-[calc(100vh-290px)] flex-col w-full">
                        <Table<IPoLineItem>
                            headers={modalAttachPoLineItemsHeaders}
                            data={poLinesData}
                            meta={poLinesMeta}
                            loading={poLinesLoading}
                            nextHandling={(page) => {
                                setPagePoLineData(page)
                            }}
                            previousHandling={(page) => {
                                setPagePoLineData(page)
                            }}
                            moduleTitle={'Purchase Order'}
                            checkboxVisible={true}
                            containerClassname={'overflow-auto'}
                            checkboxDataHandling={(checkedData) => {
                                console.log(checkedData)
                                attachPoService.setTemporarySelectedPoLines(
                                    checkedData,
                                )
                            }}
                            additionalButtonBottom={
                                <Button
                                    onClick={() => {
                                        attachPoService.proceedPurchaseOrderLines()
                                        modalService.closeModalHandling()
                                        attachPoService.resetPoLinesCheckedData(
                                            true,
                                        )
                                    }}
                                    variant="brand"
                                    label="PROCEED PURCHASE ORDER"
                                />
                            }
                            resetCheckedInitialValue={
                                attachPoService.poLinesSelectedTemporary
                            }
                        />
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default AttachPoLinesModal
