import { IMeta } from './common.interface'

export const requiredMessage = (fieldName: string) => `${fieldName} is required`
export const invalidEmailMessage = () => `Invalid Email`

export const initialFilter = {
    status: '',
    inProgressStatus: '',
    pageNumber: 1,
    pageSize: 50,
    search: '',
}

export const metaDummy2 = (total: number) => {
    const meta: IMeta = {
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 50,
        total_Items: total,
        per_page: 0,
        total_page: 1,
        index_end: total,
        index_start: 1,
    }
    return meta
}
