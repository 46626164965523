var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell, } from 'recharts';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarLoading from '../loading/bar-loading.component';
import { numberWithCommas } from '@services/common.service';
function BarChartCustom(_a) {
    var data = _a.data, className = _a.className, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var _c = useState(window.innerWidth), widthSize = _c[0], setWidthSize = _c[1];
    var _d = useState(window.innerHeight), heightSize = _d[0], setHeightSize = _d[1];
    var ref = useRef(null);
    var t = useTranslation().t;
    var _e = useState({
        width: widthSize,
        height: 235,
    }), dimension = _e[0], setDimension = _e[1];
    var YAxisCssProperties = useState({
        fontSize: 14, // default px
    })[0];
    var updateDimensions = function () {
        setWidthSize(window.innerWidth);
        setHeightSize(window.innerHeight);
        var newDimension = __assign({}, dimension);
        if (heightSize === 1080)
            newDimension.height = 250;
        else if (heightSize < 1440 && heightSize > 1300) {
            newDimension.height = 440;
        }
        else if (heightSize === 911)
            newDimension.height = 320;
        setDimension(newDimension);
    };
    useEffect(function () {
        window.addEventListener('resize', updateDimensions);
        return function () {
            window.removeEventListener('resize', updateDimensions);
        };
    });
    var CustomizedXAxisTick = function (_a) {
        var x = _a.x, y = _a.y, payload = _a.payload, props = __rest(_a, ["x", "y", "payload"]);
        if (!(payload === null || payload === void 0 ? void 0 : payload.value))
            return null;
        var matchingData = typeof (props === null || props === void 0 ? void 0 : props.index) === 'number' ? data[props === null || props === void 0 ? void 0 : props.index] : undefined;
        var fontSize = widthSize > 1279 && widthSize < 1440 ? '10px' : '12px';
        // Function untuk memotong teks dan menambahkan ellipsis
        var getEllipsisText = function (text, maxLength) {
            var _a;
            // Step 1: Check if text exceeds maxLength
            if (text.length > maxLength) {
                // Step 2: Check if text contains '('
                var indexOfParenthesis = text === null || text === void 0 ? void 0 : text.indexOf('(');
                if (indexOfParenthesis !== -1) {
                    // Step 3: Remove the text inside parentheses including the parentheses
                    text = (_a = text.substring(0, indexOfParenthesis)) === null || _a === void 0 ? void 0 : _a.trim();
                }
                // Step 4: Check again if text exceeds maxLength after removing parentheses
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                }
            }
            // Return the original or trimmed text
            return text;
        };
        var calculateWidthContent = function () {
            var _a;
            var width = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.offsetWidth;
            if (!width)
                return 0;
            var lengthBar = data.length;
            var calculate = (width / lengthBar) * 0.3; // 0.3 from barCategoryGap in BarChart
            return calculate;
        };
        var maxLength = calculateWidthContent() < 20 ? 4 : 20;
        var displayText = getEllipsisText(payload === null || payload === void 0 ? void 0 : payload.value, maxLength);
        var secondaryText = getEllipsisText((matchingData === null || matchingData === void 0 ? void 0 : matchingData.secName) || '', maxLength);
        return (_jsxs("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: [_jsx("text", __assign({ x: 0, y: 0, dy: 16, textAnchor: "middle", fill: "#20384E", fontSize: fontSize }, { children: displayText })), _jsx("text", __assign({ x: 0, y: 0, dy: 30, textAnchor: "middle", fill: "#C0C7CD", fontSize: fontSize }, { children: secondaryText }))] })));
    };
    function roundToNearestReadableNumber(value) {
        var readableNumbers = [
            1, 5, 10, 50, 100, 500, 1000, 2500, 5000, 10000,
        ];
        for (var i = 0; i < readableNumbers.length; i++) {
            if (value <= readableNumbers[i]) {
                return readableNumbers[i];
            }
        }
        return readableNumbers[readableNumbers.length - 1];
    }
    var calculateLeftMargin = function () {
        var _a;
        // using formula linear line
        // formula intial margin yx = 0.5x−49
        // formula intial interval yx =  0.5x−1
        // formula marginLeft yx = (0.5x-51) + (((0.5x−1) * maxlength) - (0.5x-1))
        // x = fontSize
        var maxValue = Math.max.apply(Math, data.map(function (item) { return item.number; }));
        var interval = maxValue / 4;
        var upperLimit = roundToNearestReadableNumber(interval) * 4;
        var maxLength = upperLimit.toString().length;
        var x = (_a = YAxisCssProperties.fontSize) !== null && _a !== void 0 ? _a : 0;
        var initialMargin = 0.5 * x - 49;
        var initialInterval = 0.5 * x - 1;
        var marginLeft = initialMargin + (initialInterval * maxLength - initialInterval);
        return marginLeft;
    };
    var CustomTooltip = function (_a) {
        var active = _a.active, payload = _a.payload, label = _a.label;
        if (active && payload && payload.length) {
            var value = payload[0].value;
            if (!value)
                return null;
            return (_jsxs("div", __assign({ className: "custom-tooltip p-2 rounded-md themes-bg-gray-v9 opacity-80 themes-text-white flex items-center max-w-[200px]" }, { children: [_jsx("div", { className: "min-w-[20px] max-w-[20px] w-[20px] h-[20px] bg-white rounded-md mr-2", style: { backgroundColor: payload[0].payload.fill } }), _jsx("p", __assign({ className: "label 2xl:text-size-S text-size-XS" }, { children: "".concat(payload[0].payload.name, ": ").concat(numberWithCommas(value.toString())) }))] })));
        }
        return null;
    };
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(BarLoading, {})) : (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%", className: className, ref: ref }, { children: _jsxs(BarChart, __assign({ width: 50, height: 40, data: data, margin: {
                    top: 5,
                    right: 5,
                    left: calculateLeftMargin(),
                    bottom: -30,
                }, barCategoryGap: data.length <= 2 ? '30%' : '10%' }, { children: [_jsx(CartesianGrid, { stroke: "#f5f5f5", strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", interval: 0, height: 70, tick: _jsx(CustomizedXAxisTick, {}) }), _jsx(YAxis, { type: 'number', style: YAxisCssProperties, className: "themes-text-red-v3 !text-size-L" }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), _jsx(Bar, __assign({ dataKey: "number", fill: "#D9DDE1", radius: [3, 3, 0, 0] }, { children: data.map(function (_entry, index) { return (_jsx(Cell, { fill: _entry.fill }, "cell-".concat(index))); }) }))] })) }))) }));
}
export default BarChartCustom;
