/* eslint-disable no-unused-vars */
import Modal from '@components//modal/modal.component'
import { IPoShipment } from '../purchase-order-detail.interface'
import { IUseModal } from '@components//modal/modal.service'
import Button from '@components/button/button.component'
import { NavigateFunction, useNavigate } from 'react-router-dom'

const RelatedShipmentModal = ({
    modalService,
    data,
}: {
    modalService: IUseModal
    data: IPoShipment[]
}) => {
    const navigate = useNavigate()
    return (
        //for JPL
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/4">
            <div className="flex flex-col gap-4 justify-content">
                <div className="text-size-L border-b "> Related Shipment </div>
                <div className="-mx-2 flex flex-col h-[250px] overflow-auto">
                    {data.map((dt) => {
                        return (
                            <div
                                className="py-2 px-2 related-shipment-row flex items-center"
                                onClick={() => {
                                    navigate(
                                        '/shipments-detail/' + dt.shipmentNo,
                                    )
                                }}
                            >
                                {dt.shipmentNo}
                            </div>
                        )
                    })}
                </div>
                <div className="flex justify-content gap-4">
                    <div className="w-full">
                        <Button
                            label="CLOSE"
                            variant="default"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RelatedShipmentModal
