export const portalAccessOptions = [
    { label: 'CUSTOMER', value: 'customer' },
    { label: 'AGENT', value: 'agent' },
]
export const industryOptions = [
    { label: 'ALL ACCESS', value: 'all' },
    { label: 'FORWARDING', value: 'forwarding' },
    { label: 'WAREHOUSE', value: 'warehouse' },
    { label: 'ACCOUNTING', value: 'accounting' },
]
