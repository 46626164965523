var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Button from "@components/button/button.component";
import { useTranslation } from "react-i18next";
var useConfigureColumnsModalService = function (columns, setHeaders) {
    var t = useTranslation().t;
    var _a = useState([]), selectedColumns = _a[0], setSelectedColumns = _a[1];
    var _b = useState([]), availableColumns = _b[0], setAvailableColumns = _b[1];
    var _c = useState(columns), configureColumns = _c[0], setConfigureColumns = _c[1];
    useEffect(function () {
        loadAvailableColumns();
        loadSelectedColumns();
        loadHeaders();
    }, [configureColumns]);
    var loadHeaders = function () {
        var data = configureColumns.flatMap(function (item) {
            return item.columns
                .filter(function (subItem) { return subItem.statusColumn === "selectedColumns"; })
                .map(function (_a) {
                var statusColumn = _a.statusColumn, rest = __rest(_a, ["statusColumn"]);
                return rest;
            });
        });
        setHeaders(data);
    };
    var loadSelectedColumns = function () {
        var data = configureColumns.flatMap(function (item) {
            return item.columns
                .filter(function (subItem) { return subItem.statusColumn === "selectedColumns"; })
                .map(function (subItem) { return ({
                title: subItem.label,
                content: selectedColumnComponent(subItem.labelTranslation
                    ? t(subItem.labelTranslation)
                    : subItem.label),
            }); });
        });
        setSelectedColumns(data);
    };
    var loadAvailableColumns = function () {
        var data = configureColumns.map(function (item) { return ({
            title: item.title,
            content: item.columns
                .filter(function (subItem) { return subItem.statusColumn === "availableColumns"; })
                .map(function (subItem) { return ({
                subTitle: subItem.label,
                subContent: availableColumnComponent(subItem.label),
            }); }),
        }); });
        setAvailableColumns(data);
    };
    var handleSearchAvailableColumns = function (search) {
        if (search) {
            var searchData = availableColumns
                .map(function (item) {
                var filteredContent = item.content.filter(function (subItem) {
                    return subItem.subTitle.toLowerCase().includes(search.toLowerCase());
                });
                if (filteredContent.length > 0) {
                    return __assign(__assign({}, item), { content: filteredContent });
                }
                return null;
            })
                .filter(function (item) { return item !== null; });
            searchData.length && setAvailableColumns(searchData);
        }
        else {
            loadAvailableColumns();
        }
    };
    var availableColumnComponent = function (subTitle) { return (_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("div", { children: subTitle }), _jsx(Button, { onClick: function () {
                    changeStatusColumn(subTitle, "selectedColumns");
                }, icon: "ri-arrow-right-s-fill text-size-XL", className: "w-btnIconWidth" })] }), subTitle)); };
    var changeStatusColumn = function (item, changeTo) {
        var updateData = configureColumns.map(function (section) { return (__assign(__assign({}, section), { columns: section.columns.map(function (column) {
                return column.label === item ? __assign(__assign({}, column), { statusColumn: changeTo }) : column;
            }) })); });
        setConfigureColumns(updateData);
    };
    var selectedColumnComponent = function (title) { return (_jsxs("div", __assign({ className: "flex gap-3 items-center" }, { children: [_jsx(Button, { onClick: function () {
                    changeStatusColumn(title, "availableColumns");
                }, icon: "ri-arrow-drop-left-fill text-size-XL", className: "w-btnIconWidth", isDisabled: title === "Shipment ID" }), _jsx("div", { children: title })] }))); };
    var sortHeader = function (newItems) {
        var titleIndexMap = new Map(newItems.map(function (item, index) { return [item.title, index]; }));
        var updateData = configureColumns.flatMap(function (item) {
            return item.columns
                .filter(function (subItem) { return subItem.statusColumn === "selectedColumns"; })
                .sort(function (a, b) {
                var _a, _b;
                var indexA = (_a = titleIndexMap.get(a.label)) !== null && _a !== void 0 ? _a : -1;
                var indexB = (_b = titleIndexMap.get(b.label)) !== null && _b !== void 0 ? _b : -1;
                return indexA - indexB;
            });
        });
        setHeaders(updateData);
    };
    var handleSearchSelectedColumns = function (search) {
        if (search && selectedColumns.length) {
            var searchData = selectedColumns.filter(function (item) {
                return item.title.toLowerCase().includes(search.toLowerCase());
            });
            searchData.length && setSelectedColumns(searchData);
        }
        else {
            loadSelectedColumns();
        }
    };
    var moveItem = function (dragIndex, hoverIndex) {
        var dragItem = selectedColumns[dragIndex];
        var newItems = __spreadArray([], selectedColumns, true);
        newItems.splice(dragIndex, 1);
        newItems.splice(hoverIndex, 0, dragItem);
        setSelectedColumns(newItems);
        sortHeader(newItems);
    };
    var moveItemUp = function (index) {
        if (index > 0 && index < selectedColumns.length) {
            var newItems = __spreadArray([], selectedColumns, true);
            var item = newItems[index];
            newItems.splice(index, 1);
            newItems.splice(index - 1, 0, item);
            setSelectedColumns(newItems);
            sortHeader(newItems);
        }
    };
    var moveItemDown = function (index) {
        if (index >= 0 && index < selectedColumns.length - 1) {
            var newItems = __spreadArray([], selectedColumns, true);
            var item = newItems[index];
            newItems.splice(index, 1);
            newItems.splice(index + 1, 0, item);
            setSelectedColumns(newItems);
            sortHeader(newItems);
        }
    };
    return {
        selectedColumns: selectedColumns,
        availableColumns: availableColumns,
        configureColumns: configureColumns,
        setConfigureColumns: setConfigureColumns,
        handleSearchAvailableColumns: handleSearchAvailableColumns,
        handleSearchSelectedColumns: handleSearchSelectedColumns,
        moveItem: moveItem,
        moveItemUp: moveItemUp,
        moveItemDown: moveItemDown,
        loadHeaders: loadHeaders,
    };
};
export default useConfigureColumnsModalService;
