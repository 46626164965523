import { IRoute } from 'common/common.interface'
import UserAccessDetail from 'client/common/pages/user-acces/user-access-detail/user-access-detail.component'
import UserAccessForm from 'client/common/pages/user-acces/user-access-form/user-access-form.component'
import UserAccess from 'client/common/pages/user-acces/user-access.component'
import { DashboardInProgress } from 'client/common/pages/work-in-progress/work-in-progress.component'
import PurchaseOrderDetail from 'client/jpl/pages/purchase-order/purchase-order-detail/purchase-order-detail.component'
import PurchaseOrderPage from 'client/jpl/pages/purchase-order/purchase.order.component'
import AttachPo from 'client/jpl/pages/shipments/attach-po/attach-po.component'
import ShipmentDetails from 'client/jpl/pages/shipments/shipments-detail/shipments-details.components'
import Shipments from 'client/jpl/pages/shipments/shipments.component'

export const jplRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: false,
        icon: 'ri-dashboard-line',
        parentId: '1',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: true,
        icon: 'ri-dashboard-line',
        parentId: '1',
    },
    {
        path: 'purchase-order',
        text: 'Purchase Order',
        Content: PurchaseOrderPage,
        show: true,
        icon: 'ri-file-list-3-line',
        showSearch: true,
        parentId: '2',
    },
    {
        path: 'purchase-order-detail/:id',
        text: 'Purchase Order Detail',
        Content: PurchaseOrderDetail,
    },
    {
        path: 'shipments',
        text: 'Shipments',
        Content: Shipments,
        parentId: '3.2',
        show: true,
        icon: 'ri-truck-line',
    },
    {
        path: 'user',
        text: 'Manage User',
        parentId: '9',
        Content: UserAccess,
        show: true,
        icon: 'ri-shield-user-line',
    },
    {
        path: 'shipments-detail/:id',
        text: 'Shipments Detail',
        Content: ShipmentDetails,
    },
    {
        path: 'user/detail/:id',
        text: 'User Detail',
        Content: UserAccessDetail,
    },
    {
        path: 'profile',
        text: 'Profile',
        Content: () => UserAccessForm({ isNew: false, isProfilePage: true }),
    },
    {
        path: 'user/create/',
        text: 'Create User',
        Content: () => UserAccessForm({ isNew: true, isProfilePage: false }),
    },
    {
        path: 'user/update/:id',
        text: 'Update User',
        Content: () => UserAccessForm({ isNew: false, isProfilePage: false }),
    },
    {
        path: 'shipments-detail/attach-po/:id',
        text: 'Attach Purchase Order',
        Content: AttachPo,
    },
]
