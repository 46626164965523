import { IMeta } from 'common/common.interface'
import {
    IAverageMonthlyPerformance,
    IControlTowerMultipleBars,
    IDataCustomersTable,
    IDataOverviewTable1,
    IDataOverviewTable2,
    IDataTradeLanesTable,
    IFinanceTableData,
    IHistoryData,
    IMarkShipmentCustomers,
    ISplitRecordBarchartData,
} from './control-tower.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
    IBarChartStackedData,
    IDataJointChart,
    ILineData,
} from '@components/rechart-component/rechart-interface'
import { faker } from '@faker-js/faker'
import { progressMultipleBarKeyControlTower } from './control-tower.static'
import { IPieChartData } from '@components/rechart-component/pie/doughnut-chart.component'
import {
    IRelatedKeyFiguresItemsChangeResponse,
    IRelatedKeyFiguresItemsResponse,
    IRelatedKeyFiguresResponse,
} from 'client/ifb/repository/interface/control-tower.interface'
import { numberToCurrency, numberWithCommas } from 'common/common.service'

export interface IControlTowerSlice {
    // common
    meta: IMeta
    historyData: IHistoryData

    // segment overview
    dataOverviewTable1: IDataOverviewTable1[]
    dataOverviewTable2: IDataOverviewTable2[]
    joinChartOverview: IDataJointChart
    barchartOverview: IBarChartStackedData

    // segment trade lanes
    pieChartTradeLanes: {
        regionPieChart: IPieChartData[]
        carrierPieChart: IPieChartData[]
    }
    dataTradeLanesTable: IDataTradeLanesTable[]

    // segment customers
    customerChartOrganisationData: IControlTowerMultipleBars<
        IMarkShipmentCustomers,
        IMarkShipmentCustomers
    >
    customerTableData: IDataCustomersTable[]

    // segment finance
    financeTableData: IFinanceTableData[]
    financePieChart: IPieChartData[]
    averageMonthlyPerformance: IAverageMonthlyPerformance
}

export const initialState: IControlTowerSlice = {
    // segment common
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    historyData: {
        development: {
            chartData: {
                key: [
                    {
                        id: 'line1',
                        color: '#8D99A5',
                        label: 'This Period',
                    },
                    {
                        id: 'line2',
                        color: '#D9DDE1',
                        label: 'Last Period',
                    },
                ],
                line1: [],
                line2: [],
            },
            tickItems: [],
        },
        splitRecord: {
            key: [
                {
                    id: 'f1',
                    color: '#D9DDE1',
                },
                {
                    id: 'f2',
                    color: '#8D99A5',
                },
            ],
            data: [
                {
                    name: 'This Period',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'Last Period',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
            ],
        },
    },

    // segment overview
    dataOverviewTable1: [],
    dataOverviewTable2: Array.from({ length: 20 }, (_, idx) => [
        [
            {
                name: 'OneByOne Tokyo',
                thisPeriod: '15,580',
                lastPeriod: '11,544',
                change: '+4,036 (35%)',
            },
            {
                name: 'OneByOne New York City',
                thisPeriod: '14,698',
                lastPeriod: '14,002',
                change: '+696 (5%) ',
            },
            {
                name: 'OneByOne Paris',
                thisPeriod: '14,051',
                lastPeriod: '15,668',
                change: '-1,617 (10.3%) ',
            },
        ],
    ]).flat(2),
    joinChartOverview: {
        key: [
            {
                id: 'line1',
                color: '#8D99A5',
                label: 'This Period',
            },
            {
                id: 'line2',
                color: '#D9DDE1',
                label: 'Last Period',
            },
        ],
        line1: [],
        line2: [],
    },
    barchartOverview: {
        key: [
            {
                id: 'f1',
                color: '#D9DDE1',
            },
            {
                id: 'f2',
                color: '#8D99A5',
            },
        ],
        data: [
            {
                name: 'This Period',
                f1: faker.number.int({ min: 10, max: 30 }),
                f2: faker.number.int({ min: 10, max: 30 }),
                amt: 10,
            },
            {
                name: 'Last Period',
                f1: faker.number.int({ min: 10, max: 30 }),
                f2: faker.number.int({ min: 10, max: 30 }),
                amt: 10,
            },
        ],
    },

    // segment trade lanes
    pieChartTradeLanes: {
        regionPieChart: [
            {
                name: 'Southeast Asia',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#5280C6',
                textColor: '#FFFFFF',
            },
            {
                name: 'Sub-Saharan',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#76C6CC',
                textColor: '#FFFFFF',
            },
            {
                name: 'Middle East',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#9D75AD',
                textColor: '#FFFFFF',
            },
            {
                name: 'Latin America',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#E3CD9A',
                textColor: '#FFFFFF',
            },
            {
                name: 'Western Europe',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C98167',
                textColor: '#FFFFFF',
            },
            {
                name: 'Northern America',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C9677F',
                textColor: '#FFFFFF',
            },
            {
                name: 'Australia',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#84B37D',
                textColor: '#FFFFFF',
            },
            {
                name: 'Others',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#465A6C',
                textColor: '#FFFFFF',
            },
        ],
        carrierPieChart: [
            {
                name: 'Maersk Line',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#5280C6',
                textColor: '#FFFFFF',
            },
            {
                name: 'Mediterranean',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#76C6CC',
                textColor: '#FFFFFF',
            },
            {
                name: 'CMA CGM',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#9D75AD',
                textColor: '#FFFFFF',
            },
            {
                name: 'Evergreen Line',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#E3CD9A',
                textColor: '#FFFFFF',
            },
            {
                name: 'Hapag-Lloyd',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C98167',
                textColor: '#FFFFFF',
            },
            {
                name: 'COSCO Shipping',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C9677F',
                textColor: '#FFFFFF',
            },
            {
                name: 'UPS',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#84B37D',
                textColor: '#FFFFFF',
            },
            {
                name: 'Others',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#465A6C',
                textColor: '#FFFFFF',
            },
        ],
    },
    dataTradeLanesTable: Array.from({ length: 20 }, (_, idx) => [
        [
            {
                company: 'Brazil',
                thisPeriod: '15,580',
                lastPeriod: '11,544',
                change: '+4,036 (35%)',
            },
            {
                company: 'Canada',
                thisPeriod: '14,698',
                lastPeriod: '14,002',
                change: '+696 (5%) ',
            },
            {
                company: 'Japan',
                thisPeriod: '14,051',
                lastPeriod: '15,668',
                change: '-1,617 (10.3%) ',
            },
        ],
    ]).flat(2),

    // segment customers
    customerChartOrganisationData: {
        data: {
            total: 300000,
            items: {
                value: {
                    blue: 90000,
                    aqua: 84000,
                    purple: 30000,
                    orange: 18000,
                    yellow: 12000,
                    red: 9000,
                    gray: 57000,
                },
                additionalValue: {
                    blue: Math.round((90000 / 300000) * 100),
                    aqua: Math.round((84000 / 300000) * 100),
                    purple: Math.round((30000 / 300000) * 100),
                    orange: Math.round((18000 / 300000) * 100),
                    yellow: Math.round((12000 / 300000) * 100),
                    red: Math.round((9000 / 300000) * 100),
                    gray: Math.round((57000 / 300000) * 100),
                },
            },
        },
        keyItems: progressMultipleBarKeyControlTower,
    },
    customerTableData: Array.from({ length: 20 }, (_, idx) => {
        const color: string[] = [
            '#5280C6',
            '#76C6CC',
            '#9D75AD',
            '#E3CD9A',
            '#C98167',
            '#C9677F',
            '#C9677F',
        ]
        const randomColor = color[Math.floor(Math.random() * color.length)]

        return [
            {
                mark: randomColor,
                name: 'VALMANNEW',
                thisPeriod: '15,580',
                lastPeriod: '11,544',
                development: '+4,036 (35%)',
                rev: '30%',
                gp: '43.2%',
                vol: '58%',
                gpPerShipment: 'AUD 5,012.00',
            },
            {
                mark: randomColor,
                name: 'MICRESLON',
                thisPeriod: '14,698',
                lastPeriod: '14,002',
                development: '+696 (5.0%)',
                rev: '28%',
                gp: '25.2%',
                vol: '30.2%',
                gpPerShipment: 'AUD 3,952.00',
            },
            {
                mark: randomColor,
                name: 'APPDETSFO',
                thisPeriod: '14,698',
                lastPeriod: '15,668',
                development: '-1,617 (10.3%)',
                rev: '10%',
                gp: '78%',
                vol: '54%',
                gpPerShipment: 'AUD 2,269.00',
            },
        ]
    }).flat(2),

    // segment finance
    financeTableData: Array.from({ length: 20 }, (_, idx) => [
        [
            {
                name: 'REVENUE',
                thisPeriod: 'AUD 5,012.00',
                lastPeriod: 'AUD 5,984.00',
                change: '-AUD 972.00 (16.2%)',
            },
            {
                name: 'COSTS',
                thisPeriod: 'AUD 3,952.00',
                lastPeriod: 'AUD 2,547.62',
                change: '+AUD 1,404.38 (55.1%)',
            },
            {
                name: 'GP',
                thisPeriod: 'AUD 2,269.00',
                lastPeriod: 'AUD 2,002.58',
                change: '+AUD 266.42 (13.3%)',
            },
        ],
    ]).flat(2),
    financePieChart: [
        {
            name: 'Within payment term',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#C0C7CD',
            textColor: '#FFFFFF',
        },
        {
            name: '1 week overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#5B6C7C',
            textColor: '#FFFFFF',
        },
        {
            name: '2 weeks overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#FFE3A4',
            textColor: '#FFFFFF',
        },
        {
            name: '1 month overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
        },
        {
            name: '3 months overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#F691A9',
            textColor: '#FFFFFF',
        },
        {
            name: '6 months overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#C9677F',
            textColor: '#FFFFFF',
        },
    ],
    averageMonthlyPerformance: {
        chartData: {
            key: [
                {
                    id: 'line1',
                    color: '#5280C6',
                    label: 'Avg. GP',
                },
                {
                    id: 'line2',
                    color: '#C98167',
                    label: 'Avg. OPEX',
                },
                {
                    id: 'line3',
                    color: '#76C6CC',
                    label: 'Avg. NP',
                },
            ],
            line1: [],
            line2: [],
            line3: [],
        },
        tickItems: [],
    },
}

const controlTowerSlice = createSlice({
    name: 'ControlTowerIFB',
    initialState,
    reducers: {
        // segment common
        setHistoryData(
            state,
            action: PayloadAction<{
                development: {
                    line1: ILineData[]
                    line2: ILineData[]
                    tickItems: string[]
                }
                splitRecord: ISplitRecordBarchartData[]
            }>,
        ) {
            const { development, splitRecord } = action.payload
            state.historyData.development.chartData.line1 = development.line1
            state.historyData.development.chartData.line2 = development.line2
            state.historyData.development.tickItems = development.tickItems

            const splitRecordBarchartData = splitRecord.map((item) => ({
                ...item,
                amt: 10,
            }))
            state.historyData.splitRecord.data = splitRecordBarchartData
        },

        // segment overview
        setDataOverviewTable1(
            state,
            action: PayloadAction<IRelatedKeyFiguresResponse>,
        ) {
            const payloadData = action.payload

            const handleEmptyString = (
                value1?: string | number,
                value2?: string | number,
            ) => (value1 && value2 ? `${value1} ${value2}` : '')

            const findDataCurrentVolume = ({
                transportMode,
                containerMode,
                type,
            }: {
                transportMode: string
                containerMode?: string
                type: string
            }) => {
                const findDataCurrent = payloadData.current.items.find(
                    (item) =>
                        item.transport === transportMode &&
                        (containerMode !== undefined
                            ? item.container === containerMode
                            : true),
                )
                const findDataLast = payloadData.last.items.find(
                    (item) =>
                        item.transport === transportMode &&
                        (containerMode !== undefined
                            ? item.container === containerMode
                            : true),
                )
                const findDataChange = payloadData.change.itemsChange.find(
                    (item) =>
                        item.transport === transportMode &&
                        (containerMode !== undefined
                            ? item.container === containerMode
                            : true),
                )
                let keys: {
                    key: keyof IRelatedKeyFiguresItemsResponse
                    keyUnit: keyof IRelatedKeyFiguresItemsResponse
                    changeKey: keyof IRelatedKeyFiguresItemsChangeResponse
                    changeUnitKey: keyof IRelatedKeyFiguresItemsChangeResponse
                } = {
                    key: 'volume',
                    keyUnit: 'volumeUnit',
                    changeKey: 'volumeChange',
                    changeUnitKey: 'volumeUnit',
                }
                if (type === 'weight') {
                    keys = {
                        key: 'weight',
                        keyUnit: 'weightUnit',
                        changeKey: 'weightChange',
                        changeUnitKey: 'weightUnit',
                    }
                }

                return {
                    thisPeriod: handleEmptyString(
                        findDataCurrent?.[keys.key],
                        findDataCurrent?.[keys.keyUnit],
                    ),
                    lastPeriod: handleEmptyString(
                        findDataLast?.[keys.key],
                        findDataLast?.[keys.keyUnit],
                    ),
                    change: {
                        amount: findDataChange
                            ? Number(findDataChange[keys.changeKey])
                            : 0,
                        unit: findDataChange?.[keys.changeUnitKey] as string,
                    },
                }
            }

            const mappingData: IDataOverviewTable1[] = [
                {
                    item: 'Revenue',
                    thisPeriod: `${payloadData.current.items[0].currency} ${numberToCurrency(payloadData.current.revenue)}`,
                    lastPeriod: `${payloadData.last.items[0].currency} ${numberToCurrency(payloadData.last.revenue)}`,
                    change: {
                        amount: payloadData.change.revenueChange,
                        currency: payloadData.last.items[0].currency,
                    },
                    key: 'revenue',
                    additionalKey: {
                        changeKey: 'revenueChange',
                    },
                },
                {
                    item: 'GP',
                    thisPeriod: `${payloadData.current.items[0].currency} ${numberToCurrency(payloadData.current.gp)}`,
                    lastPeriod: `${payloadData.last.items[0].currency} ${numberToCurrency(payloadData.last.gp)}`,
                    change: {
                        amount: payloadData.change.gpChange,
                        currency: payloadData.last.items[0].currency,
                    },
                    key: 'gp',
                    additionalKey: {
                        changeKey: 'gpChange',
                    },
                },
                {
                    item: 'GP Margin',
                    thisPeriod: '',
                    lastPeriod: '',
                    change: {
                        amount: 0,
                    },
                    key: 'gpMargin',
                    additionalKey: {
                        changeKey: 'gpMargin',
                    },
                },
                {
                    item: 'No. of Shipments',
                    thisPeriod: numberWithCommas(
                        payloadData.current.noShipment.toString(),
                    ),
                    lastPeriod: numberWithCommas(
                        payloadData.last.noShipment.toString(),
                    ),
                    change: {
                        amount: payloadData.change.noShipmentChange,
                    },
                    key: 'noShipment',
                    additionalKey: {
                        changeKey: 'noShipmentChange',
                    },
                },
                {
                    item: 'Volume Sea FCL',
                    thisPeriod: findDataCurrentVolume({
                        transportMode: 'SEA',
                        containerMode: 'FCL',
                        type: 'volume',
                    }).thisPeriod,
                    lastPeriod: findDataCurrentVolume({
                        transportMode: 'SEA',
                        containerMode: 'FCL',
                        type: 'volume',
                    }).lastPeriod,
                    change: findDataCurrentVolume({
                        transportMode: 'SEA',
                        containerMode: 'FCL',
                        type: 'volume',
                    }).change,
                    key: 'volume',
                    additionalKey: {
                        changeKey: 'volumeChange',
                        unitKey: 'volumeUnit',
                    },
                },
                {
                    item: 'Volume Sea LCL',
                    thisPeriod: findDataCurrentVolume({
                        transportMode: 'SEA',
                        containerMode: 'LCL',
                        type: 'volume',
                    }).thisPeriod,
                    lastPeriod: findDataCurrentVolume({
                        transportMode: 'SEA',
                        containerMode: 'LCL',
                        type: 'volume',
                    }).lastPeriod,
                    change: findDataCurrentVolume({
                        transportMode: 'SEA',
                        containerMode: 'LCL',
                        type: 'volume',
                    }).change,
                    key: 'volume',
                    transportMode: 'sea',
                    containerMode: 'LCL',
                    additionalKey: {
                        changeKey: 'volumeChange',
                        unitKey: 'volumeUnit',
                    },
                },
                {
                    item: 'Volume Rail FCL',
                    thisPeriod: findDataCurrentVolume({
                        transportMode: 'RAI',
                        containerMode: 'FCL',
                        type: 'volume',
                    }).thisPeriod,
                    lastPeriod: findDataCurrentVolume({
                        transportMode: 'RAI',
                        containerMode: 'FCL',
                        type: 'volume',
                    }).lastPeriod,
                    change: findDataCurrentVolume({
                        transportMode: 'RAI',
                        containerMode: 'FCL',
                        type: 'volume',
                    }).change,
                    key: 'volume',
                    transportMode: 'rail',
                    containerMode: 'FCL',
                    additionalKey: {
                        changeKey: 'volumeChange',
                        unitKey: 'volumeUnit',
                    },
                },
                {
                    item: 'Volume Rail LCL',
                    thisPeriod: findDataCurrentVolume({
                        transportMode: 'RAI',
                        containerMode: 'LCL',
                        type: 'volume',
                    }).thisPeriod,
                    lastPeriod: findDataCurrentVolume({
                        transportMode: 'RAI',
                        containerMode: 'LCL',
                        type: 'volume',
                    }).lastPeriod,
                    change: findDataCurrentVolume({
                        transportMode: 'RAI',
                        containerMode: 'LCL',
                        type: 'volume',
                    }).change,
                    key: 'volume',
                    transportMode: 'rail',
                    containerMode: 'LCL',
                    additionalKey: {
                        changeKey: 'volumeChange',
                        unitKey: 'volumeUnit',
                    },
                },
                {
                    item: 'Volume Road',
                    thisPeriod: findDataCurrentVolume({
                        transportMode: 'RAI',
                        type: 'volume',
                    }).thisPeriod,
                    lastPeriod: findDataCurrentVolume({
                        transportMode: 'RAI',
                        type: 'volume',
                    }).lastPeriod,
                    change: findDataCurrentVolume({
                        transportMode: 'RAI',
                        type: 'volume',
                    }).change,
                    key: 'volume',
                    transportMode: 'road',
                    containerMode: 'LCL',
                    additionalKey: {
                        changeKey: 'volumeChange',
                        unitKey: 'volumeUnit',
                    },
                },
                {
                    item: 'Weight Air',
                    thisPeriod: findDataCurrentVolume({
                        transportMode: 'AIR',
                        type: 'weight',
                    }).thisPeriod,
                    lastPeriod: findDataCurrentVolume({
                        transportMode: 'AIR',
                        type: 'weight',
                    }).lastPeriod,
                    change: findDataCurrentVolume({
                        transportMode: 'AIR',
                        type: 'weight',
                    }).change,
                    key: 'weight',
                    transportMode: 'air',
                    containerMode: 'LSE',
                    additionalKey: {
                        changeKey: 'weightChange',
                        unitKey: 'weightUnit',
                    },
                },
            ]
            state.dataOverviewTable1 = mappingData
        },

        // segment finance
        setAverageMonthlyPerformance(
            state,
            action: PayloadAction<{
                line1: ILineData[]
                line2: ILineData[]
                line3: ILineData[]
                tickItems: string[]
            }>,
        ) {
            const { line1, line2, line3, tickItems } = action.payload
            state.averageMonthlyPerformance.chartData.line1 = line1
            state.averageMonthlyPerformance.chartData.line2 = line2
            state.averageMonthlyPerformance.chartData.line3 = line3
            state.averageMonthlyPerformance.tickItems = tickItems
        },
    },
})

// these all the variables used for selector\
// segment common
export const historyDataSelector = (state: RootState) =>
    state.controlTowerIFB.historyData || {}

// segment overview
export const dataOverviewTable1Selector = (state: RootState) =>
    state.controlTowerIFB.dataOverviewTable1 || {}
export const dataOverviewTable2Selector = (state: RootState) =>
    state.controlTowerIFB.dataOverviewTable2 || {}
export const joinChartOverviewSelector = (state: RootState) =>
    state.controlTowerIFB.joinChartOverview || {}
export const barChartOverviewSelector = (state: RootState) =>
    state.controlTowerIFB.barchartOverview || {}

// segment trade lanes
export const pieChartTradeLanesSelector = (state: RootState) =>
    state.controlTowerIFB.pieChartTradeLanes || {}
export const dataTradeLanesTableSelector = (state: RootState) =>
    state.controlTowerIFB.dataTradeLanesTable || {}

// segment customers
export const customerChartOrganisationDataSelector = (state: RootState) =>
    state.controlTowerIFB.customerChartOrganisationData || {}
export const customerTableDataSelector = (state: RootState) =>
    state.controlTowerIFB.customerTableData || {}

// segment finance
export const financeTableDataSelector = (state: RootState) =>
    state.controlTowerIFB.financeTableData || {}
export const financePieChartSelector = (state: RootState) =>
    state.controlTowerIFB.financePieChart || {}
export const averageMonthlyPerformanceSelector = (state: RootState) =>
    state.controlTowerIFB.averageMonthlyPerformance || {}

// all actions
export const {
    setHistoryData,
    setAverageMonthlyPerformance,
    setDataOverviewTable1,
} = controlTowerSlice.actions

// Reducer
export default controlTowerSlice.reducer
