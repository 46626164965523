var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import FormInput from '@components/form-input/form-input.component';
import Tooltip from '@components/tooltip/tooltip.component';
import { FormikProvider, useFormik } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggabbleList from './draggable-list.component';
import { useTranslation } from 'react-i18next';
var List = function (_a) {
    var header = _a.header, items = _a.items, onSearchSubmit = _a.onSearchSubmit, moveItem = _a.moveItem, moveItemUp = _a.moveItemUp, moveItemDown = _a.moveItemDown, _b = _a.isDraggable, isDraggable = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var formik = useFormik({
        initialValues: { searchTerm: '' },
        onSubmit: function (values) {
            onSearchSubmit && onSearchSubmit(values.searchTerm);
        },
        validate: function (values) {
            var errors = {};
            if (values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0) {
                errors.searchTerm = 'Search term must be at least 3 characters';
            }
            return errors;
        },
    });
    var heightContainer = onSearchSubmit
        ? 'h-[calc(100vh-20rem)]  mobile:h-auto'
        : 'h-full';
    return (_jsxs("div", __assign({ className: "".concat(heightContainer) }, { children: [onSearchSubmit && (_jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ className: "w-full flex justify-end mb-3", onSubmit: function (e) {
                        e.preventDefault();
                        formik.handleSubmit();
                    } }, { children: _jsx(Tooltip, __assign({ text: 'Search term must be at least 3 characters', isShow: formik.errors.searchTerm ? true : false }, { children: _jsx(FormInput, { autoComplete: "auto-off", hideError: true, placeholder: t('action.search', {
                                value: t('additional.items'),
                            }), parentDivClassName: "w-full  mobile:min-w-0 mobile:w-full !mb-0 ", icon: "ri-search-line", name: "searchTerm", onKeyDown: function (event) {
                                if (event.key === 'Backspace' &&
                                    formik.values.searchTerm.length === 1) {
                                    formik.values.searchTerm = '';
                                    formik.handleSubmit();
                                }
                            } }) })) })) }))), _jsxs("div", __assign({ className: "border rounded h-full flex flex-col" }, { children: [_jsx("div", __assign({ className: "border-b p-3 font-bold themes-text-gray-v5" }, { children: header })), isDraggable ? (_jsx(DndProvider, __assign({ backend: HTML5Backend }, { children: _jsx("div", __assign({ className: "h-full overflow-auto" }, { children: items === null || items === void 0 ? void 0 : items.map(function (item, index) {
                                return (_jsx("div", { children: _jsx(DraggabbleList, { item: item, index: index, moveItem: moveItem, moveItemUp: moveItemUp, moveItemDown: moveItemDown, itemLength: items.length }) }, index));
                            }) })) }))) : (_jsx("div", __assign({ className: "" }, { children: items.map(function (item, index) {
                            var lastRowClass = index + 1 === (items === null || items === void 0 ? void 0 : items.length)
                                ? 'border-b-0'
                                : ' border-b';
                            return (_jsx("div", __assign({ className: "".concat(lastRowClass, " p-3") }, { children: item.content }), index));
                        }) })))] }))] })));
};
export default List;
