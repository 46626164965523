import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import { ProductStatusHeader, ProductSummaryHeader } from './products.static'
import { IProduct } from './products.interface'
import { metaDummy, ProductDataDummy } from './products.dummy'
import useProducts from './products.service'
import ProductFilterOverlay from './components/products-filter-overlay.component'
import ProductFilter from './components/products-filter.component'
import { useTranslation } from 'react-i18next'

const Products = () => {
    const { t } = useTranslation()
    const {
        filterOverlayService,
        generateReportModalService,
        tabFilter,
        data,
        setTabFilter,
        allModal,
        headers,
        ConfigureColumnsModalService,
        navigate,
    } = useProducts()

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)] pt-1.5 xl:pt-0 sm:pt-0">
                <TabMaster
                    items={ProductStatusHeader}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('products.part_number'),
                    })}
                    onSearchSubmit={() => {}}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    iconButton1={{
                        onClick: () =>
                            filterOverlayService.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <ProductFilterOverlay
                                filterOverlayService={filterOverlayService}
                            />
                        ),
                    }}
                    iconButton2={{
                        onClick: () =>
                            ConfigureColumnsModalService.openModalHandling(),
                        icon: 'ri-settings-2-line',
                    }}
                />

                <Table<IProduct>
                    headers={headers}
                    data={data}
                    loading={false}
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    moduleTitle={'Products'}
                    onRowClick={(e) => {
                        navigate(`/products-details/${e.partNumber}`)
                    }}
                    enableExport={true}
                    // modalService={generateReportModalService}
                    // components={<ProductFilter isGenerateReport={true} />}
                    // resetFilter={() => {}}
                    // getDataGenerate={() => {}}
                    // generateReportData={ProductDataDummy}
                    // GenerateReportHeaders={ProductSummaryHeader}
                    // removeFilter={() => {}}
                />
            </div>
            {allModal}
        </div>
    )
}

export default Products
