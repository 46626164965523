var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
var Pills = function (_a) {
    var items = _a.items, onChange = _a.onChange, unActiveVariant = _a.unActiveVariant;
    var _b = useState(0), activeIndex = _b[0], setActiveIndex = _b[1];
    var bgVariantUnActive = unActiveVariant === "white" ? "themes-bg-white" : "themes-bg-gray-v2";
    return (_jsx("div", __assign({ className: "  overflow-auto", style: { scrollbarWidth: "none" } }, { children: _jsx("div", __assign({ className: " flex gap-x-2 w-max" }, { children: items === null || items === void 0 ? void 0 : items.map(function (x, idx) {
                var isActive = activeIndex === idx
                    ? "themes-bg-brand-v5 themes-text-white"
                    : bgVariantUnActive;
                return (_jsx("div", __assign({ className: "".concat(isActive, " p-2 cursor-pointer rounded-full z-[0] text-size-S text-size-XS"), onClick: function () {
                        setActiveIndex(idx);
                        onChange(x);
                    } }, { children: x === null || x === void 0 ? void 0 : x.label }), "pill-".concat(idx)));
            }) })) })));
};
export default Pills;
