import moment from 'moment'
import { IMilestones } from '../../shipments.interface'

const Milestones = ({
    data,
    id,
}: {
    data: IMilestones[]
    id: string | undefined
}) => {
    return (
        <div className="flex flex-row w-full h-full overflow-hidden">
            <div className="flex-wrap w-1/3 md:min-w-[337px] sm:min-w-[250px]">
                <div className="flex flex-col h-full">
                    <button
                        className="border-b-4 p-3 flex justify-between items-center themes-border-brand-v4 themes-text-brand-v4"
                        type="button"
                        onClick={() => {}}
                    >
                        <div className="flex items-center gap-2">
                            <span>SHIPMENT</span>
                            <span className="font-bold">{id}</span>
                        </div>
                        <i className="ri-arrow-right-s-line" />
                    </button>
                </div>
            </div>

            <div
                className={`flex flex-grow themes-bg-gray-v2 border-l themes-border-gray-v4 ${data.length === 0 && 'justify-center '}`}
            >
                <div className="flex">
                    {/* <div className="border-l themes-border-gray-v4" /> */}

                    {/* Milestones content */}
                    {data.length > 0 ? (
                        <div className="flex flex-col flex-grow p-3">
                            {data?.map((item: IMilestones, idx: number) => (
                                <div
                                    key={`data-track-${idx}`}
                                    className="flex items-start relative"
                                >
                                    {/* Milestones dot and line */}
                                    <div className="mr-2 flex flex-col items-center">
                                        <div
                                            className={`rounded-full w-3 h-3 ${
                                                idx === 0
                                                    ? 'themes-bg-gray-v7'
                                                    : 'themes-bg-brand-v1 border-[3px] t'
                                            } line-through z-[1]`}
                                        />
                                        {idx !== data?.length - 1 && (
                                            <div className="absolute top-0 bottom-0 w-[2px] themes-bg-gray-v3" />
                                        )}
                                    </div>

                                    {/* Milestones content */}
                                    <div className="flex flex-col pb-5 -mt-1">
                                        <div className="font-bold">
                                            {item.description}
                                        </div>
                                        <div className="text-table-desc">
                                            {item.estimated
                                                ? moment(item.estimated).format(
                                                      'DD/MM/YYYY, HH:mm',
                                                  )
                                                : 'No Date Available'}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className=" self-center">
                            No Milestones Available
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Milestones
