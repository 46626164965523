import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { ProductDataDummy } from './products.dummy'
import { IProduct } from './products.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const ProductStatusHeader: ITabItem[] = [
    {
        label: 'All Products',
        labelTranslationCode: 'status.all_product',
        totalData: ProductDataDummy.length,
        key: 'status',
        value: 'allProducts',
        className: 'brand',
    },
    {
        label: 'Widget Assembly',
        labelTranslationCode: 'status.widget_assembly',
        totalData: ProductDataDummy.filter(
            (x: any) => x.partDescription === 'Widget Assembly',
        ).length,
        key: 'status',
        value: 'Widget Assembly',
        className: 'green',
    },
    {
        label: 'Copper Wire Bundle',
        labelTranslationCode: 'status.copper_wire_bundle',
        totalData: ProductDataDummy.filter(
            (x: any) => x.partDescription === 'Copper Wire Bundle',
        ).length,
        key: 'status',
        value: 'Copper Wire Bundle',
        className: 'yellow',
    },
    {
        label: 'Aluminum Frame',
        labelTranslationCode: 'status.alumunium_frame',
        totalData: ProductDataDummy.filter(
            (x: any) => x.partDescription === 'Aluminum Frame',
        ).length,
        key: 'status',
        value: 'Aluminum Frame',
        className: 'red',
    },
    {
        label: 'Others',
        labelTranslationCode: 'status.others',
        totalData: ProductDataDummy.filter(
            (x: any) => x.partDescription === 'Others',
        ).length,
        key: 'status',
        value: 'Others',
        className: 'gray',
    },
]

export const ProductSummaryHeader: ITableColumn[] = [
    {
        label: 'Part Number',
        accessor: 'partNumber',
    },
    {
        label: 'Description',
        accessor: 'partDescription',
    },
    {
        label: 'Model',
        accessor: 'model',
    },
    {
        label: 'Weight',
        accessor: 'weight',
    },
]

export const ProductsConfigureColumns: IConfigureColumns<IProduct>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: 'Part Number',
                labelTranslation: 'products.part_number',
                accessor: 'partNumber',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Description',
                labelTranslation: 'products.part_description',
                accessor: 'partDescription',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Model',
                labelTranslation: 'products.model',
                accessor: 'model',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Weight',
                labelTranslation: 'products.weight',
                accessor: 'weight',
            },
        ],
    },
]
