import {
    createUserInitialValue,
    createUserValidation,
    ICreateUserModuleValidation,
    ICreateUserValidation,
} from 'client/ifb/form-validation/create-user.validation'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserAccessConfigurationModal from './components/user-access-configuration-modal.component'
import { useModal } from '@components/modal/modal.service'
import {
    createUser,
    getAccessModule,
    getCurrentUser,
    updateUser,
} from 'client/ifb/repository/manage-user.repository'
import { useDispatch, useSelector } from 'react-redux'
import {
    chooseModuleSelector,
    currentUserSelector,
    filterModuleTemplateSelector,
    manageUserDetailSelector,
    moduleTemplatesSelector,
    setChooseModule,
    setCurrentUser,
    setFilterModule,
    setModuleTemplate,
} from '../manage-users.slice'
import {
    IFilterModuleTemplates,
    IModuleTemplate,
} from '../manage-users.interface'
import { Toast } from '@components/toast/toast.component'
import {
    IUpdateUserValidation,
    updateUserInitialValue,
    updateUserValidation,
} from 'client/ifb/form-validation/update-user.validation'

const useManageUserForm = (isNew: boolean) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // selectors
    const currentUser = useSelector(manageUserDetailSelector)
    const filterModuleTemplate = useSelector(filterModuleTemplateSelector)
    const moduleTemplates = useSelector(moduleTemplatesSelector)
    const chooseModule = useSelector(chooseModuleSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingModule, setLoadingModule] = useState<boolean>(false)

    const userAccessConfModal = useModal()

    const formik = useFormik<ICreateUserValidation>({
        validationSchema: createUserValidation,
        initialValues: createUserInitialValue,
        onSubmit: async (values) => {
            // set field
            const setDataModule = moduleTemplates.map((item) => {
                const initialAccess = {
                    canAccessModule: false,
                    canViewDetails: false,
                    canCreate: false,
                    canUpdate: false,
                    canDelete: false,
                }
                // Mengubah `loadActionList` menjadi objek akses
                const moduleAccess = item.loadActionList.reduce(
                    (access, action) => {
                        switch (action.value) {
                            case 'accessModule':
                                access.canAccessModule = action.isActive
                                break
                            case 'viewDetails':
                                access.canViewDetails = action.isActive
                                break
                            case 'create':
                                access.canCreate = action.isActive
                                break
                            case 'update':
                                access.canUpdate = action.isActive
                                break
                            case 'delete':
                                access.canDelete = action.isActive
                                break
                            default:
                                break
                        }
                        return access
                    },
                    { ...initialAccess },
                )
                return {
                    moduleTemplateId: item.id,
                    access: moduleAccess,
                }
            })

            values.moduleList = setDataModule
            try {
                const response = await createUser(values)
                if (response.isSuccess) {
                    navigate('/manage-users')
                    const message = isNew
                        ? 'New user Succesfully Created!'
                        : 'User Details Successfully Edited!'
                    Toast({
                        header: message,
                        message: '',
                        type: 'success',
                    })
                } else {
                    Toast({
                        header: 'Failed',
                        message: response?.message,
                        type: 'error',
                    })
                }
            } catch (error) {
                console.error(error)
                Toast({
                    header: 'Failed',
                    message: 'Failed to create user',
                    type: 'error',
                })
            }
        },
    })
    const formikUpdate = useFormik<IUpdateUserValidation>({
        validationSchema: updateUserValidation,
        initialValues: updateUserInitialValue(currentUser),
        onSubmit: async (values) => {
            // console.log(values)
            try {
                const response = await updateUser(values)
                if (response.isSuccess) {
                    navigate('/manage-users')
                    const message = isNew
                        ? 'New User Successfully Created!'
                        : 'User Details Successfully Edited!'
                    Toast({
                        header: message,
                        message: '',
                        type: 'success',
                    })
                } else {
                    Toast({
                        header: 'Failed',
                        message: response?.message,
                        type: 'error',
                    })
                }
            } catch (error) {
                console.error(error)
                Toast({
                    header: 'Failed',
                    message: 'Failed to create user',
                    type: 'error',
                })
            }
        },
    })

    const submitForm = () => {
        if (isNew) formik.submitForm()
        else formikUpdate.submitForm()
        const err = isNew ? formik.errors : formikUpdate.errors
        if (Object.keys(err).length > 0) {
            Toast({
                header: 'Unable to Proceed!',
                message:
                    'There is some mandatory fields required to not leave blank',
                type: 'error',
            })
        }
    }

    const loadCurrentUser = async () => {
        try {
            setLoading(true)
            const getUser = await getCurrentUser()
            dispatch(setCurrentUser(getUser))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const loadModule = async () => {
        const setFilter = {
            industry:
                filterModuleTemplate.industry === 'all'
                    ? ''
                    : filterModuleTemplate.industry,
            portalAcess: filterModuleTemplate.portalAcess,
        }
        try {
            setLoadingModule(true)
            const getModuleTemplates = await getAccessModule(setFilter)
            dispatch(setModuleTemplate(getModuleTemplates))
            setLoadingModule(false)
        } catch (error) {
            setLoadingModule(false)
            console.error(error)
        }
    }

    const onClickModule = (item: IModuleTemplate) => {
        dispatch(setChooseModule(item))
    }

    const onClickFilterModule = (filter: IFilterModuleTemplates) => {
        const setFilter = {
            industry: filter.industry || filterModuleTemplate.industry,
            portalAcess: filter.portalAcess || filterModuleTemplate.portalAcess,
        }
        dispatch(setFilterModule(setFilter))
    }

    const handleToggle = (value: string) => {
        if (!chooseModule) return
        const updatedActionList = chooseModule?.loadActionList?.map((item) =>
            item.value === value ? { ...item, isActive: !item.isActive } : item,
        )
        const data = {
            ...chooseModule,
            loadActionList: updatedActionList,
        }
        dispatch(setChooseModule(data))
    }

    const allModal = (
        <UserAccessConfigurationModal
            modalService={userAccessConfModal}
            moduleTemplates={moduleTemplates}
            onClickModule={onClickModule}
            onClickFilterModule={onClickFilterModule}
            filterModuleTemplate={filterModuleTemplate}
            loading={loadingModule}
            chooseModule={chooseModule}
            handleToggle={handleToggle}
        />
    )

    useEffect(() => {
        if (isNew) {
            loadCurrentUser()
        }
    }, [isNew])

    useEffect(() => {
        if (!currentUser.id) return
        loadModule()
    }, [
        currentUser,
        filterModuleTemplate.industry,
        filterModuleTemplate.portalAcess,
    ])

    return {
        formik,
        formikUpdate,
        allModal,
        userAccessConfModal,
        currentUser,
        loading,
        submitForm,
        navigate,
    }
}

export default useManageUserForm
