import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { commonEndpoints } from 'client/common/endpoints/common.endpoints'

export const getCountry = async () => {
    try {
        const response = await fetch<any, any>({
            endpoint: commonEndpoints.country,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<any>
        err.isSuccess = false
        return err
    }
}

export const getOrganization = async () => {
    try {
        const response = await fetch<any, any>({
            endpoint: commonEndpoints.organization,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<any>
        err.isSuccess = false
        return err
    }
}
