import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { useTranslation } from 'react-i18next'

const ShipmentInformationForm = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>{t('bookings.shipment_information')}</div>
            <div className="grid grid-cols-2 grid-flow-row gap-3 pt-3">
                <FormInput
                    label={t('bookings.shippers_ref')}
                    name={`ShippersRef`}
                    placeholder={t('action.enter', {
                        value: t('bookings.shippers_ref'),
                    })}
                    parentDivClassName="!mb-0 col-span-2"
                />
                <FormDropdown
                    label={t('bookings.transport_mode')}
                    name={`transportMode`}
                    placeholder={t('action.enter', {
                        value: t('bookings.transport_mode'),
                    })}
                    parentDivClassName="!mb-0 w-full"
                    options={[
                        {
                            label: 'Sea Freight',
                            value: 'Sea Freight',
                        },
                    ]}
                />
                <FormInput
                    label={t('bookings.container_mode')}
                    name={`containerMode`}
                    placeholder={t('action.enter', {
                        value: t('bookings.container_mode'),
                    })}
                    parentDivClassName="!mb-0 w-full"
                    disabled
                />
                <FormDropdown
                    label={t('bookings.service_level')}
                    name={`serviceLevel`}
                    placeholder={t('action.enter', {
                        value: t('bookings.service_level'),
                    })}
                    parentDivClassName="!mb-0"
                    options={[
                        {
                            label: 'STD - STANDARD',
                            value: 'std',
                        },
                    ]}
                />
                <FormDropdown
                    label={t('bookings.packs')}
                    name={`packs`}
                    placeholder={t('action.enter', {
                        value: t('bookings.packs'),
                    })}
                    parentDivClassName="!mb-0 w-full"
                    options={[
                        {
                            label: 'Sea Freight',
                            value: 'Sea Freight',
                        },
                    ]}
                />
                <FormDropdown
                    label={t('bookings.outer_pack_unit')}
                    name={`outerPackUnit`}
                    placeholder={t('action.enter', {
                        value: t('bookings.outer_pack_unit'),
                    })}
                    parentDivClassName="!mb-0 w-full"
                    options={[
                        {
                            label: 'STD - STANDARD',
                            value: 'std',
                        },
                    ]}
                />
                <FormMeasurementInput
                    options={[{ label: 'Cubic Meters', value: 'M3' }]}
                    name={'volumeMeasure'}
                    label={t('bookings.volume_measure')}
                />
                <FormMeasurementInput
                    options={[{ label: 'Kilogram', value: 'KG' }]}
                    name={'weightMeasure'}
                    label={t('bookings.weight_measure')}
                />
                <FormMeasurementInput
                    options={[{ label: 'Kilogram', value: 'KG' }]}
                    name={'chargeableWeightOrVolume'}
                    label={t('bookings.chargeable_weight_volume')}
                />
                <FormTextarea
                    name={'goodsDescription'}
                    label={t('bookings.goods_description')}
                    placeholder={t('action.enter', {
                        value: t('bookings.goods_description'),
                    })}
                    parentDivClassName="col-span-2"
                />
                <FormTextarea
                    name={'marksAndNumbers'}
                    label={t('bookings.marks_and_numbers')}
                    placeholder={t('action.enter', {
                        value: t('bookings.marks_and_numbers'),
                    })}
                    parentDivClassName="col-span-2"
                />
            </div>
        </div>
    )
}

export default ShipmentInformationForm
