import AvatarCircle from '@components/avatar-circle/avatar-circle.component'
import Button from '@components/button/button.component'
import Table from '@components/table/table.component'
import './manage-user-details.style.css'
import useManageUserDetails from './manage-user-details.service'
import { userActivityHeaders } from '../manage-users.static'
import { useTranslation } from 'react-i18next'
import { IManageUserLog } from './manage-users-details.interface'
import { SettingIcon } from './components/setting-icon.component'
import SuspendUserModal from 'client/common/pages/user-acces/modal/suspend-user-modal.component'
import { formatDate } from 'common/common.service'

const ManageUserDetails = () => {
    const { t } = useTranslation()
    const {
        id,
        manageUserDataLog,
        modalConfirmationService,
        manageUserDetail,
        loading,
        navigate,
        changeStatus,
    } = useManageUserDetails()

    return (
        <div
            className={`manage-user-detail transition-opacity duration-500 !overflow-visible`}
        >
            <div className="content-1 content-full-height">
                {/* Flex 1, 2 rows */}

                <div className="content-1-A container-global">
                    {/* Image Session */}
                    <div className="flex justify-between items-centers gap-4">
                        <AvatarCircle name={'Darlene Osinski'} size={50} />
                        <div className="flex-1 flex flex-col leading-none ">
                            <div className="text-size-L pb-1 pt-1 font-bold">
                                {manageUserDetail.fullName}
                            </div>
                            <div className="text-size-M">Admin Staff</div>
                        </div>
                        <SettingIcon
                            isActive={manageUserDetail.isActive ? true : false}
                            suspendFunction={() => {
                                modalConfirmationService.openModalHandling()
                            }}
                        />
                    </div>

                    {/* Border */}
                    <div className="border-t mx-[-1rem] my-4"></div>

                    {/* Detail User Session */}
                    <div className="flex-1 flex">
                        <div className="flex-1">
                            <div className="flex flex-col gap-4">
                                <ValueLabel
                                    label={t('users.email')}
                                    value={manageUserDetail.email}
                                />
                                <ValueLabel
                                    label={t('users.phone')}
                                    value={manageUserDetail.phoneNumber}
                                />
                                <ValueLabel
                                    label={t('users.job_title')}
                                    value={manageUserDetail.jobTitle}
                                />
                                <ValueLabel
                                    label={t('users.job_category')}
                                    value={manageUserDetail.jobCategory}
                                />
                                <ValueLabel
                                    label={t('users.organisation')}
                                    value={manageUserDetail.organizationName}
                                />
                                <ValueLabel
                                    label={t('users.status')}
                                    value={
                                        manageUserDetail.isActive !== null
                                            ? manageUserDetail.isActive
                                                ? 'Active'
                                                : 'Suspended'
                                            : '-'
                                    }
                                />
                                <ValueLabel
                                    label={t('users.last_active')}
                                    value={
                                        manageUserDetail.lastLogin
                                            ? formatDate(
                                                  manageUserDetail.lastLogin,
                                              )
                                            : ''
                                    }
                                />
                                <ValueLabel
                                    label={t('users.role')}
                                    value={
                                        manageUserDetail.role?.moduleAccessList
                                            ?.length === 0 ||
                                        manageUserDetail.role
                                            ?.moduleAccessList === undefined
                                            ? '-'
                                            : manageUserDetail.role
                                                    ?.roleType === 0
                                              ? 'Admin'
                                              : 'User'
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/* Border */}
                    <div className="border-t mx-[-1rem] my-4"></div>
                    <div className="flex justify-between">
                        <Button
                            label={t('action.back')}
                            className="w-btnSmallWidth"
                            onClick={() => {
                                navigate('/manage-users')
                            }}
                        />
                        <Button
                            className="!w-[160px]"
                            icon="ri-edit-2-line"
                            label={t('action.update', {
                                value: t('users.user'),
                            })}
                            onClick={() => {
                                navigate('/manage-users/update/' + id)
                            }}
                            useUpperCase={true}
                        />
                    </div>
                </div>
            </div>
            <div className="content-2  container-global content-full-height !p-0">
                {/* Flex 2, 1 rows */}

                <div className="flex gap-4 pt-4 pl-4">
                    <div className="flex-grow text-size-L pb-1 pt-1 font-bold">
                        {t('users.user_activity_logs')}
                    </div>
                </div>

                {/* Border */}
                <div className="border-t mx-[-1rem] mt-4"></div>

                <div className="flex-grow h-[calc(100vh-12.4rem)] items-center">
                    <Table<IManageUserLog>
                        headers={userActivityHeaders}
                        data={manageUserDataLog}
                        moduleTitle={t('users.user')}
                        usePagination={false}
                        enableExport={false}
                        loading={loading}
                    />
                </div>
            </div>

            {/* Modal for suspend and unsuspend user */}
            {/* <SelectModuleModal
                modalService={modalService}
                moduleList={moduleAdjustmentList}
                setModalSelectedPhase1={(selected) => {
                    setModalSelectedPhase1(selected)
                }}
                setModalSelectedPhase2={(selected) => {
                    setModalSelectedPhase2(selected)
                }}
                setAccessHandling={(indexAction: number) => {
                    setAccess(indexAction)
                }}
                modalSelectedPhase1={modalSelectedPhase1}
                modalSelectedPhase2={modalSelectedPhase2}
                setAllAccess={() => {
                    setAllAccess({})
                }}
            /> */}
            <SuspendUserModal
                data={manageUserDetail}
                modalService={modalConfirmationService}
                isActive={manageUserDetail?.isActive ?? false}
                onSubmit={function (): void {
                    changeStatus()
                }}
            />
        </div>
    )
}

const ValueLabel = ({
    label = ' - ',
    value = ' - ',
}: {
    label?: string
    value?: string
}) => {
    const labelShow = label === '' || !label ? '-' : label
    const valueShow = value === '' || !value ? '-' : value
    return (
        <div className="leading-none ">
            <p className="!text-size-XS font-light pb-1">{labelShow}</p>
            <p className="!text-size-M font-normal">{valueShow}</p>
        </div>
    )
}

export default ManageUserDetails
