import { useEffect, useRef, useState } from 'react';
var useMonthYearPicker = function (_a) {
    var value = _a.value, readonly = _a.readonly, onChange = _a.onChange, disabled = _a.disabled;
    var pickerRef = useRef(null);
    var initialMonthYear = value || null;
    var _b = useState(initialMonthYear), selectedMonthYear = _b[0], setSelectedMonthYear = _b[1];
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = useState(false), focus = _d[0], setFocus = _d[1];
    var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    var years = Array.from({ length: 100 }, function (_, i) { return new Date().getFullYear() - i; });
    var handleMonthChange = function (month) {
        if (!readonly) {
            var year = selectedMonthYear
                ? parseInt(selectedMonthYear.split('/')[1])
                : new Date().getFullYear();
            var newMonthYear = "".concat((month + 1).toString().padStart(2, '0'), "/").concat(year);
            setSelectedMonthYear(newMonthYear);
            setIsOpen(false);
            if (onChange)
                onChange(newMonthYear);
        }
    };
    var handleYearChange = function (year) {
        if (!readonly) {
            var month = selectedMonthYear
                ? parseInt(selectedMonthYear.split('/')[0])
                : new Date().getMonth() + 1;
            var newMonthYear = "".concat(month.toString().padStart(2, '0'), "/").concat(year);
            setSelectedMonthYear(newMonthYear);
            setIsOpen(false);
            if (onChange)
                onChange(newMonthYear);
        }
    };
    var handleClear = function () {
        if (!readonly) {
            setSelectedMonthYear(null);
            if (onChange)
                onChange(null);
        }
    };
    var toggleDropdown = function () {
        if (!disabled && !readonly) {
            setIsOpen(!isOpen);
            setFocus(true);
        }
    };
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (pickerRef.current &&
                !pickerRef.current.contains(event.target)) {
                setIsOpen(false);
                setFocus(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return function () {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return {
        focus: focus,
        months: months,
        years: years,
        pickerRef: pickerRef,
        isOpen: isOpen,
        handleMonthChange: handleMonthChange,
        handleYearChange: handleYearChange,
        handleClear: handleClear,
        toggleDropdown: toggleDropdown,
        selectedMonthYear: selectedMonthYear,
    };
};
export default useMonthYearPicker;
