/* eslint-disable no-unused-vars */
import Button from '@components/button/button.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import { packLineHeadersForm } from '../../booking-details/booking-details.static'
import { metaDummy } from '../../bookings.dummy'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import { FormikProvider } from 'formik'
import {
    ICreateBookingPackLinesValidationForm,
    useBookingsCreateValidation,
} from 'client/ifb/form-validation/booking-details.validation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IOption } from '@components/measurement-input/measurement-input.interface'
import { IUseCreateBooking } from '../create-booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { Toast } from '@components/toast/toast.component'
import { metaDummy2 } from 'common/common.static'

const PackLines = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const [isNewForm, setIsNewForm] = useState<boolean>(true)
    const { t } = useTranslation()
    const { createBookingPackLinesInitialValueForm } =
        useBookingsCreateValidation()

    return (
        <div className="h-[calc(100vh-290px)] grid grid-cols-12">
            <div className="col-span-9">
                <SimpleTable<ICreateBookingPackLinesValidationForm>
                    headers={packLineHeadersForm}
                    data={CBService.packLinesData || []}
                    meta={metaDummy2(CBService.packLinesData.length)}
                    usePagination
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    onRowClick={(data) => {
                        setIsNewForm(false)
                        CBService.setPackLineData(data)
                    }}
                />
            </div>
            <FormikProvider value={CBService.formikCreateBookingPackLines}>
                <div className="col-span-3 border-y-2 flex flex-col justify-between">
                    <div>
                        <div className="flex themes-text-gray-v5 justify-between items-center p-3 text-[16px] border-b-2">
                            {!isNewForm ? (
                                <>
                                    <span>
                                        {t('bookings.selected_pack_line')}
                                    </span>
                                    <div className="text-[24px] flex">
                                        <div className="border-x-2 px-2">
                                            <i
                                                className="ri-file-copy-line themes-text-hover-green-v3 cursor-pointer"
                                                onClick={() => {
                                                    CBService.setConfirmationModalProperty(
                                                        {
                                                            title: 'Duplicate Pack Line',
                                                            message:
                                                                'Duplicating this item will create a copy of the current data. Please review carefully before proceeding',
                                                            btnProceed: {
                                                                onClick: () => {
                                                                    CBService.setPackLineParams(
                                                                        'duplicate',
                                                                    )
                                                                    CBService.formikCreateBookingPackLines.submitForm()
                                                                },
                                                                variant:
                                                                    'brand',
                                                            },
                                                        },
                                                    )
                                                    CBService.confirmationModal.openModalHandling()
                                                }}
                                            />
                                        </div>
                                        <div className="pl-2">
                                            <i
                                                className="ri-delete-bin-line themes-text-hover-red-v3 cursor-pointer"
                                                onClick={() => {
                                                    CBService.setConfirmationModalProperty(
                                                        {
                                                            title: 'Delete Pack Line',
                                                            message:
                                                                'Deleting this will not be able to undo the changes.',
                                                            btnProceed: {
                                                                onClick: () => {
                                                                    CBService.setPackLineParams(
                                                                        'delete',
                                                                    )
                                                                    CBService.formikCreateBookingPackLines.submitForm()
                                                                    setIsNewForm(
                                                                        true,
                                                                    )
                                                                },
                                                                variant: 'red',
                                                            },
                                                        },
                                                    )
                                                    CBService.confirmationModal.openModalHandling()
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>{t('bookings.add_new_pack_line')}</>
                            )}
                        </div>
                        <div className="p-3 grid grid-cols-2 gap-3">
                            <FormInput
                                label={'Pieces'}
                                name={`packs`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                type="number"
                            />
                            <FormDropdown
                                label={'Pack Type'}
                                name={`packageType`}
                                placeholder={'Select here'}
                                options={CBService.dropdownData.packsUnit}
                                parentDivClassName="!mb-0"
                                additionalOnClick={(data) => {
                                    CBService.additionalClickSetDesc<ICreateBookingPackLinesValidationForm>(
                                        CBService.formikCreateBookingPackLines,
                                        'packageTypeDescription',
                                        data as IDropdownItem,
                                    )
                                }}
                                isClearable
                                isSearchable
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .lengthWidthHeightMeasure as IOption[]
                                }
                                name="specialField.lengthMeasure"
                                label={t('bookings.length_measure')}
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .lengthWidthHeightMeasure as IOption[]
                                }
                                name={'specialField.widthMeasure'}
                                label={t('bookings.width_measure')}
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .lengthWidthHeightMeasure as IOption[]
                                }
                                name="specialField.heightMeasure"
                                label={t('bookings.height_measure')}
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .weightMeasure as IOption[]
                                }
                                name="specialField.weightMeasure"
                                label={t('bookings.weight_measure')}
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .lengthWidthHeightMeasure as IOption[]
                                }
                                name={'specialField.volumeMeasure'}
                                label={t('bookings.volume_measure')}
                            />
                            <FormInput
                                label={t('bookings.goods_description')}
                                name={`goodsDescription`}
                                placeholder={'Select here'}
                                parentDivClassName="!mb-0 col-span-2"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 p-3">
                        <Button
                            onClick={() => {
                                if (isNewForm) {
                                    CBService.setPackLineParams('add')
                                } else {
                                    CBService.setPackLineParams('update')
                                }
                                if (CBService.isFormStillError.addPackLines) {
                                    Toast({
                                        header: 'Failed to Add Pack Line!',
                                        message:
                                            'There is some mandatory fields required to not leave blank',
                                        type: 'error',
                                    })
                                }
                                CBService.formikCreateBookingPackLines.submitForm()
                            }}
                            label={
                                isNewForm
                                    ? t('bookings.add_new_pack_line')
                                    : t('bookings.update_selected_pack_line')
                            }
                            variant="brand"
                            isDisabled={
                                isNewForm
                                    ? false
                                    : !CBService.formikCreateBookingPackLines
                                          .dirty
                            }
                        />
                        <Button
                            onClick={() => {
                                CBService.setConfirmationModalProperty({
                                    title: 'Discard Changes',
                                    message:
                                        'Everything that you’ve added in the page will not be recorded',
                                    btnProceed: {
                                        onClick: () => {
                                            CBService.setPackLineData(
                                                createBookingPackLinesInitialValueForm,
                                            )
                                            CBService.formikCreateBookingPackLines.resetForm()
                                            setIsNewForm(true)
                                        },
                                        variant: 'brand',
                                    },
                                })
                                CBService.confirmationModal.openModalHandling()
                            }}
                            label={t('action.discard_changes')}
                            variant="red-inverse"
                            isDisabled={
                                !isNewForm
                                    ? false
                                    : !CBService.formikCreateBookingPackLines
                                          .dirty
                            }
                        />
                    </div>
                </div>
            </FormikProvider>
        </div>
    )
}

export default PackLines
