import SimpleTable from '@components/simple-table/simple-table.component'
import { shipmentPackLineHeaders } from '../../shipments.static'
// import { metaDummy } from '../../shipments.dummy'
import { IShipmentPackLine } from 'client/ifb/repository/interface/shipment.interface'
import { metaDummy } from '../../shipments.dummy'
import { metaDummy2 } from 'common/common.static'

const PackLines = ({ data }: { data: IShipmentPackLine[] }) => {
    return (
        <div className="h-[calc(100vh-290px)]">
            <SimpleTable<IShipmentPackLine>
                headers={shipmentPackLineHeaders}
                data={data}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy2(data.length)}
            />
        </div>
    )
}

export default PackLines
