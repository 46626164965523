import Button from '@components/button/button.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import { useOrdersDetails } from '../orders-details.service'
import { containerData, metaDummy } from '../../orders.dummy'
import { IDocketLinesOrders } from '../../orders.interface'

const DocketLines = () => {
    const {
        allModalDocketLines,
        headersDocketLines,
        ConfigureColumnsModalServiceDocketLines,
    } = useOrdersDetails()

    return (
        <div className="h-[calc(100vh-13.5rem)]">
            <SimpleTable<IDocketLinesOrders>
                headers={headersDocketLines}
                data={containerData}
                usePagination
                nextHandling={(page) => {
                    console.log('hit Next API -> ' + page)
                }}
                previousHandling={(page) => {
                    console.log('hit Prev API -> ' + page)
                }}
                meta={metaDummy}
                additionalButtonBottom={
                    <>
                        <Button
                            onClick={() =>
                                ConfigureColumnsModalServiceDocketLines.openModalHandling()
                            }
                            label="Configure Column"
                            className="w-[10rem] ml-0"
                            variant="brand-inverse"
                        />
                    </>
                }
            />
            {allModalDocketLines}
        </div>
    )
}

export default DocketLines
