import { ISTColumn } from '@components/simple-table/simple-table.interface'
import {
    IMilestonesTracking,
    INotesTracking,
    IPackLinesTracking,
    ITrackingDocs,
} from '../trackings.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'

export const PaackLinesTrackingConfigureColumns: IConfigureColumns<IPackLinesTracking>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.order_number',
                    accessor: 'orderNumber',
                    label: 'Order Number',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.line_no',
                    accessor: 'lineNo',
                    label: 'Line No',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.line_reference',
                    accessor: 'lineReference',
                    label: 'Line Reference',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.load_sequence',
                    accessor: 'loadSequence',
                    label: 'Load Sequence',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.description',
                    accessor: 'description',
                    label: 'Description',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.package_count',
                    accessor: 'packageCount',
                    label: 'Package Count',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.pack_type',
                    accessor: 'packType',
                    label: 'Pack Type',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.packed_quantity',
                    accessor: 'packedQuantity',
                    label: 'Packed Quantity',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.unit_of_qty',
                    accessor: 'unitOfQty',
                    label: 'Unit of Qty',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.actual_weight',
                    accessor: 'actualWeight',
                    label: 'Actual Weight',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.actual_weight_uq',
                    accessor: 'actualWeightUQ',
                    label: 'Actual Weight UQ',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.actual_volume',
                    accessor: 'actualVolume',
                    label: 'actual Volume',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.shipment_number',
                    accessor: 'shipmentNumber',
                    label: 'Shipment Number',
                },
                {
                    statusColumn: 'selectedColumns',
                    label: 'Weight',
                    labelTranslation: 'tracking.weight',
                    accessor: 'weight',
                },
            ],
        },
    ]

export const trackingEDocsHeaders: ISTColumn<ITrackingDocs>[] = [
    {
        label: 'File Name',
        labelTranslation: 'tracking.file_name',
        accessor: 'fileName',
    },
    {
        label: 'Description',
        labelTranslation: 'tracking.description',
        accessor: 'description',
    },
    {
        label: 'Added',
        labelTranslation: 'tracking.added',
        accessor: 'added',
    },
]

export const trakingEDocsDummy: ITrackingDocs[] = [
    {
        fileName: 'invoice_12345.pdf',
        description: 'Invoice for order #12345',
        added: '01 May 2024',
    },
    {
        fileName: 'packing_list_67890.pdf',
        description: 'Packing list for shipment #67890',
        added: '02 May 2024',
    },
    {
        fileName: 'certificate_of_origin.pdf',
        description: 'Certificate of Origin for goods',
        added: '03 May 2024',
    },
    {
        fileName: 'bill_of_lading.pdf',
        description: 'Bill of Lading for container #98765',
        added: '04 May 2024',
    },
    {
        fileName: 'insurance_certificate.pdf',
        description: 'Insurance certificate for shipment',
        added: '05 May 2024',
    },
]

export const packLinesData = [
    {
        orderNumber: 'ORD123456',
        lineNo: '1',
        lineReference: 'REF123',
        loadSequence: 'SEQ001',
        description: 'Product A',
        packageCount: '10',
        packType: 'Box',
        packedQuantity: '100',
        unitOfQty: 'pcs',
        actualWeight: '50',
        actualWeightUQ: 'kg',
        actualVolume: '0.5',
        shipmentNumber: 'SHIP123',
    },
    {
        orderNumber: 'ORD123457',
        lineNo: '2',
        lineReference: 'REF124',
        loadSequence: 'SEQ002',
        description: 'Product B',
        packageCount: '5',
        packType: 'Crate',
        packedQuantity: '50',
        unitOfQty: 'pcs',
        actualWeight: '30',
        actualWeightUQ: 'kg',
        actualVolume: '0.3',
        shipmentNumber: 'SHIP124',
    },
    {
        orderNumber: 'ORD123458',
        lineNo: '3',
        lineReference: 'REF125',
        loadSequence: 'SEQ003',
        description: 'Product C',
        packageCount: '20',
        packType: 'Pallet',
        packedQuantity: '200',
        unitOfQty: 'pcs',
        actualWeight: '100',
        actualWeightUQ: 'kg',
        actualVolume: '1.0',
        shipmentNumber: 'SHIP125',
    },
    {
        orderNumber: 'ORD123459',
        lineNo: '4',
        lineReference: 'REF126',
        loadSequence: 'SEQ004',
        description: 'Product D',
        packageCount: '8',
        packType: 'Bag',
        packedQuantity: '80',
        unitOfQty: 'pcs',
        actualWeight: '40',
        actualWeightUQ: 'kg',
        actualVolume: '0.4',
        shipmentNumber: 'SHIP126',
    },
    {
        orderNumber: 'ORD123460',
        lineNo: '5',
        lineReference: 'REF127',
        loadSequence: 'SEQ005',
        description: 'Product E',
        packageCount: '15',
        packType: 'Container',
        packedQuantity: '150',
        unitOfQty: 'pcs',
        actualWeight: '75',
        actualWeightUQ: 'kg',
        actualVolume: '0.75',
        shipmentNumber: 'SHIP127',
    },
]

export const milestoneData = [
    {
        parentJob: 'SAE23072017',
        milestoneDesc: 'Pickup Cartage Complete/Finalized',
        milestoneDate: '',
        milestoneStatus: '',
    },
    {
        parentJob: 'SAE23072017',
        milestoneDesc: 'Origin Receival from Wharf/Depot',
        milestoneDate: '',
        milestoneStatus: '',
    },
    {
        parentJob: 'SAE23072017',
        milestoneDesc: 'All Export Documents Received',
        milestoneDate: '',
        milestoneStatus: '',
    },
    {
        parentJob: 'C000001327',
        milestoneDesc: 'Departure from First Load Port',
        milestoneDate: '11-Jul-2023',
        milestoneStatus: 'overdue',
    },
    {
        parentJob: 'C000001327',
        milestoneDesc: 'Arrival at Final Discharge Port',
        milestoneDate: '22-Jul-2023',
        milestoneStatus: 'overdue',
    },
]

export const headerNotes: ISTColumn<any>[] = [
    {
        accessor: 'noteText',
        label: 'Note Text',
    },
    {
        accessor: 'description',
        label: 'Description',
    },
]

export const NotesTrackingConfigureColumns: IConfigureColumns<INotesTracking>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.note_text',
                    accessor: 'noteText',
                    label: 'Note Text',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.description',
                    accessor: 'description',
                    label: 'Description',
                },
            ],
        },
    ]

export const MilestonesTrackingConfigureColumns: IConfigureColumns<IMilestonesTracking>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.parent_job',
                    accessor: 'parentJob',
                    label: 'Parent Job',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.description',
                    accessor: 'milestoneDesc',
                    label: 'Description',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.date',
                    accessor: 'milestoneDate',
                    label: 'Date',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.status',
                    accessor: 'milestoneStatus',
                    label: 'Status',
                },
            ],
        },
    ]

export const EventsTrackingConfigureColumns: IConfigureColumns<IMilestonesTracking>[] =
    [
        {
            title: 'Flags',
            columns: [
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.parent_job',
                    accessor: 'parentJob',
                    label: 'Parent Job',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.description',
                    accessor: 'milestoneDesc',
                    label: 'Description',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.date',
                    accessor: 'milestoneDate',
                    label: 'Date',
                },
                {
                    statusColumn: 'selectedColumns',
                    labelTranslation: 'tracking.status',
                    accessor: 'milestoneStatus',
                    label: 'Status',
                },
            ],
        },
    ]

export const notesData = [
    {
        noteText: 'Compact, thorough notes for goods',
        description: 'Detailed Goods Description',
    },
    {
        noteText: 'Itemized, meticulous goods notes',
        description: 'Detailed Goods Description',
    },
]
