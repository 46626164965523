import { IRoute } from 'common/common.interface'
import { DashboardInProgress } from 'client/common/pages/work-in-progress/work-in-progress.component'

export const logisticalRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: false,
        icon: 'ri-dashboard-line',
        parentId: '1',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: true,
        icon: 'ri-dashboard-line',
        parentId: '1',
    },
]
