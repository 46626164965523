var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './item-list-info.style.css';
import { useTranslation } from 'react-i18next';
var ItemListInfo = function (_a) {
    var itemListInfo = _a.itemListInfo, _b = _a.itemClassNames, itemClassNames = _b === void 0 ? '' : _b, _c = _a.containerClassNames, containerClassNames = _c === void 0 ? '' : _c;
    var t = useTranslation().t;
    return (_jsx("div", __assign({ className: "".concat(containerClassNames, " flex gap-2") }, { children: itemListInfo.map(function (item, idx) {
            var firstChar = item.color.charAt(0);
            var color = firstChar === '#'
                ? "".concat(item.color)
                : "themes-bg-".concat(item.color);
            return (_jsxs("div", __assign({ className: "".concat(itemClassNames, " flex gap-1 ml-1 w-fit items-center text-[12px]") }, { children: [_jsx("div", { className: "w-[15px] h-[15px] mr-1 ".concat(color, " rounded"), style: { backgroundColor: color } }), _jsx("div", __assign({ className: "min-w-max text-title themes-text-gray-v6" }, { children: item.labelTranslation
                            ? t(item.labelTranslation)
                            : item.label }))] }), "item-list-info-".concat(idx)));
        }) })));
};
export default ItemListInfo;
