import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import {
    IFieldDropdownCompany,
    IFieldDropdownContactNameItem,
} from '../../bookings.interface'
import { useTranslation } from 'react-i18next'
import { IPropsFormD, IUseCreateBooking } from '../create-booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import React from 'react'
import { Toast } from '@components/toast/toast.component'

const RelatedParties = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const { t } = useTranslation()

    // state
    const [selectedConsignor, setSelectedConsignor] = React.useState<
        string | number | any[]
    >('')
    const [selectedConsignee, setSelectedConsignee] = React.useState<
        string | number | any[]
    >('')

    const propertyForm: IPropsFormD[] = [
        {
            label: 'Consignor Pickup Address',
            entity: 'shipperAddress[0]',
            dropdownContact: CBService.dropdownDataContact.shipperAddress1,
            companyDropdown: CBService.dropdownData.shippercompanies1,
        },
        {
            label: 'Consignor Documentary Address',
            entity: 'shipperAddress[1]',
            dropdownContact: CBService.dropdownDataContact.shipperAddress2,
            companyDropdown: CBService.dropdownData.shippercompanies2,
        },
        {
            label: 'Consignee Pickup Address',
            entity: 'consigneeAddress[0]',
            dropdownContact: CBService.dropdownDataContact.consigneeAddress1,
            companyDropdown: CBService.dropdownData.consgineecompanies1,
        },
        {
            label: 'Consignee Documentary Address',
            entity: 'consigneeAddress[1]',
            dropdownContact: CBService.dropdownDataContact.consigneeAddress2,
            companyDropdown: CBService.dropdownData.consgineecompanies2,
        },
    ]

    const handleCompanySelection = (
        entity: string,
        data: IDropdownItem<IFieldDropdownCompany>,
    ) => {
        const selectedValue = Array.isArray(data)
            ? data.map((item) => item.value)
            : data?.value

        if (!selectedValue) {
            if (entity === propertyForm[0].entity) {
                setSelectedConsignor('')
            } else if (entity === propertyForm[2].entity) {
                setSelectedConsignee('')
            }
            CBService.additionalOnClickCompany([entity], data)
            return
        }

        if (entity === propertyForm[0].entity) {
            // check if consignor selection is the same as consignee selection
            if (selectedValue === selectedConsignee) {
                Toast({
                    header: 'Improper Filter Selection!',
                    message:
                        'Selected consignor and consignee cannot be the same',
                    type: 'error',
                })
                CBService.additionalOnClickCompany([
                    entity,
                    propertyForm[1].entity,
                ])
                return
            }

            // if valid, set the consignor
            setSelectedConsignor(selectedValue)
            CBService.additionalOnClickCompany(
                [entity, propertyForm[1].entity],
                data,
            )
        } else if (entity === propertyForm[2].entity) {
            // check if consignee selection is the same as consignor selection
            if (selectedValue === selectedConsignor) {
                Toast({
                    header: 'Improper Filter Selection!',
                    message:
                        'Selected consignor and consignee cannot be the same',
                    type: 'error',
                })
                CBService.additionalOnClickCompany([
                    entity,
                    propertyForm[3].entity,
                ])
                return
            }

            // if valid, set the consignee
            setSelectedConsignee(selectedValue)
            CBService.additionalOnClickCompany(
                [entity, propertyForm[3].entity],
                data,
            )
        } else if (
            entity === propertyForm[1].entity ||
            entity === propertyForm[3].entity
        ) {
            CBService.additionalOnClickCompany([entity], data)
        }
    }

    return (
        <div className="p-3 grid grid-cols-4 gap-3">
            {propertyForm.map((item) => {
                return (
                    <div key={item.entity} className="flex flex-col gap-3">
                        <div>{item.label}</div>
                        <div key={`${item.entity}.company`}>
                            <FormDropdown<IFieldDropdownCompany>
                                isSearchable
                                label={t('bookings.company')}
                                name={`${item.entity}.company`}
                                options={item.companyDropdown}
                                defaultValue={item.companyDropdown}
                                isAsync={true}
                                loadOptions={(e: string) => {
                                    switch (item.entity) {
                                        case propertyForm[0].entity:
                                            return CBService.loadOptions({
                                                shippercompanies1: e,
                                            })
                                        case propertyForm[1].entity:
                                            return CBService.loadOptions({
                                                shippercompanies2: e,
                                            })
                                        case propertyForm[2].entity:
                                            return CBService.loadOptions({
                                                consgineecompanies1: e,
                                            })
                                        case propertyForm[3].entity:
                                            return CBService.loadOptions({
                                                consgineecompanies2: e,
                                            })
                                    }
                                }}
                                onClear={() =>
                                    CBService.dropdownChangeEvent(
                                        item,
                                        propertyForm,
                                        true,
                                        undefined,
                                    )
                                }
                                additionalOnClick={(value: any) =>
                                    handleCompanySelection(item.entity, value)
                                }
                                placeholder={t('action.enter', {
                                    value: t('bookings.company'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                                isClearable={true}
                            />
                        </div>
                        <FormInput
                            label={t('bookings.street')}
                            name={`${item.entity}.address1`}
                            placeholder={t('action.enter', {
                                value: t('bookings.street'),
                            })}
                            parentDivClassName="!mb-0 col-span-2"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.additional_street')}
                            name={`${item.entity}.address2`}
                            placeholder={t('action.enter', {
                                value: t('bookings.additional_street'),
                            })}
                            parentDivClassName="!mb-0 col-span-2"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.city')}
                            name={`${item.entity}.city`}
                            placeholder={t('action.enter', {
                                value: t('bookings.city'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.state')}
                            name={`${item.entity}.state.value`}
                            placeholder={t('action.enter', {
                                value: t('bookings.state'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.postcode')}
                            name={`${item.entity}.postCode`}
                            placeholder={t('action.enter', {
                                value: t('bookings.postcode'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />

                        {/* temporarily replace with input form country  */}
                        <FormInput
                            label={t('bookings.country')}
                            name={`${item.entity}.countryCode`}
                            placeholder={t('action.enter', {
                                value: t('bookings.country'),
                            })}
                            parentDivClassName="!mb-0 "
                            disabled
                        />
                        <div key={`${item.entity}.contactName`}>
                            <FormDropdown<IFieldDropdownContactNameItem>
                                isSearchable
                                label={t('bookings.contact_name')}
                                name={`${item.entity}.contactName`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.contact_name'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                                options={item.dropdownContact}
                                isClearable
                                onClear={() =>
                                    CBService.dropdownChangeEvent(
                                        item,
                                        propertyForm,
                                        false,
                                        undefined,
                                    )
                                }
                                additionalOnClick={(data) =>
                                    CBService.dropdownChangeEvent(
                                        item,
                                        propertyForm,
                                        false,
                                        data,
                                    )
                                }
                            />
                        </div>
                        <FormInput
                            label={t('bookings.contact_email_address')}
                            name={`${item.entity}.contactEmailAddress`}
                            placeholder={t('action.enter', {
                                value: t('bookings.contact_email_address'),
                            })}
                            parentDivClassName="!mb-0 col-span-2"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.contact_phone')}
                            name={`${item.entity}.contactPhoneNumber`}
                            placeholder={t('action.enter', {
                                value: t('bookings.contact_phone'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.contact_mobile')}
                            name={`${item.entity}.contactMobileNumber`}
                            placeholder={t('action.enter', {
                                value: t('bookings.contact_mobile'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default RelatedParties
