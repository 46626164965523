import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeta, IResponseData } from 'common/common.interface'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IShipment,
    IShipmentFilter,
    IShipmentStatusTotal,
    IShipmentDetails,
    IShipmentPackLine,
    IShipmentTracking,
} from 'client/ifb/repository/interface/shipment.interface'
import { RootState } from 'store'
import { intialValueShipmentDetails } from './shipment-details/shipment-details.static'
import { formatDate, formatDateTime } from 'common/common.service'
import { empityMetaDummy } from './shipments.dummy'
import { ITrackingSumary } from '../trackings/trackings.interface'
import moment from 'moment'

interface IShipmentsSlice {
    data: IShipment[]
    meta: IMeta
    responseMessage: string
    filter: IShipmentFilter
    statusSelected: ITabItem
    statusTotal: IShipmentStatusTotal

    // segment details
    detailsData: IShipmentDetails
    shipmentDetails: {
        tracking: {
            data: ITrackingSumary[]
            meta: IMeta
        }
    }
}

export const initialState: IShipmentsSlice = {
    data: [],
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    responseMessage: '',
    filter: {
        status: '',
        inProgressStatus: '',
        pageNumber: 1,
        pageSize: 50,
    },
    statusTotal: {
        all: 0,
        arrived: 0,
        inTransit: 0,
        delayed: 0,
        noStatus: 0,
    },
    statusSelected: {
        value: 'all',
        key: 'status',
        label: 'All Shipment',
        totalData: 0,
    },

    // segment details
    detailsData: intialValueShipmentDetails,
    shipmentDetails: {
        tracking: {
            data: [],
            meta: {
                current_page: 0,
                last_page: 0,
                per_page: 50,
                total_page: 0,
                from: 0,
                to: 0,
                index_end: 0,
                index_start: 0,
            },
        },
    },
}

const shipmentsSlice = createSlice({
    name: 'shipmentsIFB',
    initialState,
    reducers: {
        setShipmentData(
            state,
            action: PayloadAction<IResponseData<IShipment[]>>,
        ) {
            const { data, isSuccess, message, meta, links } = action.payload

            let setFormatData: IShipment[] = []
            let setMeta: IMeta
            // change this logic when the status summary is clear
            if (state.statusSelected.value === 'all') {
                // set total status
                setFormatData = data.map((item) => ({
                    ...item,
                    datesETDShipment: item?.datesETDShipment
                        ? formatDate(item?.datesETDShipment)
                        : '',
                    datesETAShipment: item?.datesETAShipment
                        ? formatDate(item?.datesETAShipment)
                        : '',
                }))
                setMeta = meta
            } else {
                setFormatData = []
                setMeta = empityMetaDummy
            }

            const d = {
                data: setFormatData,
                isSuccess,
                message,
                meta: setMeta,
                links,
                statusTotal: {
                    ...state.statusTotal,
                    all: meta.total_Items || 0,
                },
            }

            return {
                ...state,
                ...d,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload

            return {
                ...state,
                statusSelected,
            }
        },
        setFilter(state, action: PayloadAction<IShipmentFilter>) {
            const filter = action.payload
            return {
                ...state,
                filter,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },

        // segment details
        setShipmentDetailsData(
            state,
            action: PayloadAction<IResponseData<IShipmentDetails>>,
        ) {
            const payloadData = action.payload.data
            const metaTracking = state.shipmentDetails.tracking.meta

            const loadMeta = (totalItems: number, meta: IMeta) => {
                const total_Items = totalItems
                const current_page = total_Items
                    ? metaTracking.current_page
                        ? metaTracking.current_page
                        : 1
                    : 0
                const index_start =
                    (current_page - 1) * metaTracking.per_page + 1
                const index_end = Math.min(
                    index_start + metaTracking.per_page - 1,
                    total_Items,
                )
                const last_page = Math.ceil(total_Items / metaTracking.per_page)

                return {
                    ...meta,
                    last_page,
                    index_start,
                    index_end,
                    total_Items,
                    current_page,
                }
            }

            // detailsData.destination.etaShipment = detailsData.destination
            //     .etaShipment
            //     ? formatDate(detailsData.destination.etaShipment)
            //     : ''
            // detailsData.origin.etdShipment = detailsData.origin.etdShipment
            //     ? formatDate(detailsData.origin.etdShipment)
            //     : ''

            // state.details.tracking.meta = {
            //     ...metaTracking,
            //     last_page,
            //     index_start,
            //     index_end,
            //     total_Items: total_Items_tracking,
            //     current_page,
            // }

            const loadMetaTracking = loadMeta(
                payloadData.shipmentTracking.length,
                state.shipmentDetails.tracking.meta,
            )
            const updatedTrackingData: ITrackingSumary[] =
                payloadData?.shipmentTracking
                    ?.map((d) => ({
                        idShipment: d?.containerNo,
                        initialCountryCode: d?.portOfLoadingCode,
                        initialCountryName: d?.portOfLoadingDesc,
                        destinationCountryCode: d?.portOfDischargeCode,
                        destinationCountryName: d?.portOfDischargeDesc,
                        ETD: d?.containerETD
                            ? moment(d.containerETD).format('DD MMM YY')
                            : '',
                        ETA: d?.containerETA
                            ? moment(d.containerETA).format('DD MMM YY')
                            : '',
                        detail: {
                            containerType: d?.containerType,
                            containerMode: d?.containerMode,
                            deliveryMode: d?.deliveryMode,
                            relatedShipments: d?.relatedShipmentNumbers,
                            consoleNo: d?.consolNo,
                            sealNo: d?.sealNo,
                            weight: d?.weight,
                            vessel: d?.vesselName,
                            voyage: d?.voyageNo,
                            emptyReturnedOn: d?.emptyReturnedOn
                                ? formatDate(d.emptyReturnedOn)
                                : '',
                            transportMode: d?.transportMode,
                        },
                        track:
                            d?.trackingHistoryMilestone?.map((t) => ({
                                label: t?.eventCode || '',
                                desc: t?.description || '',
                                time:
                                    t?.estimatedDate !== null
                                        ? formatDateTime(t.estimatedDate)
                                        : '',
                            })) || [],
                        status: { color: 'green', text: 'IN TRANSIT' },
                    }))
                    .slice(
                        loadMetaTracking.index_start - 1,
                        loadMetaTracking.index_end,
                    )
            state.shipmentDetails.tracking.data = updatedTrackingData
            state.shipmentDetails.tracking.meta = loadMetaTracking

            state.detailsData = action.payload.data
        },
        setShipmentDetailsTracking(
            state,
            action: PayloadAction<IShipmentTracking[]>,
        ) {
            const payloadData = action.payload
            const metaTracking = state.shipmentDetails.tracking.meta

            const total_Items = payloadData.length
            const current_page = total_Items
                ? metaTracking.current_page
                    ? metaTracking.current_page
                    : 1
                : 0
            const index_start = (current_page - 1) * metaTracking.per_page + 1
            const index_end = Math.min(
                index_start + metaTracking.per_page - 1,
                total_Items,
            )
            const last_page = Math.ceil(total_Items / metaTracking.per_page)

            const updatedTrackingData: ITrackingSumary[] = payloadData
                ?.map((d) => ({
                    idShipment: d?.containerNo,
                    initialCountryCode: d?.portOfLoadingCode,
                    initialCountryName: d?.portOfLoadingDesc,
                    destinationCountryCode: d?.portOfDischargeCode,
                    destinationCountryName: d?.portOfDischargeDesc,
                    ETD: d?.containerETD
                        ? moment(d.containerETD).format('DD MMM YY')
                        : '',
                    ETA: d?.containerETA
                        ? moment(d.containerETA).format('DD MMM YY')
                        : '',
                    detail: {
                        containerType: d?.containerType,
                        containerMode: d?.containerMode,
                        deliveryMode: d?.deliveryMode,
                        relatedShipments: d?.relatedShipmentNumbers,
                        consoleNo: d?.consolNo,
                        sealNo: d?.sealNo,
                        weight: d?.weight,
                        vessel: d?.vesselName,
                        voyage: d?.voyageNo,
                        emptyReturnedOn: d?.emptyReturnedOn
                            ? formatDate(d.emptyReturnedOn)
                            : '',
                        transportMode: d?.transportMode,
                    },
                    track:
                        d?.trackingHistoryMilestone?.map((t) => ({
                            label: t?.eventCode || '',
                            desc: t?.description || '',
                            time:
                                t?.estimatedDate !== null
                                    ? formatDateTime(t.estimatedDate)
                                    : '',
                        })) || [],
                    status: { color: 'green', text: 'IN TRANSIT' },
                }))
                .slice(index_start - 1, index_end)

            state.shipmentDetails.tracking.data = updatedTrackingData
            state.shipmentDetails.tracking.meta = {
                ...metaTracking,
                last_page,
                index_start,
                index_end,
                total_Items,
                current_page,
            }
        },
        setPageNumberTrackingSlice: (state, action: PayloadAction<number>) => {
            state.shipmentDetails.tracking.meta.current_page = action.payload
        },
    },
})

// these all the variables used for selector
export const shipmentsDataSelector = (state: RootState) =>
    state.shipmentIFB.data || {}
export const filterSelector = (state: RootState) =>
    state.shipmentIFB.filter || {}
export const shipmentMetaSelector = (state: RootState) =>
    state.shipmentIFB.meta || {}
export const shipmentsFilterSelector = (state: RootState) =>
    state.shipmentIFB.filter || {}
export const shipmentsStatusTotalSelector = (state: RootState) =>
    state.shipmentIFB.statusTotal || {}
export const tabStatusFilterSelector = (state: RootState) =>
    state.shipmentIFB.statusSelected || {}

// segment details
export const shipmentDetailsDataSelector = (state: RootState) =>
    state.shipmentIFB.detailsData || {}
export const shipmentDetailsSelector = (state: RootState) =>
    state.shipmentIFB.shipmentDetails || {}

// all actions
export const {
    setShipmentData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
    setShipmentDetailsData,
    setPageNumberTrackingSlice,
    setShipmentDetailsTracking,
} = shipmentsSlice.actions

// Reducer
export default shipmentsSlice.reducer
