import { useEffect, useRef, useState } from 'react';
var useSplitTable = function () {
    var tableRefs = useRef([]);
    var _a = useState(null), hoveredRowIndex = _a[0], setHoveredRowIndex = _a[1];
    var _b = useState(0), clickRowIndex = _b[0], setClickRowIndex = _b[1];
    var syncScroll = function (e, index) {
        var currentScrollTop = e.currentTarget.scrollTop;
        tableRefs.current.forEach(function (table, idx) {
            if (table && idx !== index) {
                table.scrollTop = currentScrollTop;
            }
        });
    };
    useEffect(function () {
        var handleWheelScroll = function (index) { return function (e) {
            var scrollTop = e.deltaY;
            tableRefs.current.forEach(function (table, idx) {
                if (table && idx !== index) {
                    table.scrollTop += scrollTop;
                }
            });
        }; };
        tableRefs.current.forEach(function (table, index) {
            if (table) {
                table.addEventListener('wheel', handleWheelScroll(index));
            }
        });
        return function () {
            tableRefs.current.forEach(function (table, index) {
                if (table) {
                    table.removeEventListener('wheel', handleWheelScroll(index));
                }
            });
        };
    }, []);
    return {
        tableRefs: tableRefs,
        hoveredRowIndex: hoveredRowIndex,
        setHoveredRowIndex: setHoveredRowIndex,
        clickRowIndex: clickRowIndex,
        setClickRowIndex: setClickRowIndex,
        syncScroll: syncScroll,
    };
};
export default useSplitTable;
